import { DateTime } from 'luxon';
import * as React from 'react';

import { useGetFeedTotalCountQuery } from '../../generated/feed';
import {
  ModelVerificationStatusEnum,
  ProfileStatusEnum,
  useAccountStatusProviderQuery,
} from '../../generated/graphql';
import { createContext } from '../../hooks/useContext';
import {
  HomeTaskCardItemProps,
  OnboardingTaskState,
} from '../../pages/Home/components/Home/TaskCard/HomeTaskCardItem';
import { extractFromUnion } from '../../utils/extractor';
import { useAuth } from '../AuthProvider';

interface ICanGoOnlineStatus {
  /**
   * Relates to "Feature Type 71" indicating, that the account is allowed to go online
   * older accounts have a hack in the backen based on their userId
   * ```
   * FTR_TP is SET ODER User-ID < XXX
   * ```
   */
  isModelVerified: boolean;
  isAllowedToGoOnlineOnVXLive: boolean;
  isAllowedToGoOnlineOnVXLiveButWasNotYet: boolean;
  wasOnlineOnVXLive: boolean;
  isAllowedToPostOnFeed: boolean;
  isAllowedToPostOnFeedButHasNonYet: boolean;
  hasSomeFeedPost: boolean;
}
interface IOnboardingTaskStatus {
  /**
   * "Ausweis hochladen >"
   * Age Verification with identityProof and idShot
   */
  isVerificationTourCompleted: boolean;
  verificationTourStatus: OnboardingTaskState;
  /**
   * "Modelprofil anlegen >"
   * Modelname and minimal model profile setup
   */
  isProfileTourCompleted: boolean;
  profileTourStatus: OnboardingTaskState;
  /**
   * "Model Release Form anlegen >"
   */
  isMrfTourCompleted: boolean;
  mrfTourStatus: OnboardingTaskState;
  totalOnboardingTaskCount: number;
  incompletedOnboardingTaskCount: number;
  completedOnboardingTaskCount: number;
  hasIncompletedOnboardingTasks: boolean;
  /**
   * Initial definition for "canGoOnline"
   */
  hasAllOnboardingTasksCompleted: boolean;
}

export interface AccountStatusContext
  extends ICanGoOnlineStatus,
    IOnboardingTaskStatus {
  isAccountStatusLoading: boolean;
  readonly actions: {
    refresh: () => void;
  };
}

export const [, useAccountStatus, accountStatusContext] =
  createContext<AccountStatusContext>({
    name: 'AccountStatusContext',
    errorMessage:
      'useAccountStatus: `AccountStatusContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export const AccountStatusProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, authUser } = useAuth();
  const accountStausQuery = useAccountStatusProviderQuery({
    skip: !isAuthenticated,
  });

  const getFeedTotalCountQuery = useGetFeedTotalCountQuery({
    variables: {
      modelId: authUser?.userId ?? '',
    },
    skip: !isAuthenticated,
  });

  const refresh = React.useCallback(() => {
    accountStausQuery.refetch().then();
    getFeedTotalCountQuery.refetch().then();
  }, [accountStausQuery, getFeedTotalCountQuery]);

  /**
   * Checks for the `mrfWizardFinished` session storage item to determine
   * if the MRF Wizard was finished a minute ago and deletes it if necessary.
   * The session storage item is set in the `WizardMRFModelReleaseFormV1Finish` component.
   *
   * This is necessary to be able to set the state of the MRF task item to `pending`,
   * because the backend may not be fast enough and still delivers `incomplete`
   * even though the wizard was finished. There's no other event or state to handle this,
   * therefore this approach was chosen.
   *
   * @returns `true` or `false`
   */
  const mrfWizardFinished = React.useCallback((): boolean => {
    const sessionStorageItem = sessionStorage.getItem('mrfWizardFinished');

    if (!sessionStorageItem) {
      return false;
    }

    const mrfWizardFinishedOneMinuteAgo =
      (DateTime.now().toUnixInteger() - Number(sessionStorageItem)) / 60 <= 1;

    if (!mrfWizardFinishedOneMinuteAgo) {
      sessionStorage.removeItem('mrfWizardFinished');
      refresh();
    }

    return mrfWizardFinishedOneMinuteAgo;
  }, [refresh]);

  const onboardingTasks = React.useMemo<IOnboardingTaskStatus>(() => {
    const profileTour = extractFromUnion(
      accountStausQuery.data?.profileTour,
      'TourOnboardingV1'
    );
    const mrfTour = extractFromUnion(
      accountStausQuery.data?.mrfTour,
      'TourModelReleaseFormV1'
    );

    // "Ausweis hochladen >"
    const isVerificationTourCompleted =
      accountStausQuery.data?.verificationTour.modelVerificationStatus ===
        ModelVerificationStatusEnum.Accepted ?? false;

    const verificationTourStatus = getAgeVerificationStepState(
      accountStausQuery.data?.verificationTour.modelVerificationStatus
    );

    // "Modelprofil anlegen >"
    const isProfileTourCompleted =
      (profileTour?.lastVerified !== null ||
        profileTour?.status === ProfileStatusEnum.Accepted) ??
      false;
    const profileTourStatus = profileTour?.lastVerified
      ? 'done'
      : getModelProfileStepState(profileTour?.status);

    // "Model Release Form anlegen >"
    const isMrfTourOptimisiticallyFinished = mrfWizardFinished();

    const isMrfTourCompleted =
      (mrfTour?.lastVerified !== null ||
        mrfTour?.status === ProfileStatusEnum.Accepted) ??
      false;
    const mrfTourStatus = isMrfTourOptimisiticallyFinished
      ? 'pending'
      : mrfTour?.lastVerified
      ? 'done'
      : getModelProfileStepState(mrfTour?.status);

    const _completedStatusArray = [
      isVerificationTourCompleted,
      isProfileTourCompleted,
      isMrfTourCompleted,
    ];
    const totalOnboardingTaskCount = _completedStatusArray.length;
    const incompletedOnboardingTaskCount = _completedStatusArray.filter(
      (isTaskCompleted) => !isTaskCompleted
    ).length;
    const completedOnboardingTaskCount = _completedStatusArray.filter(
      (isTaskCompleted) => isTaskCompleted
    ).length;
    const hasIncompletedOnboardingTasks =
      !isVerificationTourCompleted ||
      !isProfileTourCompleted ||
      !isMrfTourCompleted;

    return {
      isVerificationTourCompleted,
      verificationTourStatus,
      isProfileTourCompleted,
      profileTourStatus,
      isMrfTourCompleted,
      mrfTourStatus,
      totalOnboardingTaskCount,
      incompletedOnboardingTaskCount,
      completedOnboardingTaskCount,
      hasIncompletedOnboardingTasks,
      hasAllOnboardingTasksCompleted: !hasIncompletedOnboardingTasks,
    };
  }, [accountStausQuery.data, mrfWizardFinished]);

  const actions = React.useMemo(() => {
    return {
      refresh,
    };
  }, [refresh]);

  const context = React.useMemo<AccountStatusContext>(() => {
    const isModelVerified =
      accountStausQuery.data?.account?.isModelVerified ?? false;
    const wasOnlineOnVXLive =
      accountStausQuery.data?.account?.wasOnlineOnVXLive ?? false;
    const hasSomeFeedPost =
      (getFeedTotalCountQuery.data?.feedTotalCounts?.totalCount ?? 0) > 0;

    return {
      isAccountStatusLoading:
        accountStausQuery.loading || getFeedTotalCountQuery.loading,
      isModelVerified,
      isAllowedToGoOnlineOnVXLive: isModelVerified,
      isAllowedToGoOnlineOnVXLiveButWasNotYet:
        isModelVerified && !wasOnlineOnVXLive,
      wasOnlineOnVXLive,
      isAllowedToPostOnFeed: isModelVerified,
      isAllowedToPostOnFeedButHasNonYet: isModelVerified && !hasSomeFeedPost,
      hasSomeFeedPost,
      ...onboardingTasks,
      actions,
    };
  }, [getFeedTotalCountQuery, accountStausQuery, onboardingTasks, actions]);
  return (
    <accountStatusContext.Provider value={context}>
      {children}
    </accountStatusContext.Provider>
  );
};

function getAgeVerificationStepState(
  status: ModelVerificationStatusEnum | undefined
): OnboardingTaskState {
  switch (status) {
    case 'accepted':
      return 'done';
    case 'incomplete':
      return 'todo';
    case 'pending':
      return 'pending';
    case 'rejected':
      return 'error';
    default:
      return 'error';
  }
}

function getModelProfileStepState(
  status: ProfileStatusEnum | undefined
): HomeTaskCardItemProps['state'] {
  switch (status) {
    case 'accepted':
      return 'done';
    case 'open':
      return 'todo';
    case 'incomplete':
      return 'todo';
    case 'pending':
      return 'pending';
    case 'rejected':
      return 'error';
    default:
      return 'error';
  }
}
