import { Close } from '@campoint/odi-ui-icons';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  InputGroup,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FormControlHeaderStack } from '../../FormElements/FormControlHeaderStack/FormControlHeaderStack';

export const DateControl: React.FC<{
  name: string;
  label: React.ReactNode;
  helperText?: React.ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  labelTrailingHint?: React.ReactNode;
  footerInfoText?: React.ReactNode;
  labelProps?: FormLabelProps;
  text?: string;
  min?: string;
  max?: string;
}> = ({
  name,
  label,
  isDisabled,
  isReadOnly,
  labelProps,
  labelTrailingHint,
  text,
  min,
  max,
}) => {
  const {
    register,
    setFocus,
    setValue,
    formState: { errors, touchedFields, isSubmitted },
  } = useFormContext();

  const value = useWatch({ name });
  const hasValue = !!value;

  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);

  return (
    <FormControl
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <FormControlHeaderStack>
        <HStack w={'full'}>
          <FormLabel children={label} flex={1} {...labelProps} />
          {labelTrailingHint}
        </HStack>
        {text && <FormHelperText>{text}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup>
        <Input
          type={'date'}
          min={min}
          max={max}
          {...register(name)}
          sx={{
            '&::-webkit-date-and-time-value': {
              textAlign: 'left',
            },
          }}
        />
        {!isDisabled && hasValue && (
          <InputRightElement>
            <ClearableElement
              onClick={() => {
                setValue(name, null, { shouldTouch: true });
                setFocus(name);
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {errors[name] && (
        <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
      )}
    </FormControl>
  );
};

const ClearableElement = forwardRef<Partial<IconButtonProps>, 'button'>(
  (props, ref) => (
    <IconButton
      ref={ref}
      me={2}
      size="sm"
      aria-label="clear"
      variant="ghost"
      color="onSurface.mediumEmphasis"
      colorScheme="gray"
      icon={<Icon as={Close} boxSize="icon.md" />}
      data-testid="clearable-icon"
      {...props}
    />
  )
);
