import {
  OdiCard,
  OdiCardDescription,
  OdiCardProps,
  OdiCardTitle,
} from '@campoint/odi-ui';
import {
  Box,
  Button,
  HStack,
  Heading,
  Image,
  ImageProps,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IncomeSourceEntryFragment,
  TipEntryFragment,
} from '../../../generated/graphql';

export type ExperienceIntentionImageCardProps = {
  entry: TipEntryFragment | IncomeSourceEntryFragment;
  statusIndicator?: ReactElement;
} & ImageCardProps;

export type ImageCardProps = {
  isFullWidth?: boolean;
  imageProps?: ImageProps;
} & OdiCardProps;

const NewIndicator: React.FC = () => {
  const { t } = useTranslation(['tipsPage']);

  return (
    <Box
      position={'absolute'}
      bg={'tertiary.highEmphasis'}
      fontSize={'xl'}
      fontWeight={'bold'}
      color={'surface'}
      px={'2.5'}
      py={'1.5'}
      borderBottomRightRadius={'lg'}
      shadow={'lg'}
    >
      {t('tipsPage:label.Neu').toUpperCase()}
    </Box>
  );
};

const ImageCard: React.FC<{ isLabeledNew: boolean } & ImageCardProps> = ({
  isFullWidth = false,
  imageProps,
  children,
  isLabeledNew,
  ...rest
}) => (
  <OdiCard
    borderColor={'steel'}
    pt={0}
    overflow={'hidden'}
    justifyContent={'stretch'}
    alignItems={'start'}
    gap={0}
    width={isFullWidth ? 'full' : 56}
    minW={56}
    cursor={'pointer'}
    {...rest}
  >
    {isLabeledNew && <NewIndicator />}
    <Image
      objectFit={'cover'}
      width={'full'}
      height={isFullWidth ? 40 : 28}
      {...imageProps}
    />
    {children}
  </OdiCard>
);

const ExperienceIntentionImageCard: React.FC<
  ExperienceIntentionImageCardProps
> = ({ entry, statusIndicator, ...rest }) => {
  const { t } = useTranslation(['tipsPage']);

  return (
    <ImageCard
      imageProps={{
        src: entry.headerImage?.src ?? '',
        alt: entry.headerImage?.alt ?? '',
      }}
      isLabeledNew={entry.isLabeledNew}
      {...rest}
    >
      <VStack
        mt={4}
        alignItems={'stretch'}
        justifyContent={'space-between'}
        spacing={0}
        w={'full'}
        h={'full'}
      >
        {statusIndicator}
        <Heading mb={2} size={'md'} as={OdiCardTitle} textAlign={'start'}>
          {entry.title}
        </Heading>
        <OdiCardDescription justifyContent={'start'} textAlign={'start'}>
          <Text noOfLines={[1, 3]}>{entry.teaserText}</Text>
        </OdiCardDescription>
        <HStack spacing={'2'} w={'full'} px={4}>
          <Spacer />
          <Button
            variant="link"
            children={t('tipsPage:button.MehrErfahren')}
            pr={'4'}
            py={'2'}
          />
        </HStack>
      </VStack>
    </ImageCard>
  );
};

export default ExperienceIntentionImageCard;
