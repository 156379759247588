import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import { VxModelsLogo } from '@campoint/odi-ui-icons';
import {
  Container,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import VXFeedEmptyStateImage from '../../assets/images/shared/VX-Feed-Empty-State.svg';
import VXFeedUSPInteraction from '../../assets/images/shared/VX-Feed-USP-Interaction.svg';
import VXFeedUSPPosting from '../../assets/images/shared/feed-preview.svg';
import VXFeedFilteredEmptyStateImage from '../../assets/images/shared/list-empty-state.svg';
import MissingRequirementsImage from '../../assets/images/shared/missing-requirements-blocker.svg';
import VXFeedUSPTipping from '../../assets/images/shared/payout.svg';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../components/Layout/LayoutCenteringVStack';
import { SectionCenterContainer } from '../../components/Layout/SectionCenterContainer';
import { UrlFragment } from '../../components/Layout/UrlFragmentScrollToTarget';
import { BlockerCard } from '../../components/shared/BlockerCard/BlockerCard';
import { FetchMoreIndicator } from '../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import ProfileHeader from '../../components/shared/ProfileHeader/ProfileHeader';
import { ProfileMenuSwitch } from '../../components/shared/ProfileMenuSwitch/ProfileMenuSwitch';
import {
  USPModal,
  USPModalSteps,
} from '../../components/shared/USPModal/USPModal';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { FeatureName, useFeature } from '../../flags';
import {
  FskEnum,
  ModelPostTypesEnum,
  PostTypeEnum,
} from '../../generated/feed';
import { useFeedPageQuery } from '../../generated/graphql';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { useCreateFeedPostContext } from '../../provider/FeedPostCreateModalProvider';
import { useFeedPostEditModalContext } from '../../provider/FeedPostEditModalProvider';
import {
  ReplaceImageHandlerProps,
  useFeedService,
} from '../../provider/FeedService/FeedService';
import {
  UploadJobEntityType,
  useUploadManager,
} from '../../provider/UploadManagerProvider';
import { routes } from '../../routes/routesConfig';
import { extractFromUnion } from '../../utils/extractor';
import { getFormattedAmount } from '../../utils/formattedAmount';
import { FeedFilterToolButtons } from './components/FeedFilterToolButtons/FeedFilterToolButtons';
import { ClipPost } from './components/post/ClipPost/ClipPost';
import { PhotoGalleryPost } from './components/post/PhotoGalleryPost/PhotoGalleryPost';
import { PhotoPost } from './components/post/PhotoPost/PhotoPost';
import { TextPost } from './components/post/TextPost/TextPost';
import { PostFunctionProps } from './posts';
import { CreateFeedPostSection } from './section/CreateFeedPostSection/CreateFeedPostSection';
import { FeedPostStatesSection } from './section/FeedPostStatusSection/FeedPostStatesSection';

const FeedStillBlockedCard = () => {
  const history = useHistory();
  const { t } = useTranslation(['general', 'feed']);

  const navigateToTaskSectiononHome = () => {
    history.push({
      pathname: routes.home.path,
      hash: UrlFragment.Tasks,
    });
  };

  return (
    <BlockerCard
      isBordered
      image={MissingRequirementsImage}
      heading={
        <>
          <span children={t('feed:heading.BaldVerfugbar')} />
          <br />
          <span children={'VXFeed'} />
        </>
      }
      text={t(
        'feed:text.SchliesseZuerstAlleSchritteAbSodassDuDirektDeinenErstenBeitragImVXFeXX'
      )}
      primaryButtonText={t('feed:button.SchritteAbschliessen')}
      onClickPrimary={navigateToTaskSectiononHome}
    />
  );
};

export const FeedPage: React.FC = () => {
  const { t } = useTranslation(['general', 'feed']);
  const { data, loading: isDataLoading } = useFeedPageQuery();
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });
  const {
    isAllowedToPostOnFeed,
    isAccountStatusLoading,
    actions: { refresh: triggerAccountStatusRefresh },
  } = useAccountStatus();
  const feedPostEditCtx = useFeedPostEditModalContext();
  const feedPostCreateCtx = useCreateFeedPostContext();

  const isContentLoading = isDataLoading || isAccountStatusLoading;

  const uploadManagerCtx = useUploadManager();

  const mediaCollection = extractFromUnion(
    data?.profile?.mediaCollection,
    'ProfileCollectionMobileMediaV1'
  );
  const aboutMeCollection = extractFromUnion(
    data?.profile?.aboutMeCollection,
    'ProfileCollectionMobileAboutMeV1'
  );

  const {
    feedPosts,
    loadMorePosts,
    triggerFeedRefresh,
    feedIsLoading,
    feedPostContainerId,
    setPostToCommentOnId,
    registerRefresh,
    action,
    error,
    feedFilters,
    setFilters,
    postsEditing,
  } = useFeedService();

  const currentFeedPostsCount = React.useMemo(() => {
    return feedPosts.length;
  }, [feedPosts.length]);

  const { registerOnReset } = feedPostCreateCtx.action;
  React.useEffect(
    () =>
      registerOnReset((postType) => {
        /**
         * Add 1 to the post count for a newly created text post since it is immediately available
         * otherwise one of the previously loaded posts would be lost during the refetch.
         */
        triggerFeedRefresh(postType === ModelPostTypesEnum.Text ? 1 : 0);
      }),
    [registerOnReset, triggerFeedRefresh]
  );

  // Refresh Account Status
  React.useEffect(() => {
    triggerAccountStatusRefresh();
  }, [triggerAccountStatusRefresh]);

  React.useEffect(() => {
    if (feedPostEditCtx.isSucessfullySubmitted) {
      triggerFeedRefresh();
      feedPostEditCtx.action.setIsSucessfullySubmitted(false);
    }
  });

  React.useEffect(() => {
    return uploadManagerCtx.action.registerOnJobFinished((jobHandle) => {
      if (jobHandle.entity.type === UploadJobEntityType.POST) {
        triggerFeedRefresh();
      }
    });
  }, [uploadManagerCtx.action, triggerFeedRefresh]);

  // Poll for updates with given interval
  React.useEffect(() => {
    const interval = setInterval(triggerFeedRefresh, 60_000);
    return () => clearInterval(interval);
  }, [triggerFeedRefresh]);

  const {
    isOpen: uspModalIsOpen,
    onOpen: openUspModal,
    onClose: closeUspModal,
  } = useDisclosure();

  const postFunctions: PostFunctionProps = React.useMemo(
    () => ({
      onClickOnPin: (postId, isPinned) =>
        action.pinClickHandler(postId, isPinned),
      onClickOnEdit: () => {},
      onClickOnDelete: (postId) => action.deleteClickHandler(postId),
      onClickOnDeleteImage: (imageId: string, postId: string) =>
        action.deleteImageHandler(imageId, postId),
      onClickOnReplaceImage: (props: ReplaceImageHandlerProps) =>
        action.replaceImageHandler(props),
      onClickOnCommentButton: (postId) => {
        setPostToCommentOnId(postId);
      },
      onClickOnComments: (postId) => {
        setPostToCommentOnId(postId);
      },
    }),
    [action, setPostToCommentOnId]
  );

  const locale = useIntl().locale;

  const feedPaidContentFlag = useFeature(FeatureName.feedPaidContent);
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);

  const getTags = (
    fsk: FskEnum,
    price: number | null | undefined,
    tippingGoal: boolean,
    photoContest: boolean
  ): string[] => {
    let tags: string[] = [];

    if (fsk)
      tags.push(t('feed:text.Ab', { fsk: fsk === FskEnum.Fsk16 ? 16 : 18 }));
    if (price) tags.push(getFormattedAmount(price, locale));
    if (tippingGoal) tags.push('Tipping Goal');
    if (photoContest) tags.push(t('feed:text.Wettbewerbsfoto'));
    return tags;
  };

  const noPostsBlockerCard = (
    <BlockerCard
      isBordered
      image={VXFeedEmptyStateImage}
      heading={t('feed:noPostsBlockerCard.heading')}
      text={t('feed:noPostsBlockerCard.text')}
      primaryButtonText={t('feed:noPostsBlockerCard.button.ErfahreMehr')}
      onClickPrimary={openUspModal}
      secondaryButtonText={t('feed:noPostsBlockerCard.button.ProbiereEsAus')}
      onClickSecondary={feedPostCreateCtx.action.openModal}
    />
  );

  const username = aboutMeCollection?.fields?.modelUsername?.value ?? '';
  const avatarImg = mediaCollection?.fields.modelPictureFSK16Avatar;

  const createFeedPostSection = (
    <CreateFeedPostSection
      image={isMobileViewport ? null : avatarImg?.value?.image.src ?? ''}
      imageAlt={'Avatar Image'}
      heading={isMobileViewport ? null : t('feed:heading.DeineVXFeedBeitrage')}
      text={
        isMobileViewport
          ? t('feed:text.HiUsernameWasMachstDuGerade', {
              username: username,
            })
          : t('feed:text.WasMachstDuGerade')
      }
      textProps={isMobileViewport ? { color: 'gray.900' } : {}}
      primaryButtonText={t('feed:button.BeitragErstellen')}
      isBordered={true}
    />
  );

  const noPostsIfFilteredBlockerCard = (
    <BlockerCard
      image={VXFeedFilteredEmptyStateImage}
      heading={t('feed:noPostsIfFilteredBlockerCard.heading')}
      text={t('feed:noPostsIfFilteredBlockerCard.text')}
    />
  );

  const uspSteps: USPModalSteps[] = [
    {
      image: { src: VXFeedUSPPosting },
      heading: t('feed:heading.Posting'),
      text: t(
        'feed:text.LadeFotosNachrichtenOderKurzeClipsHochUndMacheDeineFollowerHeissAufMXX'
      ),
    },
    {
      image: { src: VXFeedUSPTipping },
      heading: t('feed:heading.Tipping'),
      text: t(
        'feed:text.ErweitereDeineVerdienstmoglichkeitenDurchDasTippingAufDeineBeitrage'
      ),
    },
    {
      image: { src: VXFeedUSPInteraction },
      heading: t('feed:heading.Interaktion'),
      text: t(
        'feed:text.ReagiereSchnellUndEinfachAufKundenreaktionenWieLikesOderKommentare'
      ),
    },
  ];

  const isFeedEmpty = currentFeedPostsCount === 0;
  const hasFilteresSet = feedFilters.length > 0;
  const isFeedUnfilteredEmpty = isFeedEmpty && !hasFilteresSet;
  const isFeedFilteredEmpty = isFeedEmpty && hasFilteresSet;

  return (
    <>
      <USPModal
        isOpen={uspModalIsOpen}
        onClose={closeUspModal}
        closeButtonText={t('feed:button.BringMichZumFeed')}
        continueButtonText={t('button.Weiter')}
        steps={uspSteps}
      />

      <FluentPageLayout isContentLoading={isContentLoading}>
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle p={0}>{t('feed:heading.Feed')}</SubHeaderTitle>
          </Container>
        </SubHeader>
        <VStack
          maxW={'container.xl'}
          marginX={'auto'}
          alignItems={'stretch'}
          spacing={{ base: '4', lg: '8' }}
        >
          <ProfileHeader
            modelPictureTitleLandscape={
              mediaCollection?.fields.modelPictureTitleLandscape
            }
            modelPictureFSK16Avatar={avatarImg}
            username={username}
            isEditable={isAllowedToPostOnFeed}
          />
          <ProfileMenuSwitch />
          <UpdateTaxInformationExpiredHintSlot />
          {!isAllowedToPostOnFeed ? (
            <LayoutCenteringVStack outerStackProps={{ mb: '10' }}>
              <FeedStillBlockedCard />
            </LayoutCenteringVStack>
          ) : (
            <>
              {!isFeedUnfilteredEmpty ? (
                <LayoutCenteringVStack>
                  {createFeedPostSection}
                </LayoutCenteringVStack>
              ) : (
                <LayoutCenteringVStack>
                  {noPostsBlockerCard}
                </LayoutCenteringVStack>
              )}
              <FeedPostStatesSection
                unguardedDeleteClickHandler={action.deletePost}
                registerRefresh={registerRefresh}
              />
              <Section
                aria-busy={feedIsLoading}
                bg={{
                  base: 'transparent',
                  md: isFeedUnfilteredEmpty ? 'transparent' : 'surface',
                }}
              >
                {isFeedUnfilteredEmpty ? null : (
                  <SectionHeader
                    bg={{ base: 'surface', md: 'unset' }}
                    mb={{ base: '4', md: 'unset' }}
                    pb={{ base: '4', md: '4', xl: '4' }}
                    pt={{ base: '4', md: '8', xl: '8' }}
                  >
                    <SectionCenterContainer>
                      <SectionTitleRow>
                        <SectionIcon as={VxModelsLogo} />
                        <SectionTitle>{t('feed:heading.Feed')}</SectionTitle>
                        {!isFeedUnfilteredEmpty && (
                          <FeedFilterToolButtons
                            feedFilters={feedFilters}
                            setFeedFilters={setFilters}
                          />
                        )}
                      </SectionTitleRow>
                      {!isFeedFilteredEmpty ? null : (
                        <LayoutCenteringVStack>
                          {noPostsIfFilteredBlockerCard}
                        </LayoutCenteringVStack>
                      )}
                    </SectionCenterContainer>
                  </SectionHeader>
                )}
                <SectionBody
                  px={{ base: '0', md: '4' }}
                  py={{ base: '0', md: '4', xl: '4' }}
                >
                  <SectionCenterContainer>
                    {isFeedEmpty ? null : (
                      <VStack
                        id={feedPostContainerId}
                        scrollMarginTop={'24'}
                        spacing={{ base: '8' }}
                      >
                        {feedPosts.map((post) => {
                          const tags: string[] = getTags(
                            post.fsk,
                            feedPaidContentFlag ? post.price : null,
                            feedTippingGoalFlag ? !!post?.tippingGoal : false,
                            post.type === PostTypeEnum.CpPhotoContest
                          );

                          let formattedPrice = '';
                          if (post.price)
                            formattedPrice = getFormattedAmount(
                              post.price,
                              locale
                            );

                          const isPostEdited = Array.from(
                            postsEditing
                          ).includes(post.id);

                          switch (post.__typename) {
                            case 'TextPost':
                              return (
                                <TextPost
                                  key={post.id}
                                  tags={tags}
                                  publicationScheduledFor={null}
                                  {...post}
                                  {...postFunctions}
                                  onClickOnEdit={() => {
                                    feedPostEditCtx.action.openModal(post);
                                  }}
                                />
                              );
                            case 'ClipPost':
                              return (
                                <ClipPost
                                  key={post.id}
                                  tags={tags}
                                  formattedPrice={formattedPrice}
                                  publicationScheduledFor={null}
                                  {...post}
                                  {...postFunctions}
                                  onClickOnEdit={() => {
                                    feedPostEditCtx.action.openModal(post);
                                  }}
                                />
                              );
                            case 'PhotosPost':
                              if ((post?.photos?.length ?? 0) > 1) {
                                return (
                                  <PhotoGalleryPost
                                    key={post.id}
                                    tags={tags}
                                    formattedPrice={formattedPrice}
                                    isEditing={isPostEdited}
                                    {...post}
                                    {...postFunctions}
                                    onClickOnEdit={() => {
                                      feedPostEditCtx.action.openModal(post);
                                    }}
                                  />
                                );
                              }
                              return (
                                <PhotoPost
                                  key={post.id}
                                  tags={tags}
                                  formattedPrice={formattedPrice}
                                  publicationScheduledFor={null}
                                  isEditing={isPostEdited}
                                  {...post}
                                  {...postFunctions}
                                  onClickOnEdit={() => {
                                    feedPostEditCtx.action.openModal(post);
                                  }}
                                />
                              );
                            default:
                              return null;
                          }
                        })}
                      </VStack>
                    )}
                    <FetchMoreIndicator
                      mt={{ base: '8', lg: '12' }}
                      loading={feedIsLoading}
                      error={error}
                      onIsVisible={loadMorePosts}
                    />
                  </SectionCenterContainer>
                </SectionBody>
              </Section>
            </>
          )}
        </VStack>
      </FluentPageLayout>
    </>
  );
};
