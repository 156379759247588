import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  HStack,
  Heading,
  HeadingProps,
  Icon,
  Progress,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';

import { FeatureName, useFeature } from '../../../../../../flags';
import { TippingGoalFragment } from '../../../../../../generated/feed';
import { useFormattedAmount } from '../../../../../../utils/formattedAmount';

const TippingGoalText: React.FC<HeadingProps> = (props) => (
  <Heading as={'span'} size={'md'} {...props} />
);

interface FeedPostTippingGoalSlotProps {
  tippingGoal: Maybe<TippingGoalFragment>;
}

export const FeedPostTippingGoalSlot: React.FC<
  FeedPostTippingGoalSlotProps
> = ({ tippingGoal }) => {
  const { t } = useTranslation(['general', 'feed']);
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);

  const isShown = feedTippingGoalFlag && !!tippingGoal;

  const isTippingGoalReached = tippingGoal?.achieved;
  const currentTippingAmount = tippingGoal?.tipsSum ?? 0;
  const tippingGoalAmount = tippingGoal?.goal ?? 5;

  const currentTippingGoalAmountString =
    useFormattedAmount(currentTippingAmount);

  const tippingGoalAmountString = useFormattedAmount(tippingGoalAmount);

  return !isShown ? null : (
    <>
      <VStack alignItems={'stretch'} p={4} bg={'surface'}>
        {!isTippingGoalReached ? (
          <HStack>
            <Box>
              <TippingGoalText
                color={isTippingGoalReached ? 'caribbeanGreen.500' : 'inhert'}
              >
                {t('feed:label.TippingGoal')}
              </TippingGoalText>
            </Box>
            <Spacer />
            <Box textAlign={'end'}>
              <TippingGoalText
                data-amount={currentTippingAmount}
                color={'gray.300'}
              >
                {currentTippingGoalAmountString}
              </TippingGoalText>
              <TippingGoalText>{' / '}</TippingGoalText>
              <TippingGoalText data-amount={tippingGoalAmount}>
                {tippingGoalAmountString}
              </TippingGoalText>
            </Box>
          </HStack>
        ) : (
          <HStack color={'caribbeanGreen.500'}>
            <HStack spacing={'0.5'}>
              <Icon as={icons.Check} boxSize={'icon.md'} />
              <TippingGoalText>{t('feed:label.TippingGoal')}</TippingGoalText>
            </HStack>
            <Spacer />
            <Box>
              <TippingGoalText data-amount={tippingGoalAmount}>
                {tippingGoalAmountString}
              </TippingGoalText>
            </Box>
          </HStack>
        )}
        <Progress
          value={
            isTippingGoalReached ? tippingGoalAmount : currentTippingAmount
          }
          min={0}
          max={tippingGoalAmount}
        />
      </VStack>
      <Divider />
    </>
  );
};
