import {
  Section,
  SectionBody,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import { ChevronLeft } from '@campoint/odi-ui-icons';
import { Container, Flex, Icon, IconButton, VStack } from '@chakra-ui/react';
import { useFeature } from 'flagged';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import VXFeedEmptyStateImage from '../../../assets/images/shared/list-empty-state.svg';
import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { SectionContainer } from '../../../components/Layout/SectionContainer';
import { BlockerCard } from '../../../components/shared/BlockerCard/BlockerCard';
import { FeatureName } from '../../../flags';
import { FskEnum } from '../../../generated/feed';
import { useFeedPostEditModalContext } from '../../../provider/FeedPostEditModalProvider';
import { useFeedPostService } from '../../../provider/FeedPostService/FeedPostService';
import { ReplaceImageHandlerProps } from '../../../provider/FeedService/FeedService';
import { routes } from '../../../routes/routesConfig';
import { getFormattedAmount } from '../../../utils/formattedAmount';
import { ClipPost } from '../components/post/ClipPost/ClipPost';
import { PhotoGalleryPost } from '../components/post/PhotoGalleryPost/PhotoGalleryPost';
import { PhotoPost } from '../components/post/PhotoPost/PhotoPost';
import { TextPost } from '../components/post/TextPost/TextPost';
import { PostFunctionProps } from '../posts';

export const FeedPostPage: React.FC = () => {
  const { t } = useTranslation(['feed']);
  const locale = useIntl().locale;
  const feedPaidContentFlag = useFeature(FeatureName.feedPaidContent);
  const { postId } = useParams<{ postId: string }>();
  const history = useHistory();
  const feedPostEditCtx = useFeedPostEditModalContext();

  const { post, loading, action, error, isPostOwnedByUser, isPostEdited } =
    useFeedPostService();

  const { loadSinglePost } = action;

  React.useEffect(() => {
    loadSinglePost(postId);
  }, [loadSinglePost, postId]);

  React.useEffect(() => {
    if (!loading && error) {
      history.goBack();
    }
  }, [loading, error, history]);

  const getTags = (
    fsk: FskEnum,
    price: number | null | undefined
  ): string[] => {
    let tags: string[] = [];

    if (fsk)
      tags.push(t('feed:text.Ab', { fsk: fsk === FskEnum.Fsk16 ? 16 : 18 }));
    if (price) tags.push(getFormattedAmount(price, locale));
    return tags;
  };

  const postFunctions: PostFunctionProps = React.useMemo(
    () => ({
      onClickOnPin: (postId, isPinned) =>
        action.pinClickHandler(postId, isPinned),
      onClickOnDelete: (postId) => action.deleteClickHandler(postId),
      onClickOnDeleteImage: (imageId) => action.deleteImageHandler(imageId),
      onClickOnReplaceImage: (props: ReplaceImageHandlerProps) =>
        action.replaceImageHandler(props),
      onClickOnEdit: () => feedPostEditCtx.action.openModal(post),
    }),
    [action, feedPostEditCtx.action, post]
  );

  const postTypeToComponent = () => {
    if (post) {
      const tags: string[] = getTags(
        post.fsk,
        feedPaidContentFlag ? post.price : null
      );

      let formattedPrice = '';
      if (post.price) formattedPrice = getFormattedAmount(post.price, locale);

      switch (post.__typename) {
        case 'TextPost':
          return (
            <TextPost
              key={post.id}
              tags={tags}
              publicationScheduledFor={null}
              {...post}
              {...postFunctions}
              isPostOwnedByUser={isPostOwnedByUser()}
            />
          );
        case 'ClipPost':
          return (
            <ClipPost
              key={post.id}
              tags={tags}
              formattedPrice={formattedPrice}
              publicationScheduledFor={null}
              {...post}
              {...postFunctions}
              isPostOwnedByUser={isPostOwnedByUser()}
            />
          );
        case 'PhotosPost':
          if ((post?.photos?.length ?? 0) > 1) {
            return (
              <PhotoGalleryPost
                key={post.id}
                tags={tags}
                formattedPrice={formattedPrice}
                isEditing={isPostEdited}
                {...post}
                {...postFunctions}
                isPostOwnedByUser={isPostOwnedByUser()}
              />
            );
          }
          return (
            <PhotoPost
              key={post.id}
              tags={tags}
              formattedPrice={formattedPrice}
              publicationScheduledFor={null}
              isEditing={isPostEdited}
              {...post}
              {...postFunctions}
              isPostOwnedByUser={isPostOwnedByUser()}
            />
          );
        default:
          return null;
      }
    }
  };

  const noPostBlockerCard = (
    <BlockerCard
      image={VXFeedEmptyStateImage}
      heading={t('feed:noPostBlockerCard.heading')}
      text={t('feed:noPostBlockerCard.text')}
    />
  );

  return (
    <FluentPageLayout isContentLoading={loading}>
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <Flex
            alignItems={'center'}
            onClick={() => {
              history.push(routes.feed.path);
            }}
          >
            <IconButton
              variant="unstyled"
              aria-label={'BackButtonEditPage'}
              icon={<Icon as={ChevronLeft} boxSize="6" />}
            />
            <SubHeaderTitle cursor={'pointer'}>
              {t('feed:heading.FeedPostPage')}
            </SubHeaderTitle>
          </Flex>
        </Container>
      </SubHeader>
      <VStack
        maxW={'container.xl'}
        marginX={'auto'}
        alignItems={'stretch'}
        spacing={{ base: '4', lg: '8' }}
      >
        <Section
          aria-busy={loading}
          bg={{ base: 'transparent', md: 'surface' }}
        >
          <SectionContainer maxWidth={'768px'}>
            <SectionBody
              px={{ base: '0', md: '4' }}
              py={{ base: '0', md: '4', xl: '4' }}
              maxW={'768px'}
            >
              <VStack scrollMarginTop={'24'} spacing={{ base: '8' }}>
                {Object.keys(post).length === 0
                  ? noPostBlockerCard
                  : postTypeToComponent()}
              </VStack>
            </SectionBody>
          </SectionContainer>
        </Section>
      </VStack>
    </FluentPageLayout>
  );
};
