import { Circle, ListItem, ListProps, UnorderedList } from '@chakra-ui/react';

type DotSize = 'sm' | 'md' | 'lg';

interface StepDotGroupProps extends ListProps {
  currentStep: number;
  dotSize?: DotSize;
  totalSteps: number;
}

const getDotSize = (size: DotSize): number => {
  // Default dot size for sm
  let dotSize = 2;
  switch (size) {
    case 'md':
      dotSize = 4;
      break;
    case 'lg':
      dotSize = 6;
      break;
  }

  return dotSize;
};

/**
 * @param {number} totalSteps total count of steps that should be displayed
 * @param {number} currentStep highlighted step
 * @param {DotSize} dotSize size of the dots
 */
function createDotElements(
  totalSteps: number,
  currentStep: number,
  dotSize: DotSize
) {
  const elements = [];

  for (let i = 1; i <= totalSteps; i++) {
    elements.push(
      <ListItem key={`wizard-dot-item-${i}`} role={'listitem'}>
        <Circle
          size={getDotSize(dotSize)}
          bg={
            i === currentStep
              ? 'secondary.highEmphasis'
              : 'onSurface.layoutHelper'
          }
        />
      </ListItem>
    );
  }
  return elements;
}

export const StepDotGroup: React.FC<StepDotGroupProps> = ({
  currentStep,
  totalSteps,
  dotSize = 'sm',
  ...rest
}) => {
  const stepDotElements = createDotElements(totalSteps, currentStep, dotSize);
  return (
    <UnorderedList
      data-steps={totalSteps}
      display={'flex'}
      listStyleType={'none'}
      gap={2}
      m={0}
      {...rest}
    >
      {stepDotElements}
    </UnorderedList>
  );
};
