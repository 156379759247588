import { ImageNotSupported } from '@campoint/odi-ui-icons';
import {
  ContainerProps,
  HStack,
  Icon,
  Image,
  ImageProps,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FeedPhotoMediaPictureFragment,
  FskEnum,
} from '../../../../generated/feed';
import { ZStack } from '../../../Layout/ZStack';

export const BackgroundImageContainer: React.FC<
  ContainerProps & { backgroundImageProps: ImageProps }
> = ({ children, backgroundImageProps, ...rest }) => {
  return (
    <ZStack maxW={'full'} w={'full'} h={'full'} p={'unset'} {...rest}>
      <Image
        aria-hidden
        w={'full'}
        h={'full'}
        position={'absolute'}
        left={0}
        right={0}
        top={0}
        bottom={0}
        objectFit={'cover'}
        {...backgroundImageProps}
      />
      <HStack
        position={'absolute'}
        left={0}
        right={0}
        top={0}
        bottom={0}
        justifyContent={'center'}
        background={'blackAlpha.600'}
        backdropFilter={'blur(8px)'}
        children={children}
      />
    </ZStack>
  );
};

export const GalleryImage: React.FC<
  ImageProps & { tags: string[]; fsk: Maybe<FskEnum> }
> = ({ tags, fsk, ...props }) => {
  const { t } = useTranslation(['feed']);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  return (
    <HStack
      data-testid={'image-content-container'}
      aria-busy={isLoading}
      width={'full'}
      height={'full'}
      justifyContent={'center'}
    >
      {isLoading && !hasError && (
        <Skeleton
          h={'full'}
          w={'full'}
          startColor={'steel'}
          endColor={'primary.200'}
        />
      )}
      {hasError && (
        <VStack
          w={'full'}
          h={'full'}
          justifyContent={'center'}
          background={'steel'}
        >
          <Text
            fontSize={'2xl'}
            fontWeight={'bold'}
            color={'coldGray.900'}
            children={t('feed:text.Oops')}
          />
          <Icon
            as={ImageNotSupported}
            boxSize={'3.75rem'}
            color={'darkSteel'}
          />
          <Text
            maxW={'36'}
            textAlign={'center'}
            color={'coldGray.900'}
            lineHeight={'1.1'}
            children={t('feed:text.InhaltKonnteNichtGeladenWerden')}
          />
        </VStack>
      )}
      <Image
        hidden={hasError}
        onError={() => setHasError(true)}
        onLoad={() => setIsLoading(false)}
        {...props}
      />
    </HStack>
  );
};

export const ImageContent: React.FC<
  {
    images: Partial<FeedPhotoMediaPictureFragment>[];
    tags: string[];
    currentImageIndex: number;
  } & ContainerProps
> = ({ images, currentImageIndex, tags, ...props }) => {
  return (
    <>
      {images.map((image, index) => (
        <BackgroundImageContainer
          key={index}
          backgroundImageProps={{
            loading: 'lazy',
            src: image.url,
            srcSet: image.srcSet,
          }}
          hidden={index === currentImageIndex ? false : true}
          {...props}
        >
          <GalleryImage
            loading="lazy"
            src={image.url}
            srcSet={image.srcSet}
            fsk={image.fsk}
            tags={tags}
            maxW={'full'}
            maxH={'full'}
          />
        </BackgroundImageContainer>
      ))}
    </>
  );
};
