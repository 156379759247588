import { PowerSettingsNew } from '@campoint/odi-ui-icons';
import { HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ExperienceIntentionStatusIndicator: React.FC<StackProps> = (
  props
) => {
  return (
    <HStack
      position={'absolute'}
      spacing={'1.5'}
      p={'4px 8px 4px 8px'}
      top={0}
      left={0}
      backgroundColor={'success.highEmphasis'}
      borderBottomEndRadius={4}
      {...props}
    />
  );
};

export const ExperienceIntentionStatusIndicatorIncomeSources = () => {
  const { t } = useTranslation(['incomeSourcesPage']);
  return (
    <ExperienceIntentionStatusIndicator>
      <Icon as={PowerSettingsNew} boxSize={'icon.md'} color={'white'} />
      <Text color={'white'}>{t('incomeSourcesPage:label.Aktiv')}</Text>
    </ExperienceIntentionStatusIndicator>
  );
};
