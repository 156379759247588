import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import {
  Box,
  Center,
  Circle,
  Container,
  Divider,
  FormLabel,
  HStack,
  Heading,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { useFeatures } from 'flagged';
import React from 'react';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import ENV from '../../environments/environment';

const FeatureFlagPage: React.FC = () => {
  const { version } = require('./../../../package.json');
  const features = useFeatures();
  return (
    <FluentPageLayout isContentLoading={false}>
      <Box>
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle p={0}>Feature Flags</SubHeaderTitle>
          </Container>
        </SubHeader>
        <Container maxW="container.xl" p={0}>
          <Heading
            mt={8}
            fontSize={{ base: 'xl', lg: '4xl' }}
            noOfLines={1}
            wordBreak={'break-word'}
            px={{ base: 4, xl: 0 }}
          >
            {ENV.ENVIRONMENT}
          </Heading>
          <VStack
            justifyContent={'start'}
            alignItems={'start'}
            my={4}
            spacing={4}
            px={{ base: 4, xl: 0 }}
          >
            {Object.keys(features)
              .sort()
              .map((flag, idx) => {
                return (
                  <HStack w={'full'} key={idx}>
                    <chakra.label
                      flexGrow={1}
                      textStyle={'bodyMd'}
                      children={flag}
                      as={FormLabel}
                    />
                    {features[flag] ? (
                      <Circle size={4} bg={'success.highEmphasis'} />
                    ) : (
                      <Circle size={4} bg={'error.highEmphasis'} />
                    )}
                  </HStack>
                );
              })}
          </VStack>
          <Divider my={4} />
          <Box mb={4}>
            <Center>Version {version}</Center>
          </Box>
        </Container>
      </Box>
    </FluentPageLayout>
  );
};

export default FeatureFlagPage;
