import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogModal } from '../../../../../components/shared/DialogModal/DialogModal';

export const SubmitChangesButtonWithConfirmation: React.FC<
  { onConfirmedSubmit: (e: React.MouseEvent) => void } & ButtonProps
> = ({ onConfirmedSubmit, ...buttonProps }) => {
  const { t } = useTranslation(['payout', 'document']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        alignSelf={'center'}
        variant={'solid'}
        onClick={onOpen}
        {...buttonProps}
      >
        {t('document:button.AnderungenEinreichen')}
      </Button>
      <DialogModal
        isOpen={isOpen}
        onClose={onClose}
        headline={t(
          'payout:headline.MochtestDuDeineAnderungenZurUberprufungEinreichen'
        )}
        confirmButtonText={t('payout:button.SpeichernEinreichen')}
        onConfirm={(e) => {
          onClose();
          onConfirmedSubmit(e);
        }}
        closeButtonText={t('payout:button.AnderungenNochEinmalKontrollieren')}
        onCloseComplete={onClose}
      />
    </>
  );
};
