import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';

export const PrimaryButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    data-testid={'primary-button'}
    variant={'solid'}
    minW={'12rem'}
    ref={ref}
    {...props}
  />
));

export const SecondaryButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => (
    <Button
      data-testid={'secondary-button'}
      variant={'outline'}
      ref={ref}
      {...props}
    />
  )
);
