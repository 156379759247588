import { Info } from '@campoint/odi-ui-icons';
import { Icon, Text, VStack } from '@chakra-ui/react';

export type StatisticOverviewHeaderProps = {
  icon: typeof Info;
  text: string;
  children?: React.ReactNode;
};

export const StatisticOverviewHeader: React.FC<
  StatisticOverviewHeaderProps
> = ({ icon, text, children }) => {
  return (
    <VStack
      p={'4'}
      textAlign={'center'}
      bg={'white'}
      border={'1px solid'}
      borderColor={'steel'}
      borderRadius={'lg'}
      w={'full'}
    >
      <VStack
        w={'24px'}
        h={'24px'}
        bg={'steel'}
        borderRadius={'5px'}
        justifyContent={'center'}
      >
        {icon && <Icon as={icon} color={'darkSteel'} h={'16px'} w={'16px'} />}
      </VStack>
      <Text fontSize={'md'} fontWeight={'700'} color={'gray.900'}>
        {text ?? ''}
      </Text>
      {children}
    </VStack>
  );
};
