import * as icons from '@campoint/odi-ui-icons';
import {
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import { LoadingState } from '../../components/shared/StatisticModal/LoadingState';
import { StatisticOverviewHeader } from '../../components/shared/StatisticModal/StatisticOverviewHeader';
import { LikesTab } from '../../components/shared/StatisticModal/Tabs/LikesTab';
import { SalesTab } from '../../components/shared/StatisticModal/Tabs/SalesTab';
import { useNumberShortFormatter } from '../../hooks/useNumberShortFormatter';
import { useQueryParamState } from '../../hooks/useQueryParamState';
import { useVideoLibraryPhotoStatisticModalContext } from '../../provider/VideoLibraryProvider/VideoLibraryStatisticModalProvider/VideoLibraryPhotoStatisticModalProvider';
import { MediaRef } from './components/MediaRefBanner/MediaRefBanner';

type VideoLibraryStatisticModalOverviewHeaderProps = {
  icon: typeof icons.Info;
  text: string;
  count: string;
};

const VideoLibraryStatisticModalOverviewHeader: React.FC<
  VideoLibraryStatisticModalOverviewHeaderProps
> = ({ icon, text, count }) => {
  return (
    <StatisticOverviewHeader icon={icon} text={text}>
      <Text
        fontSize={{ base: 'lg', md: '2xl' }}
        fontWeight={'700'}
        color={'coldGray.900'}
      >
        {count}
      </Text>
    </StatisticOverviewHeader>
  );
};

export const VideoLibraryPhotoStatisticModal: React.FC = () => {
  const { t } = useTranslation(['videoStatisticModal']);
  const {
    isOpen,
    actions,
    photoalbum,
    loading,
    likes,
    likesLoading,
    likesError,
    loadMoreLikes,
    sales,
    salesLoading,
    salesError,
    loadMoreSales,
  } = useVideoLibraryPhotoStatisticModalContext();

  const numberFormatter = useNumberShortFormatter();

  const [tabId, setTabId] = useQueryParamState('modalTab');

  //!maybe there will be notifications in the future linking to these tab ids
  const tabIds = React.useMemo(() => {
    return ['videoLikes', 'videoSales'];
  }, []);

  const tabIndex = React.useMemo(() => {
    if (!tabId) {
      return 0;
    }
    const index = tabIds.indexOf(tabId);
    return index >= 0 ? index : 0;
  }, [tabId, tabIds]);

  const handleTabChange = React.useCallback(
    (index: number) => {
      const newtabId = tabIds[index] ?? tabIds[0];
      setTabId(newtabId);
    },
    [tabIds, setTabId]
  );

  const tabIndexToOverview: Array<VideoLibraryStatisticModalOverviewHeaderProps> =
    [
      {
        icon: icons.ThumbUp,
        text: t('videoStatisticModal:LikesGesamt'),
        count: numberFormatter(photoalbum?.likesCount ?? 0),
      },
      {
        icon: icons.Payments,
        text: t('videoStatisticModal:VerkaufeGesamt'),
        count: numberFormatter(photoalbum?.salesCount ?? 0),
      },
    ];

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={() => {
        setTabId(null);
        actions.closeModal();
      }}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <Heading
            children={t('videoStatisticModal:heading.Statistiken')}
            size={'md'}
            fontSize={'md'}
            letterSpacing={'wide'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        {photoalbum && !loading ? (
          <>
            <MediaRef media={photoalbum} />
            <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
              <VStack bg={'lightBackground'}>
                <HStack
                  spacing={'2'}
                  m={'4'}
                  minW={'288px'}
                  justifyContent={'center'}
                  alignItems={'stretch'}
                >
                  <VideoLibraryStatisticModalOverviewHeader
                    icon={tabIndexToOverview[tabIndex].icon}
                    text={tabIndexToOverview[tabIndex].text}
                    count={tabIndexToOverview[tabIndex].count}
                  />
                </HStack>
              </VStack>
              <Tabs isFitted isLazy index={tabIndex} onChange={handleTabChange}>
                <TabList bg={'lightBackground'}>
                  <Tab children={t('videoStatisticModal:tabs.Likes')} />
                  <Tab children={t('videoStatisticModal:tabs.Verkaufe')} />
                </TabList>
                <TabPanels py={'6'} px={'4'}>
                  <TabPanel
                    p={0}
                    children={
                      <LikesTab
                        likes={likes?.map((e) => {
                          return {
                            userId: e.userId.toString(),
                            username: e.userName,
                            avatarUrl: e.avatarUrl,
                            likedAt: e.date,
                          };
                        })}
                        likesLoading={likesLoading}
                        likesError={likesError}
                        loadMoreLikes={loadMoreLikes}
                      />
                    }
                  />
                  <TabPanel
                    p={0}
                    children={
                      <SalesTab
                        sales={sales?.map((e) => {
                          return {
                            userId: e.userId.toString(),
                            userName: e.userName,
                            avatarUrl: e.avatarUrl,
                            soldAt: e.date,
                            discount: e.discountPercent,
                            price: e.guestPrice,
                          };
                        })}
                        salesLoading={salesLoading}
                        salesError={salesError}
                        loadMoreSales={loadMoreSales}
                      />
                    }
                  />
                </TabPanels>
              </Tabs>
            </ResponsiveModalBodyBox>
          </>
        ) : (
          <LoadingState />
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
