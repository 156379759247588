import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  HStack,
  Heading,
  Icon,
  IconButton,
  Img,
  List,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { DateTime, Interval } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import payoutImage from '../../../../assets/images/finance/setup-payout.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { FinancesInvoice } from '../../../../generated/graphql';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { InvoiceListItem } from '../../components/Payout/InvoicePicker/components/InvoiceListItem/InvoiceListItem';

export const OldPayoutWithdrawalsSection: React.FC = () => {
  const { t } = useTranslation(['withdrawl']);

  const { invoiceDataLoading, currentYear, invoiceYearBuckets, invoiceList } =
    useFinanceService();

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={icons.ReceiptLong} />
            <SectionTitle children={t('withdrawl:heading.DeineAuszahlungen')} />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody pos={'relative'} minH={'400px'}>
        <SectionCenterContainer>
          {invoiceDataLoading ? (
            <AbsoluteFullCenterLoadingSpinner />
          ) : (
            <InvoicePicker
              initialSelectedDate={currentYear}
              dates={invoiceYearBuckets}
              invoices={invoiceList}
            />
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

type InvoicePickerProps = {
  dates: number[];
  initialSelectedDate: number;
  invoices: FinancesInvoice[];
};

const InvoicePicker: React.FC<InvoicePickerProps> = ({
  initialSelectedDate: initialYear,
  dates: years,
  invoices,
}) => {
  const [selectedYear, setSelectedYear] = useState(initialYear);

  const filteredInvoices = React.useMemo(
    () => filterInvoicesByYear(selectedYear, invoices),
    [invoices, selectedYear]
  );

  const selectNextYear = () => {
    const currentIndex = years.indexOf(selectedYear);
    const nextIndex = currentIndex + 1;
    const isValidIndex = nextIndex < years.length && nextIndex >= 0;

    if (isValidIndex) {
      setSelectedYear(years[nextIndex]);
    }
  };
  const selectPreviousYear = () => {
    const currentIndex = years.indexOf(selectedYear);
    const nextIndex = currentIndex - 1;
    const isValidIndex = nextIndex < years.length && nextIndex >= 0;

    if (isValidIndex) {
      setSelectedYear(years[nextIndex]);
    }
  };

  return (
    <Box minH={'32rem'}>
      <Box mb={4}>
        <VStack alignItems={'stretch'}>
          <HStack>
            <IconButton
              aria-label={'Previous Year'}
              icon={<Icon as={icons.ChevronLeft} boxSize="icon.md" />}
              onClick={selectPreviousYear}
              isDisabled={selectedYear === years[0]}
            />
            <Heading textAlign={'center'} flexGrow={1} size={'xl'}>
              {selectedYear}
            </Heading>
            <IconButton
              aria-label={'Next Year'}
              icon={<Icon as={icons.ChevronRight} boxSize="icon.md" />}
              onClick={selectNextYear}
              isDisabled={selectedYear === years[years.length - 1]}
            />
          </HStack>
        </VStack>
      </Box>
      <InvoiceList invoices={filteredInvoices} />
    </Box>
  );
};

export default InvoicePicker;

const InvoiceList: React.FC<{ invoices: FinancesInvoice[] }> = ({
  invoices,
}) => {
  if (invoices.length <= 0) {
    return <FinanceYourWithdrawalsEmptyYear />;
  }
  return <FinanceYourWithdrawalsContentState invoices={invoices} />;
};

function filterInvoicesByYear(
  selectedYear: number,
  invoices: FinancesInvoice[]
) {
  const selectedYearDate = DateTime.fromObject({
    year: selectedYear,
  }).startOf('year');
  const selectedInterval = Interval.fromDateTimes(
    selectedYearDate,
    selectedYearDate.plus({ year: 1 })
  );
  return invoices.filter((invoice) => {
    if (!invoice.payoutDate) {
      return false;
    }
    const date = DateTime.fromISO(invoice.payoutDate);
    return selectedInterval.contains(date);
  });
}

const financeContentStateContainerTestId = 'finance-content-state-container';
type FinanceYourWithdrawalsContentStateProps = {
  invoices: FinancesInvoice[];
};

const FinanceYourWithdrawalsContentState: React.FC<
  FinanceYourWithdrawalsContentStateProps
> = (props) => (
  <VStack
    as={List}
    divider={<Divider />}
    alignItems={'stretch'}
    spacing={1}
    data-testid={financeContentStateContainerTestId}
  >
    {props.invoices.length > 0 &&
      props.invoices.map((invoice, index) => {
        return <InvoiceListItem invoice={invoice} key={`invoice-${index}`} />;
      })}
  </VStack>
);

const StyledEmptyState: React.FC<StackProps> = (props) => (
  <VStack
    alignItems="center"
    justifyItems="center"
    textAlign="center"
    p="4"
    {...props}
  />
);

const financeEmptyStateContainerTestId = 'finance-empty-state-container';

const FinanceYourWithdrawalsEmptyYear: React.FC = () => {
  const { t } = useTranslation(['payout', 'financePage']);

  return (
    <StyledEmptyState data-testid={financeEmptyStateContainerTestId}>
      <Img
        src={payoutImage}
        alt={t('financePage:img.KundeZahltWahrendEinerShow')}
      />
      <Heading
        as="h4"
        size="xl"
        mb="2"
        children={t(
          'payout:headline.InDiesemJahrHastDuKeineAuszahlungAusgefuhrt'
        )}
      />
    </StyledEmptyState>
  );
};
