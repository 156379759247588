import { Heading, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { usePostCommenting } from '../../../provider/PostCommentingProvider';
import { noop } from '../../../utils';
import Logger from '../../../utils/Logger';
import { AbsoluteFullCenterLoadingSpinner } from '../../Layout/AbsoluteFullCenterLoadingSpinner';
import {
  ResponsiveModal,
  ResponsiveModalBodyBlankBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
  ResponsiveModalStickySubHeaderBox,
} from '../../Layout/ResponsiveModal';
import { FeedPostRef } from '../FeedCommentPostPreview/FeedCommentPostPreview';
import { ConfirmCommentDiscardDialog } from '../dialogs/ConfirmCommentDiscardDialog/ConfirmCommentDiscardDialog';
import { CommentCreate } from './CommentCreate';
import { FeedConversation } from './FeedConversation';
import { CommentList, itemVariants, listVariants } from './shared';

export const feedCommentModalSubHeaderId = 'feed-comment-modal-sub-header-id';
export const FeedCommentModal: React.FC<{}> = () => {
  const discardChangesDialog = useDisclosure({ defaultIsOpen: false });
  const commentingCtx = usePostCommenting();
  const { conversations } = commentingCtx;
  const { t } = useTranslation(['feed']);

  const onConditionClose = React.useCallback(() => {
    if (commentingCtx.isDirty) {
      discardChangesDialog.onOpen();
    } else {
      commentingCtx.action.closeModal();
    }
  }, [commentingCtx.isDirty, commentingCtx.action, discardChangesDialog]);

  const onDiscardChanges = React.useCallback(() => {
    discardChangesDialog.onClose();
    commentingCtx.action.closeModal();
  }, [commentingCtx.action, discardChangesDialog]);

  const [scrollDirection, setScrollDirection] = React.useState<
    'down' | 'up' | null
  >(null);

  const lastScrollY = React.useRef(0);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [showHeaderShadow, setShowHeaderShadow] = React.useState(true);

  const updateScrollDirection: React.UIEventHandler<HTMLDivElement> = (e) => {
    const scrollY = e.currentTarget.scrollTop ?? 0;
    const direction = scrollY > lastScrollY.current ? 'down' : 'up';
    if (direction !== scrollDirection) {
      setScrollDirection(direction);
    }
    toggleHeaderShadow(scrollY);
    lastScrollY.current = scrollY > 0 ? scrollY : 0;
  };

  const toggleHeaderShadow = (scrollY: number) => {
    //auf desktop ab < 64 -> isTop true
    const shadowHeight = isMobile ? 0 : 64;
    if (scrollY > shadowHeight && showHeaderShadow) {
      setShowHeaderShadow(false);
    }
    if (scrollY <= shadowHeight) {
      setShowHeaderShadow(true);
    }
  };

  const headerRef = React.useRef<HTMLDivElement>(null);
  const extendedHeaderRef = React.useRef<HTMLDivElement>(null);
  const headerHeight = headerRef.current?.clientHeight;
  const subHeaderHeight = (extendedHeaderRef.current?.clientHeight || 0) * -1;

  return (
    <ResponsiveModal
      isOpen={commentingCtx.isOpen}
      onClose={onConditionClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ConfirmCommentDiscardDialog
        navigateBack={onDiscardChanges}
        isOpen={discardChangesDialog.isOpen}
        onClose={discardChangesDialog.onClose}
      />
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        maxW={'567px'}
        containerProps={{ onScroll: updateScrollDirection }}

        /**
        * todo: decide how/if maxHeight is used here
        ```
        maxHeight={{ base: 'unset', lg: '830px' }}
        overflowY={{ base: 'unset', lg: 'scroll' }}
        ```
        */
      >
        <ResponsiveModalStickyHeaderBox
          ref={headerRef}
          scrollDirection={scrollDirection}
        >
          <Heading
            as={'h1'}
            children={t('feed:heading.Kommentare')}
            size={'md'}
            fontSize={'md'}
            letterSpacing={'wide'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalStickySubHeaderBox
          ref={extendedHeaderRef}
          scrollDirection={scrollDirection}
          showHeaderShadow={showHeaderShadow}
          headerHeight={headerHeight ?? 0}
          subHeaderHeight={subHeaderHeight ?? 0}
          data-testid={feedCommentModalSubHeaderId}
        >
          <FeedPostRef post={commentingCtx.post} />
          <ResponsiveModalBodyBlankBox p="4">
            <CommentCreate
              onSubmit={async (values, formikHelpers) => {
                try {
                  const result = await commentingCtx.action.addComment(
                    values?.text
                  );
                  if (result.errors) {
                    Logger.error(result.errors);
                  }
                } catch (error) {
                  Logger.error(error);
                  formikHelpers.setSubmitting(false);
                }
                formikHelpers.resetForm();
              }}
              onClose={noop}
              showAvatar={false}
              commentAutoFocus={conversations.length === 0}
              placeholder={t('feed:placeholder.BeitragKommentieren')}
              registerOpenComment={commentingCtx.action.registerOpenComment}
            />
          </ResponsiveModalBodyBlankBox>
        </ResponsiveModalStickySubHeaderBox>
        <ResponsiveModalBodyBlankBox px="4" pb="4">
          {commentingCtx.isLoading ? (
            <AbsoluteFullCenterLoadingSpinner />
          ) : (
            <CommentList>
              <AnimatePresence initial={true}>
                <motion.div
                  key={commentingCtx.post.id}
                  initial="inactive"
                  animate="active"
                  variants={listVariants}
                  exit="exit"
                >
                  <AnimatePresence initial={false} exitBeforeEnter={false}>
                    {conversations.map((conversation) => {
                      return (
                        <motion.div
                          key={conversation.comment.id + 'listItem'}
                          variants={itemVariants}
                          initial="inactive"
                          animate="active"
                          exit="exit"
                        >
                          <FeedConversation
                            key={conversation.comment.id}
                            conversation={conversation}
                          />
                        </motion.div>
                      );
                    })}
                  </AnimatePresence>
                </motion.div>
              </AnimatePresence>
            </CommentList>
          )}
        </ResponsiveModalBodyBlankBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
