import {
  ChevronRight,
  EmojiEvents,
  ModeComment,
  MoreHoriz,
  Payments,
  PushPin,
  PushPinOutline,
  ShoppingCart,
  ThumbUpAlt,
  Tipping,
  Visibility,
} from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  Button,
  Container,
  ContainerProps,
  Divider,
  Flex,
  HStack,
  Heading,
  HeadingProps,
  Icon,
  IconButton,
  IconButtonProps,
  Image,
  ImageProps,
  Spacer,
  StackProps,
  Text,
  TextProps,
  VStack,
  chakra,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureName, useFeature } from '../../../flags';
import { PostStatusEnum } from '../../../generated/feed';
import { ContestFragment } from '../../../generated/graphql';
import { useNumberShortFormatter } from '../../../hooks/useNumberShortFormatter';
import { TempFeedPostPhotoPostProp } from '../../../pages/FeedPage/components/post/component/types';
import { useFeedPostWrapper } from '../../../pages/FeedPage/hooks/useFeedPostWrapper';
import { useCreateFeedPostContext } from '../../../provider/FeedPostCreateModalProvider';
import { TusUploadEntry } from '../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/videoUploadEnhancer';
import { AgeIndicator } from '../AgeIndicator/AgeIndicator';
import ErrorIcon from '../ErrorIcon/ErrorIcon';
import {
  DestructiveMenuModalItem,
  MenuModal,
  MenuModalContent,
  MenuModalItem,
  MenuModalItemList,
  MenuModalOverlay,
} from '../FeedPostMenu/FeedPostMenu';
import { ImageGalleryTag } from '../ImageGallery/components/ImageGalleryTag';
import { MetricCountButton } from '../buttons/MetricCountButton/MetricCountButton';

export const FeedPostHeader: React.FC<StackProps> = (props) => {
  return (
    /**
     * Flex is used, because Chakra's Stack components don't allow custom margins on their child elements:
     * For reference: https://github.com/chakra-ui/chakra-ui/issues/2578#issuecomment-743109898
     */
    <Flex
      alignItems={'center'}
      bgColor={'white'}
      p={'2.5'}
      minH={70}
      gap={'2.5'}
      {...props}
    />
  );
};

export const FeedPostModelImage: React.FC<ImageProps> = (props) => {
  return (
    <Image
      borderRadius={'full'}
      bgColor={'steel'}
      w={50}
      h={50}
      sizes={'(max-width: 800px) 100vw, 800px'}
      {...props}
    />
  );
};

export const FeedPostHeaderStack: React.FC<StackProps> = (props) => (
  <VStack spacing={0} alignItems={'start'} lineHeight={'6'} {...props} />
);

export const FeedPostHeaderTitle: React.FC<TextProps> = (props) => (
  <Text
    fontWeight={'medium'}
    noOfLines={1}
    wordBreak={'break-all'}
    color={'coldGray.900'}
    {...props}
  />
);

export const FeedPostHeaderDataRow: React.FC<StackProps> = (props) => (
  <Flex alignItems={'center'} columnGap={'2.5'} wrap={'wrap'} {...props} />
);

export const FeedPostDate: React.FC<{ children: string } & TextProps> = ({
  children,
  ...props
}) => {
  return <AgeIndicator created={children} />;
};

export const FeedPostDataText: React.FC<TextProps> = (props) => (
  <Text color={'gray.300'} fontSize={'sm'} {...props} />
);

export const FeedPostTags: React.FC<{ tags: string[] }> = ({ tags }) => (
  <HStack
    position={'absolute'}
    p={'3'}
    top={0}
    left={0}
    spacing={'0.5'}
    pointerEvents={'none'}
  >
    {tags.map((tag) => (
      <ImageGalleryTag key={tag} children={tag} />
    ))}
  </HStack>
);

export const FeedPostCenteredButtons: React.FC<{
  onClick?: () => void;
  children: React.ReactNode;
}> = ({ onClick, children }) => {
  return (
    <VStack
      onClick={onClick}
      position={'absolute'}
      left={'50%'}
      transform={'translate(-50%)'}
      spacing={4}
      zIndex={1}
      cursor={onClick ? 'pointer' : 'default'}
      pointerEvents={'none'}
    >
      {children}
    </VStack>
  );
};

export const FeedPostBuyButton: React.FC<{
  formattedPrice: string;
  isVideo?: boolean;
}> = ({ formattedPrice, isVideo = false }) => {
  const { t } = useTranslation(['feed']);
  const feedPaidContentFlag = useFeature(FeatureName.feedPaidContent);

  if (!feedPaidContentFlag) {
    return null;
  }

  return (
    <Button
      children={t('feed:button.Kaufen', { price: formattedPrice })}
      leftIcon={<Icon as={ShoppingCart} boxSize={'6'} />}
      cursor={isVideo ? 'pointer' : 'default'}
      borderRadius={'6px'}
      disabled={true}
      color={'surface'}
      _disabled={{ color: 'surface' }}
      _active={{ color: 'surface' }}
      _hover={{ color: 'surface' }}
      boxShadow={'0px 1px 2px 1px rgba(0, 0, 0, 0.05);'}
      pointerEvents={'none'}
    />
  );
};

export const FeedPostComments: React.FC<{
  commentCount: number;
  onClickOnCommentButton: () => void;
}> = ({ commentCount, onClickOnCommentButton }) => {
  const { t } = useTranslation(['feed']);

  return (
    <>
      <Divider />
      {commentCount > 0 && (
        <Button
          variant={'link'}
          color={'gray.900'}
          onClick={onClickOnCommentButton}
        >
          {commentCount > 1
            ? t('feed:button.AlleKommentareAnsehenPlural', { commentCount })
            : t('feed:button.AlleKommentareAnsehenSingular')}
        </Button>
      )}
      {commentCount === 0 && (
        <Button
          variant={'link'}
          color={'gray.900'}
          onClick={onClickOnCommentButton}
        >
          {t('feed:button.KommentarVerfassen')}
        </Button>
      )}
    </>
  );
};

export const FeedPostViews: React.FC<StackProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <HStack spacing={'5px'} color={'gray.300'} fontSize={'sm'} {...rest}>
      <Icon as={Visibility} boxSize={'6'} />
      <Text children={children} />
    </HStack>
  );
};

export const FeedPostPin: React.FC<
  { isPinned: boolean; onClickOnPin: () => void } & Partial<IconButtonProps>
> = ({ isPinned = false, onClickOnPin, ...props }) => {
  const { t } = useTranslation(['feed']);
  const filledPin = <Icon as={PushPin} boxSize={'6'} color={'gray.900'} />;
  const outlinedPin = (
    <Icon as={PushPinOutline} boxSize={'6'} color={'gray.300'} />
  );

  return (
    <IconButton
      aria-label={
        isPinned ? t('feed:label.PinEntfernen') : t('feed:label.PostAnpinnen')
      }
      variant={'ghost'}
      icon={isPinned ? filledPin : outlinedPin}
      onClick={onClickOnPin}
      {...props}
    />
  );
};

export const FeedPostMenu: React.FC<
  {
    onClickOnEdit: null | (() => void);
    onClickOnDelete: null | (() => void);
  } & Partial<IconButtonProps>
> = ({ onClickOnEdit, onClickOnDelete, ...props }) => {
  const { t } = useTranslation(['feed']);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const hasMenutItems = !!onClickOnEdit || !!onClickOnDelete;

  const editClickHandler = () => {
    onClickOnEdit?.();
    onClose();
  };

  const deleteClickHandler = () => {
    onClickOnDelete?.();
    onClose();
  };

  return !hasMenutItems ? (
    <></>
  ) : (
    <>
      <IconButton
        aria-label={t('feed:label.MehrOptionen')}
        variant={'ghost'}
        icon={<Icon as={MoreHoriz} boxSize={'6'} color={'coldGray.900'} />}
        onClick={onOpen}
        {...props}
      />

      <MenuModal isOpen={isOpen} onClose={onClose}>
        <MenuModalOverlay />
        <MenuModalContent>
          <MenuModalItemList>
            {!onClickOnEdit ? (
              <></>
            ) : (
              <MenuModalItem onClick={editClickHandler}>
                {t('feed:menuItem.BeitragBearbeiten')}
              </MenuModalItem>
            )}
            {!onClickOnDelete ? (
              <></>
            ) : (
              <DestructiveMenuModalItem onClick={deleteClickHandler}>
                {t('feed:menuItem.BeitragLoschen')}
              </DestructiveMenuModalItem>
            )}
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
    </>
  );
};

export const FeedPostButtonWrapper: React.FC<StackProps> = (props) => (
  <HStack ml={'auto'} spacing={'1.5'} {...props} />
);

export const FeedPostBody: React.FC<ContainerProps> = (props) => (
  <Container p={0} m={0} maxW={'full'} bgColor={'steel'} {...props} />
);

export const FeedPostFooter: React.FC<StackProps> = (props) => {
  return (
    <VStack
      alignItems={'start'}
      bgColor={'white'}
      p={'4'}
      spacing={'4'}
      {...props}
    />
  );
};

export const FeedPostFooterMetrics: React.FC<{
  likes: number;
  visiblePhotoLikes?: number | undefined;
  comments: number;
  tips: number;
  isPaidContent: boolean;
  contest?: ContestFragment | null;
  purchaseCount: number;
  onClickOnLikes?: () => void;
  onClickOnTips?: () => void;
  onClickOnComments?: () => void;
}> = ({
  likes,
  visiblePhotoLikes,
  comments,
  isPaidContent,
  contest,
  purchaseCount,
  tips,
  onClickOnLikes,
  onClickOnComments,
  onClickOnTips,
}) => {
  const feedPaidContentFlag = useFeature(FeatureName.feedPaidContent);
  const { t } = useTranslation(['feed']);
  const numberFormatter = useNumberShortFormatter();
  const contestPlacement = contest?.userPlace;
  const likesToShow = contest ? visiblePhotoLikes : likes;

  return (
    <HStack justifyContent={'space-between'} spacing={30} w={'full'}>
      {likesToShow && (
        <MetricCountButton
          icon={ThumbUpAlt}
          onClick={onClickOnLikes}
          count={numberFormatter(likesToShow ?? 0)}
        />
      )}
      {!contest && (
        <MetricCountButton
          icon={ModeComment}
          onClick={onClickOnComments}
          count={comments}
        />
      )}
      {!feedPaidContentFlag || !isPaidContent ? null : (
        <MetricCountButton icon={Payments} count={purchaseCount} />
      )}
      {/* Keep the last item with tips right aligned and the ones before left aligned */}
      <Spacer />
      {!contest ? (
        <MetricCountButton
          icon={Tipping}
          onClick={onClickOnTips}
          count={t('feed:button.Tips', { tips: numberFormatter(tips) })}
        />
      ) : contestPlacement ? (
        <MetricCountButton
          icon={EmojiEvents}
          count={t('feed:label.PlatzXX', {
            place: numberFormatter(contestPlacement),
          })}
        />
      ) : null}
    </HStack>
  );
};

export const FeedPostStatisticsBanner: React.FC<
  { status: PostStatusEnum } & StackProps
> = ({ status, ...rest }) => {
  const { t } = useTranslation(['feed']);
  const statisticsFlag = useFeature(FeatureName.feedStatistics);

  return (
    <>
      {statisticsFlag && status === PostStatusEnum.Ok ? (
        <HStack
          as={'button'}
          role={'button'}
          justifyContent={'space-between'}
          h={'10'}
          w={'full'}
          pl={'4'}
          pr={'2'}
          fontWeight={'medium'}
          bg={'steel'}
          color={'gray.900'}
          {...rest}
        >
          <Text
            as={'span'}
            overflow={'hidden'}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {t('feed:button.BeitragsstatistikAnsehen')}
          </Text>
          <Icon as={ChevronRight} boxSize={'icon.md'} />
        </HStack>
      ) : (
        <></>
      )}
    </>
  );
};

export const FeedPostRejectedBanner: React.FC<
  { rejectionReason: string } & StackProps
> = ({ rejectionReason, ...rest }) => {
  return (
    <HStack
      as={'button'}
      role={'button'}
      justifyContent={'start'}
      h={'10'}
      w={'full'}
      pl={'4'}
      pr={'2'}
      fontWeight={'medium'}
      bg={'error.100'}
      color={'error.500'}
      {...rest}
    >
      <ErrorIcon />
      <Text
        as={'span'}
        overflow={'hidden'}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        flex={1}
        textAlign={'left'}
      >
        {rejectionReason}
      </Text>
      <Icon justifySelf={'end'} as={ChevronRight} boxSize={'icon.md'} />
    </HStack>
  );
};

/**
 * Can be used for footer and text post content
 */
export const FeedPostHeadline: React.FC<HeadingProps> = (props) => {
  return (
    <Heading
      as={'h3'}
      lineHeight={'8'}
      fontSize={'xl'}
      color={'coldGray.900'}
      {...props}
    />
  );
};

/**
 * Can be used for footer, text post content and regular header text
 */
export const FeedPostText: React.FC<TextProps> = (props) => (
  <Text color={'coldGray.900'} {...props} />
);

interface Props extends BoxProps {
  children: string;
}

export const ExpandableText = forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => {
    const { t } = useTranslation(['feed']);
    const isMobileViewport = useBreakpointValue({ base: true, lg: false });
    const [isClicked, setIsClicked] = React.useState(false);
    const handleToggle = () => {
      setIsClicked((prev) => !prev);
    };

    const isTextClamped = children.length > 75;

    const truncatedText = (str: string) => {
      if (isTextClamped && !isClicked && isMobileViewport) {
        return str.substring(0, 75) + '...';
      }
      return str;
    };

    return (
      <Box {...rest}>
        <Text color={'coldGray.900'}>
          {truncatedText(children)}
          <chakra.span
            color={'coldGray.900'}
            display={
              isTextClamped && isMobileViewport ? 'inline-block' : 'none'
            }
            onClick={handleToggle}
            pl={'4px'}
            fontWeight={'700'}
            cursor={'pointer'}
          >
            {isClicked
              ? t('feed:text.WenigerAnzeigen')
              : t('feed:text.MehrAnzeigen')}
          </chakra.span>
        </Text>
      </Box>
    );
  }
);

export const FeedPostFooterContent: React.FC<
  { headline?: string | null; text?: string | null } & StackProps
> = ({ headline, text, ...props }) => {
  return (
    <VStack alignItems={'start'} w={'full'} {...props}>
      {headline && <FeedPostHeadline children={headline} />}
      {text && <ExpandableText children={text} />}
    </VStack>
  );
};

interface FeedPostProps extends ContainerProps {
  postId?: string;
  status?: PostStatusEnum;
  currentTusUpload?: TusUploadEntry | undefined;
  rejected?: Maybe<boolean>;
  rejectionReason?: Maybe<string>;
  deRejection?: Maybe<string>;
  enRejection?: Maybe<string>;
  isFeatured?: boolean;
  isScheduled?: boolean;
  onDeletePost: () => void;
  contest?: ContestFragment | null;
}
export const FeedPost: React.FC<FeedPostProps & TempFeedPostPhotoPostProp> = ({
  status,
  currentTusUpload,
  rejectionReason,
  isFeatured,
  isScheduled,
  postId,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  isPhotoPost,
  multiPhotoPost,
  contest,
  visiblePhotoId,
  ...containerProps
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { isHighlighted, highlightColor, labelElement } = useFeedPostWrapper({
    status,
    currentTusUpload,
    rejectionReason: rejectionReason ?? undefined,
    isFeatured,
    isScheduled,
    onDeletePost,
    onDeleteImage,
    onReplaceImage,
    isPhotoPost,
    multiPhotoPost,
    contest,
    postId,
    visiblePhotoId,
  });

  const { lastCreatedPostId } = useCreateFeedPostContext();
  const [wasScrolledTo, setWasScrolledTo] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!wasScrolledTo && lastCreatedPostId === postId) {
      setTimeout(
        () => {
          ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        },
        // Gives the animation some delay, so the page can finsih its initial loading
        // and especially the status section has time to take its required height
        // and the PostCreateModal has time to disapear
        500
      );
      setWasScrolledTo(true);
    }
  }, [postId, lastCreatedPostId, wasScrolledTo, setWasScrolledTo]);

  return (
    <VStack
      maxW={'768px'}
      width={'full'}
      alignItems={'stretch'}
      spacing={1}
      px={{ base: isHighlighted ? '2' : 0, md: 0 }}
      sx={{
        // give the element some space when scrolled to,
        // so it is not covered by the header navbar
        scrollMargin: '58px',
      }}
      ref={ref}
    >
      {!labelElement ? (
        <></>
      ) : (
        <Box
          // pad sides in case it is not already padded by container
          px={{ base: !isHighlighted ? '2' : 0, md: 0 }}
          children={labelElement}
        />
      )}
      <Box
        overflow={'hidden'}
        borderRadius={isHighlighted ? '8px' : 'none'}
        borderWidth={{ base: isHighlighted ? '1px' : '0', md: '1px' }}
        borderColor={
          isHighlighted
            ? highlightColor
            : { base: 'transparent', md: 'gray.100' }
        }
        {...containerProps}
      />
    </VStack>
  );
};
