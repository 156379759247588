import {
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { DocumentPickerV3ForWizard } from '../../../../components/shared/DocumentPickerV3/DocumentPickerV3';
import { RequiredUploadErrorMessage } from '../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { FormControlHeaderStack } from '../../../../components/shared/FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { PromotingOptionList } from '../../../../components/shared/FormikFormElements/PromotingSelect';
import { HelpLink } from '../../../../components/shared/HelpLink/HelpLink';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { RadioGroupHookControl } from '../../../../components/shared/HookFormForms/RadioGroupHookCrontrol/RadioGroupHookControl';
import { MediaUploadCropperPortal } from '../../../../components/shared/MediaUpload/MediaUpload';
import { PrivacyHint } from '../../../../components/shared/PrivacyHint/PrivacyHint';
import { TipBanner } from '../../../../components/shared/cards/TipBanner/TipBanner';
import {
  InputTourPayoutV1,
  ProfileFieldsEnum,
  TaxCountryEnum,
  useGetWizardPayoutTaxTinExtrasQuery,
  useWizardPayoutBankDataQuery,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { MediaInputProvider } from '../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../provider/MediaPropertiesProvider';
import {
  MediaContext,
  MediaProvider,
} from '../../../../provider/MediaProvider';
import { externalRoutes } from '../../../../routes/routesConfig';
import Logger from '../../../../utils/Logger';
import { uploadDocument } from '../../../../utils/media';
import { createStringValidationSchema } from '../../../../utils/validation';
import { WizardInstruction } from '../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../components/styled';
import { useWizardPayout } from '../WizardPayoutContext';

const fieldName = {
  isTaxable: ProfileFieldsEnum.PayoutIsTaxable,
  taxNumber: ProfileFieldsEnum.PayoutTaxId,
  tin: 'payoutTaxTin',
  tinCountry: 'payoutTaxTinCountry',
  document: ProfileFieldsEnum.PayoutTaxDocument,
} as const;

interface FormValues {
  [fieldName.isTaxable]: 'yes' | 'no';
  [fieldName.taxNumber]: string;
  [fieldName.tin]: string;
  [fieldName.tinCountry]: TaxCountryEnum | '';
  [fieldName.document]: number | null;
}

export const WizardPayoutTaxDetails: React.FC = () => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation([
    'general',
    'wizardPayout',
    'payout',
    'document',
  ]);
  const [pickedDocument, setPickedDocument] = useState<
    null | MediaContext['media']
  >();
  const uploadedDocIds = React.useRef(new Map());

  const wizardPayoutCtx = useWizardPayout();

  const { data: taxData, loading: isTaxDataLoading } =
    useGetWizardPayoutTaxTinExtrasQuery();
  const {
    data: payoutBankData,
    error: payoutBankDataLoadingError,
    loading: isPayoutDataLoading,
  } = useWizardPayoutBankDataQuery();

  const showTin = React.useMemo(() => {
    return taxData?.payment?.taxData?.showTin ?? false;
  }, [taxData]);

  const showDocument = taxData?.payment?.taxData?.isDocumentRequired ?? false;

  const initialValues = React.useMemo<FormValues>(() => {
    const fields = taxData?.payment?.taxData;
    return {
      [fieldName.isTaxable]: fields?.isTaxable?.value ? 'yes' : 'no',
      [fieldName.taxNumber]: fields?.taxNumber?.value ?? '',
      [fieldName.document]: fields?.document?.value?.docId ?? null,
      [fieldName.tin]: fields?.tin?.value ?? '',
      [fieldName.tinCountry]:
        ((fields?.tinCountry?.value ?? '') as TaxCountryEnum) || '',
    };
  }, [taxData]);

  const validationSchema = React.useMemo(() => {
    const fields = taxData?.payment?.taxData;

    return Yup.object().shape({
      [fieldName.taxNumber]: createStringValidationSchema({
        ...fields?.taxNumber,
      }).when(fieldName.isTaxable, (isTaxable, prevSchema) => {
        return isTaxable !== 'yes' ? Yup.string() : prevSchema;
      }),
      [fieldName.tin]: createStringValidationSchema({
        ...fields?.tin,
      }).when(fieldName.isTaxable, (isTaxableValue, prevSchema) => {
        if (showTin || isTaxableValue !== 'yes') {
          return prevSchema;
        }
        return Yup.string();
      }),
      [fieldName.tinCountry]: createStringValidationSchema({}).when(
        fieldName.isTaxable,
        (isTaxableValue, prevSchema) => {
          if (showTin || isTaxableValue !== 'yes') {
            return prevSchema;
          }
          return Yup.string();
        }
      ),
    });
  }, [taxData, showTin]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const currentIsTaxableValue =
    useWatch({ name: fieldName.isTaxable, control: hookForm.control }) ===
    'yes';

  // Updates the account data set in the previous step.

  useEffect(() => {
    if (payoutBankDataLoadingError) {
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
        status: 'error',
      });
      wizardPayoutCtx.wizardCloseCallback();
    }
  }, [payoutBankDataLoadingError, t, wizardPayoutCtx]);

  const { isAccountFromGermany, currentField, currentFieldError } =
    wizardPayoutCtx;
  const isUploadRequired =
    payoutBankData?.account?.isAccountFromGermany ?? isAccountFromGermany;
  const uploadUrl = currentField?.payoutTaxDocument?.upload?.url;

  React.useEffect(() => {
    if (Object.keys(currentFieldError).length > 0) {
      Object.entries(currentFieldError).forEach(([key, entry]) => {
        hookForm.setError(key as any, {
          type: 'manual',
          message: entry as any,
        });
      });
    }
  }, [currentFieldError, hookForm]);

  const setDocumentHandler = useCallback(
    (document: null | MediaContext['media']) => {
      setPickedDocument(document);

      if (!document) {
        hookForm.setError(fieldName.document, {
          type: 'manual',
          message: (<RequiredUploadErrorMessage />) as any,
        });
      } else {
        hookForm.clearErrors(fieldName.document);
      }
    },
    [setPickedDocument, hookForm]
  );

  const { wizardNextStepCallback } = wizardPayoutCtx;

  const onInvalidSubmit: SubmitErrorHandler<FormValues> = (errors) => {
    Logger.error(errors);
  };

  const issueErrorToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      status: 'error',
    });
  }, [t]);

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const isTaxableValue = data[fieldName.isTaxable] === 'yes';

        const tinPart: Partial<InputTourPayoutV1> =
          !showTin && isTaxableValue
            ? {}
            : {
                [fieldName.tin]: data[fieldName.tin],
                [fieldName.tinCountry]: data[
                  fieldName.tinCountry
                ] as TaxCountryEnum,
              };

        if (
          isTaxableValue &&
          (!pickedDocument || !pickedDocument?.blob) &&
          isUploadRequired
        ) {
          hookForm.setError(fieldName.document, {
            type: 'manual',
            message: (<RequiredUploadErrorMessage />) as any,
          });
          issueErrorToast();
          return;
        }

        const docId: {
          [fieldName.document]?: number;
          payoutTaxDocumentId?: number;
        } = {};

        if (pickedDocument && pickedDocument.blob) {
          const previousUploadId = uploadedDocIds.current.get(pickedDocument);
          if (previousUploadId) {
            // keep setting to prevent errors payoutTaxDocument
            docId[fieldName.document] = previousUploadId;
            // let payoutTaxDocumentId do the actual job
            docId.payoutTaxDocumentId = previousUploadId;
          } else {
            if (!uploadUrl) {
              issueErrorToast();
              return;
            }
            try {
              const result = await uploadDocument(
                uploadUrl,
                pickedDocument.blob
              );
              if (result.id === null) {
                issueErrorToast();
                return;
              }

              // keep setting to prevent errors payoutTaxDocument
              docId.payoutTaxDocument = result.id;
              // let payoutTaxDocumentId do the actual job
              docId.payoutTaxDocumentId = result.id;
              uploadedDocIds.current.set(
                pickedDocument,
                docId.payoutTaxDocument
              );
            } catch {
              issueErrorToast();
            }
          }
        }

        wizardNextStepCallback(
          isTaxableValue
            ? {
                [fieldName.isTaxable]: true,
                [fieldName.taxNumber]: data[fieldName.taxNumber],
                ...tinPart,
                ...docId,
              }
            : {
                [fieldName.isTaxable]: false,
                ...tinPart,
              }
        );
      } catch (error) {
        Logger.error(error);
        issueErrorToast();
      }
    },
    [
      wizardNextStepCallback,
      hookForm,
      showTin,
      issueErrorToast,
      uploadUrl,
      isUploadRequired,
      pickedDocument,
    ]
  );

  const validValues = taxData?.payment?.taxData?.tinCountry?.validValues;
  const quickpickValues =
    taxData?.payment?.taxData?.tinCountry?.quickpickValues;
  const optionListProps = useMemo(
    () => ({
      options: validValues ?? [],
      groupLabel: t('general:optgroup.Lander'),
      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
      placeholder: t('payout:placeholder.AusstellungslandAuswahlen'),
      promotedValues: (quickpickValues ?? []).map(({ value }) => value),
    }),
    [t, validValues, quickpickValues]
  );

  const isMissingDocument =
    !pickedDocument && !!currentIsTaxableValue && !!showDocument;

  // Whether the continue button is disabled
  const disableContinueButton =
    isMissingDocument ||
    !hookForm.formState.isValid ||
    hookForm.formState.isSubmitting;

  // console.log({
  //   values: hookForm.getValues(),
  //   hasPickedDocument: !!pickedDocument,
  // });

  const isReadOnly = false;

  // The content to be displayed depending on the selection of the radio button
  return (
    <WizardParentModalStepLayout
      isContentLoading={isTaxDataLoading || isPayoutDataLoading}
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t('wizardPayout:heading.BistDuUmsatzsteuerpflichtig')}
        >
          <FormProvider {...hookForm}>
            <form
              onSubmit={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}
            >
              <VStack spacing={6} divider={<Divider />} alignItems={'stretch'}>
                <VStack spacing={6} alignItems={'stretch'} pt={'4'}>
                  <RadioGroupHookControl
                    name={fieldName.isTaxable}
                    options={[
                      {
                        isReadOnly,
                        children: t('payout:radio.IchBinKleinunternehmer'),
                        value: 'no',
                      },
                      {
                        isReadOnly,
                        children: t('payout:radio.IchBinUmsatzsteuerpflichtig'),
                        value: 'yes',
                      },
                    ]}
                  />
                  {currentIsTaxableValue ? (
                    <HelpLink
                      alignSelf={'start'}
                      href={externalRoutes.helpCenterArticleAllesZurUmsatzsteuerpflicht(
                        lang
                      )}
                    >
                      {t('payout:text.AllesZurUmsatzsteuerpflicht')}
                    </HelpLink>
                  ) : (
                    <HelpLink
                      alignSelf={'start'}
                      href={externalRoutes.helpCenterArticleWannBinIchKleinunternehmer(
                        lang
                      )}
                    >
                      {t('payout:text.WannBinIchKleinunternehmer')}
                    </HelpLink>
                  )}
                </VStack>
                {!!currentIsTaxableValue && (
                  <VStack spacing={6} alignItems={'stretch'}>
                    <ClearableInputControl
                      label={t('payout:label.SteuernummerUmsatzsteuerID')}
                      placeholder={t(
                        'payout:placeholder.SteuernummerUstIdNrEingeben'
                      )}
                      name={fieldName.taxNumber}
                      isReadOnly={isReadOnly}
                    />
                    {showDocument && (
                      <FormControl>
                        <FormControlHeaderStack>
                          <FormLabel
                            pointerEvents={'none'}
                            cursor={'default'}
                            size={'sm'}
                          >
                            {t('payout:label.Umsatzsteuernachweis')}
                          </FormLabel>
                          <FormHelperText>
                            {t(
                              'payout:text.OffiziellesDokumentDesSteuerberatersFinanzamtsDasUmsatzsteuerpflichtXX'
                            )}
                          </FormHelperText>
                        </FormControlHeaderStack>
                        <MediaPropertiesProvider
                          targetDimensions={{ width: 1000, height: 1400 }}
                          determineAspectRatioByInput={true}
                          named={t('document:named.Umsatzsteuernachweis')}
                        >
                          <MediaProvider
                            initialReplacement={pickedDocument ?? undefined}
                            // initialSrc={pickedDocument?.src ?? undefined}
                            // initialFilename={
                            //   pickedDocument?.filename ?? undefined
                            // }
                            onReplacement={(replacementMedia) => {
                              setDocumentHandler(replacementMedia);
                            }}
                          >
                            <MediaInputProvider accept={'DEFAULT_FOR_DOCUMENT'}>
                              <DocumentPickerV3ForWizard
                                named={t('document:named.Umsatzsteuernachweis')}
                                errorMessage={
                                  hookForm.formState.errors[fieldName.document]
                                    ?.message ?? undefined
                                }
                              />
                              <MediaUploadCropperPortal />
                            </MediaInputProvider>
                          </MediaProvider>
                        </MediaPropertiesProvider>
                      </FormControl>
                    )}
                  </VStack>
                )}
                {(!currentIsTaxableValue || showTin) && (
                  <VStack spacing={6} alignItems={'stretch'}>
                    <TipBanner
                      text={t(
                        'payout:text.NurBeiNaturlichenPersonenMitWohnsitzInDerEU'
                      )}
                    />
                    <ClearableInputControl
                      label={t('payout:label.SteuerIdentifikationsnummerTIN')}
                      text={t(
                        'payout:text.ZuFindenObenRechtsAufDerLohnsteuerbescheinigungOderDemEinkommensteueXX'
                      )}
                      placeholder={t('payout:placeholder.SteuerIDEingeben')}
                      name={fieldName.tin}
                      isReadOnly={isReadOnly}
                    />
                    <EnumSelectFieldHookForm
                      label={t(
                        'payout:label.InWelchemLandWurdeDieSteuerIdentifikationsnummerTINAusgestellt'
                      )}
                      name={fieldName.tinCountry}
                      isReadOnly={isReadOnly}
                    >
                      <PromotingOptionList {...optionListProps} />
                    </EnumSelectFieldHookForm>
                  </VStack>
                )}
              </VStack>
            </form>
          </FormProvider>
        </WizardInstruction>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            isLoading={hookForm.formState.isSubmitting}
            isDisabled={disableContinueButton}
            children={t('wizardPayout:button.Weiter')}
            onClick={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}
          />
        </ButtonStack>
      }
    />
  );
};
