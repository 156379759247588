import { Add } from '@campoint/odi-ui-icons';
import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { UPLOADER_EVENTS, useUploady } from '@rpldy/uploady';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MissingRequirementsImage from '../assets/images/shared/missing-requirements-blocker.svg';
import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../components/Layout/ResponsiveModal';
import { UrlFragment } from '../components/Layout/UrlFragmentScrollToTarget';
import { BlockerCard } from '../components/shared/BlockerCard/BlockerCard';
import { FeedPostCreateModal } from '../components/shared/FeedPostCreate/FeedPostCreate';
import {
  FskEnum,
  ModelPostTypesEnum,
  useCreateFeedPostMutation,
  useMarkPostAsUploadedMutation,
} from '../generated/feed';
import { VideoTypeEnum } from '../generated/graphql';
import { useCallbackRegister } from '../hooks/useCallbackRegister';
import { createContext } from '../hooks/useContext';
import { FeedConfig, useFeedConfig } from '../hooks/useFeedConfig';
import { routes } from '../routes/routesConfig';
import Logger from '../utils/Logger';
import { UploadJobEntityType, useUploadManager } from './UploadManagerProvider';

type CreatePostActionDefaultProps = {
  fsk: FskEnum;
  pinned: boolean;
  price?: number | null;
  title?: string;
  text?: string;
  thumbnail?: Blob | null;
  publicationScheduledFor?: string | null;
  tippingGoal?: number | null;
};

type ShowCreateFeedPostModalNavButtonProps = {
  canUseFeed: boolean;
  isFeedCard?: boolean;
};

type CreatePostAction =
  | ({
      type: ModelPostTypesEnum.Text;
    } & CreatePostActionDefaultProps)
  | ({
      type: ModelPostTypesEnum.Photos;
      photos: Blob[];
    } & CreatePostActionDefaultProps)
  | ({
      type: ModelPostTypesEnum.Clip;
      clip: Blob;
      clipName: string;
    } & CreatePostActionDefaultProps);

export type CreateFeedPostModalContext = {
  readonly isCreateFeedPostMutationLoading: boolean;
  readonly isOpen: boolean;
  readonly lastCreatedPostId?: Maybe<string>;
  readonly action: {
    readonly openModal: () => void;
    readonly closeModal: () => void;
    readonly createPost: (props: CreatePostAction) => Promise<void>;
    readonly registerOnReset: (
      fn: (postType?: ModelPostTypesEnum) => void
    ) => void;
  };
  readonly feedConfig: FeedConfig;
};

export const [, useCreateFeedPostContext, feedPostCreateModalContext] =
  createContext<CreateFeedPostModalContext>({
    name: 'CreateFeedPostModalProvider',
    errorMessage:
      'useCreateFeedPostContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const FeedPostCreateModalProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { t } = useTranslation(['feed']);
  const [lastCreatedPostId, setLastCreatedPostId] =
    React.useState<Maybe<string>>(null);

  const uploady = useUploady();
  const uploadManagerCtx = useUploadManager();

  const [createFeedPost, { loading: isCreateFeedPostMutationLoading }] =
    useCreateFeedPostMutation({
      fetchPolicy: 'no-cache',
      onError: (error) => Logger.error(error),
    });

  const { data: feedConfig } = useFeedConfig();

  const navigateToFeed = React.useCallback(() => {
    history.push(routes.feed.path);
  }, [history]);

  const issueCreationErrorToast = React.useCallback(() => {
    issueChakraToast({
      status: 'error',
      description: t('feed:toast.BeimBeitragErstellenIstEinFehlerAufgetreten'),
    });
  }, [t]);

  const [registerOnReset, notifyOnResetSubscribers] =
    useCallbackRegister<(postType?: ModelPostTypesEnum) => void>();

  const [markAsUploaded] = useMarkPostAsUploadedMutation({
    onError: (error) => {
      Logger.error(error);
    },
  });

  const createPost = React.useCallback(
    async (props: CreatePostAction) => {
      try {
        const result = await createFeedPost({
          variables: {
            postType: props.type,
            title: props.title,
            text: props.text,
            fsk: props.fsk,
            pinned: props.pinned,
            price: props.price,
            tippingGoal: props.tippingGoal,
            photosCount:
              props.type === ModelPostTypesEnum.Photos
                ? props.photos?.length
                : null,
            publicationScheduledFor: props.publicationScheduledFor ?? null,
          },
        });
        const data = result.data?.vxmodels.createPost;
        const postId = data?.post?.id;
        const albumId = data?.post?.umaId;

        setLastCreatedPostId(postId ?? null);

        if (!data || !data.success) {
          issueCreationErrorToast();
          return;
        }

        switch (props.type) {
          case ModelPostTypesEnum.Photos:
            uploadManagerCtx.action.queueUploadJob({
              entity: {
                type: UploadJobEntityType.POST,
                id: data.post?.id!,
              },
              payload: {
                thumbnail: props.thumbnail,
                mediaFiles:
                  data.uploadUrls?.map((uploadUrl, index) => ({
                    uploadUrl,
                    blob: props.photos.at(index)!,
                  })) ?? [],
              },
            });
            break;
          case ModelPostTypesEnum.Clip:
            uploady.setOptions({
              params: { type: VideoTypeEnum.VideoFeed, albumId },
            });
            const file = new File([props.clip], props.clipName, {
              type: props.clip.type,
            });
            uploady.on(UPLOADER_EVENTS.ITEM_FINISH, async () => {
              if (!postId) return;
              await markAsUploaded({
                variables: {
                  postId: postId,
                },
              });
            });
            uploady.upload(file);
            break;
        }

        onClose();
        navigateToFeed();

        // on safari the feed page seems to take too long to load
        // so we need to wait a bit before refreshing the feed
        setTimeout(() => {
          notifyOnResetSubscribers(props.type);
        }, 200);
      } catch (error) {
        issueCreationErrorToast();
      }
    },
    [
      createFeedPost,
      onClose,
      navigateToFeed,
      notifyOnResetSubscribers,
      issueCreationErrorToast,
      uploadManagerCtx.action,
      uploady,
      markAsUploaded,
    ]
  );

  const action = React.useMemo<CreateFeedPostModalContext['action']>(
    () => ({
      openModal: onOpen,
      closeModal: onClose,
      createPost,
      registerOnReset,
    }),
    [onClose, onOpen, createPost, registerOnReset]
  );

  const context = React.useMemo<CreateFeedPostModalContext>(
    () => ({
      isCreateFeedPostMutationLoading,
      isOpen,
      lastCreatedPostId,
      action,
      feedConfig,
    }),
    [
      action,
      isOpen,
      isCreateFeedPostMutationLoading,
      lastCreatedPostId,
      feedConfig,
    ]
  );

  return (
    <feedPostCreateModalContext.Provider value={context}>
      {children}
      <FeedPostCreateModal />
    </feedPostCreateModalContext.Provider>
  );
};

export const ShowCreateFeedPostModalNavButton: React.FC<
  ButtonProps & ShowCreateFeedPostModalNavButtonProps
> = ({ canUseFeed, isFeedCard = false, ...buttonProps }) => {
  const { t } = useTranslation(['feed']);
  const { action } = useCreateFeedPostContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();

  const navigateToHome = () => {
    history.push({
      pathname: routes.home.path,
      hash: UrlFragment.Tasks,
    });
  };

  const isHomeRoute =
    window.location.pathname.toString() === routes.home.path.toString();

  const restrictedModal = (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} isCentered>
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ResponsiveModalCloseButton />
        <BlockerCard
          image={MissingRequirementsImage}
          heading={
            <>
              <span children={t('feed:heading.BaldVerfugbar')} />
              <br />
              <span children={'VXFeed'} />
            </>
          }
          text={t(
            'feed:text.SchliesseZuerstAlleSchritteAbSodassDuDirektDeinenErstenBeitragImVXFeXX'
          )}
          primaryButtonText={t('feed:button.SchritteAbschliessen')}
          onClickPrimary={isHomeRoute ? onClose : navigateToHome}
        />
      </ResponsiveModalContent>
    </ResponsiveModal>
  );

  return !isFeedCard ? (
    <>
      <IconButton
        aria-label={t('feed:button.PostErstellen')}
        icon={
          <Icon
            as={Add}
            boxSize={buttonProps.size === 'lg' ? `icon.lg` : `icon.md`}
            color={'whiteAlpha.900'}
          />
        }
        borderRadius={'full'}
        border={'2px'}
        borderColor={'whiteAlpha.900'}
        bgGradient="linear(to-r, primary.from, primary.to)"
        variant={'unstyled'}
        onClick={canUseFeed ? action.openModal : onOpen}
        {...buttonProps}
      />
      {restrictedModal}
    </>
  ) : (
    <>
      <Button
        aria-label={t('feed:button.PostErstellen')}
        leftIcon={<Icon as={Add} />}
        onClick={canUseFeed ? action.openModal : onOpen}
      >
        {t('feed:button.BeitragErstellen')}
      </Button>
    </>
  );
};
