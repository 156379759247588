import {
  Box,
  Button,
  HStack,
  Portal,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const firstCommitVersion = '2021.05.1';
const initReloadRequired = false;

type AppVersionContext = {
  appVersion: string;
  reloadRequired: boolean;
};

const initialValue: AppVersionContext = {
  appVersion: firstCommitVersion,
  reloadRequired: initReloadRequired,
};

export const appVersionContext =
  React.createContext<AppVersionContext>(initialValue);
export const AppVersionProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation(['appVersionBanner']);
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });
  const [appVersion, setAppVersion] =
    React.useState<string>(firstCommitVersion);
  const [reloadRequired, setReloadRequired] =
    React.useState<boolean>(initReloadRequired);

  React.useEffect(() => {
    const checkForNewVersion = async () => {
      const url = window.location.origin;
      let response = await fetch(url + '/login', {
        method: 'get',
        mode: 'cors',
      });
      if (response.ok) {
        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const newVersion = doc
          .querySelector('meta[name="app-ui-version"]')
          ?.getAttribute('content');
        if (newVersion && newVersion !== version) {
          setAppVersion(newVersion);
          setReloadRequired(true);
        }
      }
    };

    const version =
      document
        ?.querySelector('meta[name="app-ui-version"]')
        ?.getAttribute('content') ?? firstCommitVersion;
    setAppVersion(version);

    const intervalId = setInterval(async () => {
      if (!reloadRequired) {
        await checkForNewVersion();
      } else {
        clearInterval(intervalId);
      }
    }, 600_000);

    return () => clearInterval(intervalId);
  }, [reloadRequired]);

  return (
    <appVersionContext.Provider value={{ appVersion, reloadRequired }}>
      {children}
      {reloadRequired && (
        <Portal>
          <Box
            position={'fixed'}
            p={4}
            left={0}
            right={0}
            top={isMobileViewport ? '84px' : 'unset'}
            bottom={isMobileViewport ? 'unset' : '35px'}
            margin={'auto'}
            display={'flex'}
            justifyContent={'center'}
            width={'max-content'}
            height={'max-content'}
            alignItems={'center'}
            bgColor={'white'}
            borderRadius={'8px'}
            boxShadow={
              '0px 20px 25px -5px #0000001A, 0px 10px 10px -5px #0000000A'
            }
            borderWidth={'1px'}
            borderColor={'primary.200'}
          >
            <HStack gap={4}>
              <Text lineHeight={'20px'} fontWeight={'normal'} fontSize={'14px'}>
                {t('appVersionBanner:text.NeueVersionVerfugbar')}
              </Text>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t('appVersionBanner:button.Aktualisieren')}
              </Button>
            </HStack>
          </Box>
        </Portal>
      )}
    </appVersionContext.Provider>
  );
};

export const useAppVersion = () => {
  const context = React.useContext(appVersionContext);
  return context;
};
