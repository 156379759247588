import { useDisclosure } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';

import {
  FeedPostComments,
  FeedPostFooter,
  FeedPostFooterContent,
  FeedPostFooterMetrics,
  FeedPostRejectedBanner,
  FeedPostStatisticsBanner,
} from '../../../../../components/shared/FeedPost/FeedPost';
import {
  FeedPhotoMediaPictureFragment,
  PostStatusEnum,
  PostTypeEnum,
  TippingGoalFragment,
} from '../../../../../generated/feed';
import { ContestFragment } from '../../../../../generated/graphql';
import { useFeedPostStatistics } from '../../../../../provider/FeedPostStatisticsProvider';
import { usePostCommenting } from '../../../../../provider/PostCommentingProvider';
import { FeedPostRejectedModal } from '../../modal/FeedPostRejectedModal';
import { FeedPostTippingGoalSlot } from './FeedPostTippingGoalSlot/FeedPostTippingGoalSlot';
import { TempFeedPostPhotoPostProp, TempFeedPostScheduleProp } from './types';

export const PostFooter: React.FC<
  {
    postId: string;
    status: PostStatusEnum;
    brokenOrErrorStatus?: boolean;
    likes: number;
    comments: number;
    tips: number;
    purchaseCount: number;
    price?: Maybe<number>;
    headline?: string | null;
    text?: string | null;
    type?: PostTypeEnum;
    tippingGoal: Maybe<TippingGoalFragment>;
    contest?: ContestFragment | null;
    onClickOnLikes: (() => void) | undefined;
    onClickOnTips: (() => void) | undefined;
    onDeletePost: () => void;
    borderRadius?: string;
    rejectionReason?: string | null | undefined;
    visiblePhoto?: FeedPhotoMediaPictureFragment | undefined;
  } & TempFeedPostScheduleProp &
    TempFeedPostPhotoPostProp
> = ({
  isPhotoPost = false,
  multiPhotoPost = false,
  postId,
  visiblePhoto,
  status,
  type,
  brokenOrErrorStatus,
  likes,
  comments,
  tips,
  purchaseCount,
  headline,
  text,
  isPublished,
  price,
  publicationScheduledFor,
  borderRadius,
  tippingGoal,
  onClickOnLikes,
  onClickOnTips,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  rejectionReason,
  contest,
}) => {
  const feedPostStatisticsCtx = useFeedPostStatistics();
  const commentingCtx = usePostCommenting();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isScheduled = status === PostStatusEnum.Scheduled;
  const isContest = type === PostTypeEnum.CpPhotoContest;
  const isExtraFeaturesVisible =
    status !== PostStatusEnum.Broken && !brokenOrErrorStatus && !isScheduled;
  const isCommentable = isExtraFeaturesVisible;
  const isMetricVisible = isExtraFeaturesVisible;
  //we do this temporarily to hide the statistics for the contest posts
  const isStatisticBannerVisible = isExtraFeaturesVisible && !contest;
  const isRejectedBannerVisible = !!rejectionReason && !isScheduled;

  return (
    <>
      {!isStatisticBannerVisible ? (
        <></>
      ) : (
        <FeedPostStatisticsBanner
          status={status}
          onClick={() => feedPostStatisticsCtx.action.showModal(postId)}
        />
      )}
      {!isRejectedBannerVisible ? (
        <></>
      ) : (
        <FeedPostRejectedBanner
          rejectionReason={rejectionReason}
          onClick={() => {
            if (!isContest) {
              onOpen();
            }
          }}
        />
      )}
      <FeedPostTippingGoalSlot tippingGoal={tippingGoal} />
      <FeedPostFooter borderRadius={borderRadius} w={'full'}>
        {(headline || text) && (
          <FeedPostFooterContent
            headline={headline}
            text={text}
            borderRadius={borderRadius}
          />
        )}
        {!isMetricVisible ? (
          <></>
        ) : (
          <FeedPostFooterMetrics
            likes={likes}
            comments={comments}
            tips={tips}
            isPaidContent={!!price}
            purchaseCount={purchaseCount}
            onClickOnLikes={onClickOnLikes}
            onClickOnComments={() => commentingCtx.action.showModal(postId)}
            onClickOnTips={onClickOnTips}
            contest={contest}
            visiblePhotoLikes={visiblePhoto?.likes}
          />
        )}
        {!isCommentable ? (
          <></>
        ) : (
          <FeedPostComments
            commentCount={comments}
            onClickOnCommentButton={() =>
              commentingCtx.action.showModal(postId)
            }
          />
        )}
        {isRejectedBannerVisible && (
          <FeedPostRejectedModal
            isOpen={isOpen}
            onClose={onClose}
            rejectionReason={rejectionReason}
            onDeletePost={onDeletePost}
            isPhotoPost={isPhotoPost}
            multiPhotoPost={multiPhotoPost}
            onDeleteImage={onDeleteImage}
            onReplaceImage={onReplaceImage}
            visiblePhotoId={visiblePhoto?.id}
            postId={postId}
          />
        )}
      </FeedPostFooter>
    </>
  );
};
