import { ApolloError } from '@apollo/client';
import React from 'react';

import { FeatureName, useFeature } from '../../../flags';
import {
  PhotoalbumFragment,
  PhotoalbumStatusEnum,
  PhotoalbumTypeEnum,
  useGetVideoLibraryPhotoUploadListQuery,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { useAuth } from '../../AuthProvider';
import { useDeleteMedia } from '../useDeleteMedia';

export type VideoLibraryPhotoContext = {
  photos: PhotoalbumFragment[];
  initialLoading: boolean;
  photosLoading: boolean;
  photosError: ApolloError | undefined;
  loadMore: () => void;
  onDelete: (albumId: number) => void;
};

export const [, useVideoLibraryPhotoContext, videoLibraryPhotoContext] =
  createContext<VideoLibraryPhotoContext>({
    name: 'VideoLibraryPhotoProvider',
    errorMessage:
      'useVideoLibraryPhotoContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const VideoLibraryPhotoProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const authCtx = useAuth();
  const [photos, setPhotos] = React.useState<PhotoalbumFragment[]>([]);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const fetchMorePhotoCount = 20;

  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  const skip = !authCtx.isAuthenticated || !videoLibraryFeatureFlag;

  const {
    data,
    fetchMore,
    loading: photosLoading,
    error: photosError,
  } = useGetVideoLibraryPhotoUploadListQuery({
    variables: {
      count: fetchMorePhotoCount,
      cursor: '',
      mediaTypes: [PhotoalbumTypeEnum.Free, PhotoalbumTypeEnum.Shop],
      status: [PhotoalbumStatusEnum.Released, PhotoalbumStatusEnum.Rejected],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const loadMore = React.useCallback(() => {
    const hasNextPage =
      data?.media?.photoalbums.photoalbumList.pageInfo?.hasNextPage;

    if (hasNextPage && !photosError) {
      const lastPost =
        data?.media?.photoalbums?.photoalbumList?.edges[
          data?.media?.photoalbums?.photoalbumList?.edges?.length - 1
        ];
      fetchMore({
        variables: {
          count: fetchMorePhotoCount,
          cursor: lastPost?.cursor,
        },
      });
    }
  }, [
    data?.media?.photoalbums.photoalbumList.pageInfo?.hasNextPage,
    data?.media?.photoalbums.photoalbumList?.edges,
    photosError,
    fetchMore,
  ]);

  const onDeleteSuccess = (albumId: number) => {
    setPhotos((prevPhotos: PhotoalbumFragment[]) =>
      prevPhotos.filter((item) => item.albumId !== albumId)
    );
  };

  const onDelete = useDeleteMedia({
    onSuccess: onDeleteSuccess,
    mediaType: 'photo',
  });

  React.useEffect(() => {
    const fetchedPhotos = (data?.media?.photoalbums?.photoalbumList?.edges?.map(
      (el) => el?.node
    ) ?? []) as PhotoalbumFragment[];
    if (fetchedPhotos) {
      setPhotos(fetchedPhotos);
    }
  }, [data?.media?.photoalbums?.photoalbumList?.edges, setPhotos]);

  React.useEffect(() => {
    if (!photosLoading) {
      setInitialLoading(false);
    }
  }, [photosLoading]);

  const context: VideoLibraryPhotoContext = React.useMemo(() => {
    return {
      photos,
      initialLoading,
      photosLoading,
      photosError,
      loadMore,
      onDelete,
    };
  }, [initialLoading, loadMore, onDelete, photos, photosError, photosLoading]);

  return (
    <videoLibraryPhotoContext.Provider value={context}>
      {children}
    </videoLibraryPhotoContext.Provider>
  );
};
