import { Box, Flex, Portal } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import { ScrollElementToTopOnMount } from '../../../../components/Layout/ScrollElementToTopOnMount';
import LoadingPage from '../../../LoadingPage/LoadingPage';
import { useWizardModal } from '../WizardParentModalLayout/WizardParentModalLayout';

export const wizardParentModalStepLayoutTestingIds = {
  contentSection: 'wizard-step-layout-content-section',
  hintSection: 'wizard-step-layout-hint-section',
  footerSection: 'wizard-step-layout-footer-section',
};

type WizardParentModalStepLayoutProps = {
  /**
   * The main content of the step
   */
  contentSection: ReactElement;
  /**
   * The footer content of the step. In most cases the buttons. If all three properties are set, then the footer is displayed at the bottom of the screen
   */
  footerSection?: ReactElement;
  /**
   * The hint card of the step. If all three properties are set, then these hints are always displayed vertically centered
   */
  hintSection?: ReactElement;
  /**
   * Whether the loading screen should be displayed
   */
  isContentLoading?: boolean;
  children?: React.ReactNode;
};

export const WizardParentModalStepLayout: React.FC<
  WizardParentModalStepLayoutProps
> = ({
  contentSection,
  footerSection,
  hintSection,
  isContentLoading = false,
}) => {
  const wizardModalCtx = useWizardModal();

  if (isContentLoading) {
    return <LoadingPage />;
  }

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'space-between'}
      minHeight={'full'}
      w={'full'}
      data-testid={wizardParentModalStepLayoutTestingIds.contentSection}
    >
      <ScrollElementToTopOnMount />
      {contentSection}
      {hintSection && (
        <Box
          mt={4}
          data-testid={wizardParentModalStepLayoutTestingIds.hintSection}
        >
          {hintSection}
        </Box>
      )}
      {footerSection && (
        <Portal containerRef={wizardModalCtx.footerRef}>{footerSection}</Portal>
      )}
    </Flex>
  );
};
