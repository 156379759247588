import { Close } from '@campoint/odi-ui-icons';
import {
  InputRightElement as ChakraInputRightElement,
  Icon,
  IconButton,
  InputElementProps,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';

import { useClearableInputContext } from './use-clearable-input';

const ClearableElement = () => {
  const { onClearInput } = useClearableInputContext();
  return (
    <IconButton
      me={2}
      size="sm"
      aria-label="clear"
      variant="ghost"
      color="onSurface.mediumEmphasis"
      colorScheme="gray"
      icon={<Icon as={Close} boxSize="icon.md" />}
      onClick={onClearInput}
      data-testid="clearable-icon"
    />
  );
};

export const ClearableInputClearElement = forwardRef<InputElementProps, 'div'>(
  (props, ref) => {
    const { showRightAddonChildren, showClearableAddon } =
      useClearableInputContext();
    const { children } = props;

    return (
      // @ts-ignore
      <ChakraInputRightElement ref={ref} {...props}>
        {showClearableAddon && <ClearableElement />}
        {showRightAddonChildren && children}
      </ChakraInputRightElement>
    );
  }
);
