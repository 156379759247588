import { UploaderEnhancer } from '@rpldy/uploader';
import { Batch, BatchItem, UPLOADER_EVENTS } from '@rpldy/uploady';
import { actions } from '@storybook/addon-actions';

/**
 * Adds Storybook "action" logging for certain events
 */
export const actionLogEnhancer: UploaderEnhancer = (uploader) => {
  const events = actions(
    'ITEM_START',
    'ITEM_ABORT',
    'ITEM_FINISH',
    'BATCH_ADD',
    'BATCH_FINISH'
  );

  uploader.on(UPLOADER_EVENTS.BATCH_ADD, (batch: Batch) => {
    events.BATCH_ADD(batch.id, 'added');
  });

  uploader.on(UPLOADER_EVENTS.ITEM_START, (item: BatchItem) => {
    events.ITEM_START(item.id, item.file ? item.file.name : item.url);
  });

  uploader.on(UPLOADER_EVENTS.ITEM_ABORT, (item: BatchItem) => {
    events.ITEM_ABORT(item.id, item.file ? item.file.name : item.url);
  });

  uploader.on(UPLOADER_EVENTS.ITEM_FINISH, (item: BatchItem) => {
    events.ITEM_FINISH(item.id, item.file ? item.file.name : item.url);
  });

  uploader.on(UPLOADER_EVENTS.BATCH_FINISH, (batch: Batch) => {
    events.BATCH_FINISH(batch.id, 'finished');
  });

  return uploader;
};
