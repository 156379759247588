import { Section, SectionBody } from '@campoint/odi-ui';
import * as icon from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  Divider,
  HStack,
  Heading,
  HeadingProps,
  Icon,
  IconProps,
  Image,
  ImageProps,
  ListItemProps,
  Progress,
  StackProps,
  Text,
  VStack,
  chakra,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import textPostPreviewImageTall from '../../../../assets/images/feed/TextPostPreviewImageTall.svg';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { ZStack } from '../../../../components/Layout/ZStack';
import {
  FeedNodeFragment,
  FeedPostFragment,
  PostStatusEnum,
  PostTypeEnum,
  useGetFeedNonOkPostsQuery,
} from '../../../../generated/feed';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { useAuth } from '../../../../provider/AuthProvider';
import {
  UploadJobEntityType,
  useUploadManager,
  useUploadManagerJob,
} from '../../../../provider/UploadManagerProvider';
import { FeedPostBrokenModal } from '../../components/modal/FeedPostBrokenModal';
import { FeedPostRejectedModal } from '../../components/modal/FeedPostRejectedModal';

const FeedPostRefHeading: React.FC<HeadingProps> = (props) => (
  <Heading
    as="h3"
    size={'none'}
    textStyle={'labelSm'}
    noOfLines={1}
    {...props}
  />
);
const FeedPostRefContent: React.FC<StackProps> = (props) => (
  <VStack spacing={'0'} alignItems={'start'} {...props} />
);
const FeedPostRefBox: React.FC<StackProps> = (props) => (
  <HStack
    px={0}
    py={2}
    spacing={4}
    alignItems={'center'}
    role={'group'}
    {...props}
  />
);
const FeedPostRefMediaPreview: React.FC<ImageProps> = (props) => (
  <Image
    fit={'cover'}
    borderRadius={'base'}
    bgColor={'steel'}
    h={'53px'}
    w={'40px'}
    alt={''}
    {...props}
  />
);

const CorneredIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon boxSize={'20px'} color={'white'} ref={ref} {...props} />
));

const PostRefImage: React.FC<
  BoxProps & { src?: string; type?: PostTypeEnum; isInvalid?: boolean }
> = ({ src, isInvalid, type, ...props }) => {
  const cornerIcon = React.useMemo(() => {
    if (!src) {
      return null;
    }
    switch (type) {
      case PostTypeEnum.Photos:
        return icon.PhotoLibrary;
      case PostTypeEnum.FpPhotoGallery:
        return icon.PhotoLibrary;
      case PostTypeEnum.FpHotClip:
        return icon.Movie;
      case PostTypeEnum.Clip:
        return icon.Movie;
      default:
        return null;
    }
  }, [src, type]);

  return (
    <ZStack
      bg={'coldGray.900'}
      borderRadius={'base'}
      borderWidth={'1px'}
      borderColor={!isInvalid ? 'surface' : 'error.500'}
      overflow={'hidden'}
      {...props}
    >
      <FeedPostRefMediaPreview src={src ?? textPostPreviewImageTall} />
      {!cornerIcon ? null : (
        <Box p={0.25} alignSelf={'end'}>
          <CorneredIcon as={cornerIcon} position={'relative'} />
        </Box>
      )}
    </ZStack>
  );
};

export const FeedPostStatusTranscoding: React.FC<{
  post: FeedPostFragment;
}> = ({ post }) => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostRefBox>
      <PostRefImage type={post.type} />
      <FeedPostRefContent flexGrow={1} spacing={1}>
        <FeedPostRefHeading>
          {t('feed:heading.BeitragWirdVorbereitet')}
        </FeedPostRefHeading>
        <Progress isIndeterminate w={'full'} size={'sm'} />
      </FeedPostRefContent>
    </FeedPostRefBox>
  );
};

export const FeedPostStatusUploading: React.FC<{
  post: FeedPostFragment;
}> = ({ post }) => {
  const { t } = useTranslation(['feed']);
  const uploadingJob = useUploadManagerJob(UploadJobEntityType.POST, post.id);

  const src = React.useMemo(() => {
    const blob = uploadingJob?.payload?.thumbnail;

    if (!blob) {
      return undefined;
    }
    return URL.createObjectURL(blob);
  }, [uploadingJob]);

  const value = uploadingJob?.progress ?? 0;
  const isIndeterminate =
    !value || (value >= 100 && uploadingJob?.success === null);

  return (
    <FeedPostRefBox>
      <PostRefImage type={post.type} src={src} />
      <FeedPostRefContent flexGrow={1} spacing={1}>
        <FeedPostRefHeading>
          {t('feed:heading.BeitragWirdHochgeladen')}
        </FeedPostRefHeading>
        <Progress
          colorScheme={'primary'}
          value={value}
          isIndeterminate={isIndeterminate}
          w={'full'}
          size={'sm'}
        />
      </FeedPostRefContent>
    </FeedPostRefBox>
  );
};

const ChevronRightIcon: React.FC<IconProps> = (props) => (
  <Icon as={icon.ChevronRight} boxSize={'icon.md'} {...props} />
);

export const FeedPostStatusBroken: React.FC<{
  post: FeedPostFragment;
  rejectionReason?: string | null | undefined;
  unguardedDeleteClickHandler: (postId: string) => void;
}> = ({ post, rejectionReason, unguardedDeleteClickHandler }) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const uploadingJob = useUploadManagerJob(UploadJobEntityType.POST, post.id);

  const src = React.useMemo(() => {
    const blob = uploadingJob?.payload?.thumbnail;

    if (!blob) {
      return undefined;
    }
    return URL.createObjectURL(blob);
  }, [uploadingJob]);

  return (
    <FeedPostRefBox>
      <PostRefImage
        isInvalid
        type={post.type}
        src={src}
        h={'53px'}
        w={'40px'}
      />
      <FeedPostRefContent flexGrow={1}>
        <HStack
          role={'alert'}
          color={'error.500'}
          cursor={'pointer'}
          onClick={onOpen}
        >
          <Icon as={icon.Error} />
          <Text fontSize={'sm'}>{t('feed:heading.UploadFehlgeschlagen')}</Text>
          <ChevronRightIcon />
        </HStack>
        <FeedPostBrokenModal
          isOpen={isOpen}
          onClose={onClose}
          onDeletePost={() => unguardedDeleteClickHandler(post.id)}
          rejectionReason={rejectionReason}
        />
      </FeedPostRefContent>
    </FeedPostRefBox>
  );
};

export const FeedPostStatusRejected: React.FC<{
  post: FeedNodeFragment;
  unguardedDeleteClickHandler: (postId: string) => void;
}> = ({ post, unguardedDeleteClickHandler }) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiLang = useActiveApiLanguage();
  const rejectionReason = React.useMemo(() => {
    switch (apiLang) {
      case 'de':
        return post.deRejection;
      default:
        return post.enRejection;
    }
  }, [post, apiLang]);
  const src = React.useMemo(() => {
    switch (post.__typename) {
      case 'PhotosPost':
        return post?.photos?.[0]?.url;
      case 'ClipPost':
        return post?.clip?.poster?.url;

      default:
        return undefined;
    }
  }, [post]);
  return (
    <FeedPostRefBox>
      <PostRefImage
        isInvalid
        type={post.type}
        src={src}
        h={'53px'}
        w={'40px'}
      />
      <FeedPostRefContent flexGrow={1}>
        <HStack
          role={'alert'}
          color={'error.500'}
          cursor={'pointer'}
          onClick={onOpen}
        >
          <Icon as={icon.Error} />
          <Text fontSize={'sm'}>{t('feed:heading.BeitragWurdeAbgelehnt')}</Text>
          <ChevronRightIcon />
        </HStack>
        <FeedPostRejectedModal
          isPhotoPost={false}
          multiPhotoPost={false}
          isOpen={isOpen}
          onClose={onClose}
          rejectionReason={rejectionReason ?? undefined}
          onDeletePost={() => unguardedDeleteClickHandler(post.id)}
        />
      </FeedPostRefContent>
    </FeedPostRefBox>
  );
};

export const useFeedPostStatusList = () => {
  const authCtx = useAuth();
  const uploadManagerCtx = useUploadManager();
  const modelId = authCtx?.authUser?.userId ?? '';
  const { data, refetch, loading } = useGetFeedNonOkPostsQuery({
    variables: {
      modelId,
      count: 20,
    },
  });

  React.useEffect(
    () =>
      uploadManagerCtx.action.registerOnJobFinished((jobHandle) => {
        if (jobHandle.entity.type === UploadJobEntityType.POST) {
          refetch().then();
        }
      }),
    [uploadManagerCtx.action, refetch]
  );

  const entries = React.useMemo(() => {
    return data?.feed?.nonOkPosts?.edges ?? [];
  }, [data]);

  return {
    entries,
    loading,
    refetch,
  };
};

const FeedPostStatesList = forwardRef<StackProps, 'ol'>((props, ref) => {
  const { t } = useTranslation(['feed']);
  return (
    <VStack
      as={'ol'}
      listStyleType={'none'}
      alignItems={'stretch'}
      w={'full'}
      divider={<Divider />}
      aria-label={t('feed:list.Beitragszustande')}
      ref={ref}
      {...props}
    />
  );
});

type FeedPostStatesListItemProps = ListItemProps & {
  post: FeedNodeFragment;
  unguardedDeleteClickHandler: (postId: string) => void;
};
const FeedPostStatesListItem = forwardRef<FeedPostStatesListItemProps, 'li'>(
  ({ post, unguardedDeleteClickHandler, ...props }, ref) => {
    const view = React.useMemo(() => {
      switch (post.status) {
        case PostStatusEnum.Broken:
          return (
            <FeedPostStatusBroken
              post={post}
              unguardedDeleteClickHandler={unguardedDeleteClickHandler}
            />
          );
        case PostStatusEnum.Rejected:
          return (
            <FeedPostStatusRejected
              post={post}
              unguardedDeleteClickHandler={unguardedDeleteClickHandler}
            />
          );
        default:
          return null;
      }
    }, [post, unguardedDeleteClickHandler]);

    return (
      <chakra.li ref={ref} {...props}>
        {view}
      </chakra.li>
    );
  }
);

export const FeedPostStatesSection: React.FC<{
  unguardedDeleteClickHandler: (postId: string) => void;
  registerRefresh: (fn: () => void) => void;
}> = ({ unguardedDeleteClickHandler, registerRefresh }) => {
  const { entries, loading, refetch } = useFeedPostStatusList();

  React.useEffect(
    () => registerRefresh(() => refetch().then()),
    [registerRefresh, refetch]
  );

  return loading || entries.length <= 0 ? (
    <></>
  ) : (
    <Section aria-label={''}>
      <SectionBody>
        <SectionCenterContainer>
          <FeedPostStatesList>
            {entries.map(({ node }) => (
              <FeedPostStatesListItem
                key={node.id}
                post={node}
                unguardedDeleteClickHandler={unguardedDeleteClickHandler}
              />
            ))}
          </FeedPostStatesList>
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};
