import { Heading, Img, Text, TextProps, VStack } from '@chakra-ui/react';
import React from 'react';

import {
  ChakraUniversalCard,
  UniversalCardSection,
  UniversalCardSectionWithBorder,
} from '../../../../components/shared/cards/UniversalCard/UniversalCard';
import { useAccountStatus } from '../../../../provider/AccountStatusProvider/AccountStatusProvider';
import { ShowCreateFeedPostModalNavButton } from '../../../../provider/FeedPostCreateModalProvider';

type CreateFeedPostSectionProps = {
  isBordered?: boolean;
  image?: string | null;
  imageAlt?: string;
  heading?: string | JSX.Element | null;
  text: string;
  primaryButtonText: string;
  textProps?: TextProps;
};

export const CreateFeedPostSection: React.FC<CreateFeedPostSectionProps> = ({
  isBordered,
  heading,
  image,
  imageAlt,
  text,
  primaryButtonText,
  textProps,
}) => {
  const { isAllowedToPostOnFeed } = useAccountStatus();
  const content = (
    <ChakraUniversalCard
      cardBody={
        <>
          {image && (
            <Img
              src={image}
              alt={imageAlt ?? ''}
              borderRadius="full"
              boxSize="48px"
            />
          )}
          <VStack mt="4" spacing="4">
            {heading && (
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {heading}
              </Heading>
            )}
            <Text
              size={{ base: 'sm', lg: 'md' }}
              textAlign={'center'}
              color={'gray.500'}
              p={0}
              whiteSpace={'pre-line'}
              {...textProps}
            >
              {text}
            </Text>
          </VStack>
        </>
      }
      cardFooter={
        <ShowCreateFeedPostModalNavButton
          variant={'solid'}
          canUseFeed={isAllowedToPostOnFeed}
          isFeedCard={true}
        />
      }
    />
  );
  return isBordered ? (
    <UniversalCardSectionWithBorder>{content}</UniversalCardSectionWithBorder>
  ) : (
    <UniversalCardSection>{content}</UniversalCardSection>
  );
};
