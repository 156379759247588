import {
  CheckCircle,
  Error as ErrorIcon,
  Group,
  Schedule,
} from '@campoint/odi-ui-icons';
import {
  ContainerProps,
  Icon,
  TextProps,
  Tooltip,
  chakra,
  forwardRef,
  useDisclosure,
  usePrevious,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { FILE_STATES } from '@rpldy/uploady';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PostStatusEnum } from '../../../generated/feed';
import { ContestFragment } from '../../../generated/graphql';
import { TusUploadEntry } from '../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/videoUploadEnhancer';
import { FeedPostBrokenModal } from '../components/modal/FeedPostBrokenModal';
import { FeedPostRejectedModal } from '../components/modal/FeedPostRejectedModal';
import { TempFeedPostPhotoPostProp } from '../components/post/component/types';

const FeedPostWrapperStatusLabel = forwardRef<
  TextProps & { icon: EmotionIcon },
  'span'
>((props, ref) => {
  const { icon, children, ...spanProps } = props;
  return (
    <chakra.span
      display={'inline-flex'}
      alignItems={'center'}
      textStyle={'caption'}
      fontWeight={400}
      ref={ref}
      {...spanProps}
    >
      <chakra.span aria-hidden paddingEnd={'1'}>
        {<Icon as={icon} boxSize={'6'} />}
      </chakra.span>
      <chakra.span>{children}</chakra.span>
    </chakra.span>
  );
});
export const FeaturedPostLabel = () => {
  const { t } = useTranslation(['feed']);
  return (
    <Tooltip
      label={t('feed:label.FeaturedPostDescription')}
      width={'full'}
      p={2}
      bg={{ base: 'white', md: 'steel' }}
      color={'black'}
      borderRadius={'8px'}
      placement="bottom-start"
      closeDelay={500}
    >
      <FeedPostWrapperStatusLabel
        icon={Group}
        children={t('feed:label.FeaturedPost')}
        color={'primary.500'}
      />
    </Tooltip>
  );
};
export const BrokenPostLabel: React.FC<{ onDeletePost: () => void }> = ({
  onDeletePost,
}) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <FeedPostWrapperStatusLabel
        icon={ErrorIcon}
        children={t('feed:text.Fehlgeschlagen')}
        color={'red.500'}
        cursor={'pointer'}
        onClick={onOpen}
      />
      <FeedPostBrokenModal
        isOpen={isOpen}
        onClose={onClose}
        onDeletePost={onDeletePost}
      />
    </>
  );
};
export const JustPublishedPostLabel = () => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostWrapperStatusLabel
      icon={CheckCircle}
      children={t('feed:text.LiveAufVISITX')}
      color={'lime.500'}
    />
  );
};

export const ScheduledPostLabel = () => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostWrapperStatusLabel
      icon={Schedule}
      children={t('feed:label.Eingeplant')}
      color={'primary.500'}
    />
  );
};
export const InVotingContestPostLabel = (contest: ContestFragment) => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostWrapperStatusLabel
      icon={Schedule}
      children={t('feed:label.VotingEndetAmDate', { date: contest.votingEnd })}
      color={'primary.500'}
    />
  );
};
export const RejectedPostLabel: React.FC<
  {
    onDeletePost: () => void;
    rejectionReason?: string;
    isContest: boolean;
  } & TempFeedPostPhotoPostProp
> = ({
  isPhotoPost = false,
  multiPhotoPost = false,
  isContest = false,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  rejectionReason,
  postId,
  visiblePhotoId,
}) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FeedPostWrapperStatusLabel
        icon={ErrorIcon}
        children={t('feed:text.Abgelehnt')}
        color={'red.500'}
        cursor={'pointer'}
        onClick={() => {
          if (!isContest) {
            onOpen();
          }
        }}
      />
      <FeedPostRejectedModal
        isPhotoPost={isPhotoPost}
        multiPhotoPost={multiPhotoPost}
        isOpen={isOpen}
        onClose={onClose}
        rejectionReason={rejectionReason}
        onDeletePost={onDeletePost}
        onDeleteImage={onDeleteImage}
        onReplaceImage={onReplaceImage}
        postId={postId}
        visiblePhotoId={visiblePhotoId}
      />
    </>
  );
};
export const useFeedPostWrapper = ({
  status,
  currentTusUpload,
  rejectionReason,
  isFeatured,
  isScheduled,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  isPhotoPost,
  multiPhotoPost,
  contest,
  visiblePhotoId,
  postId,
}: {
  status?: PostStatusEnum;
  currentTusUpload?: TusUploadEntry | undefined;
  rejectionReason?: string;
  isFeatured?: boolean;
  isScheduled?: boolean;
  onDeletePost: () => void;
  contest?: ContestFragment | null;
} & TempFeedPostPhotoPostProp) => {
  const currentTusUploadStatus = currentTusUpload?.status;
  const previusStatus = usePrevious(status);

  //for photo contests: check if the contest votingEnd is in the past
  const contestVotingEndInPast = React.useMemo(() => {
    if (!contest || !contest.votingEnd) return null;

    const todayDate = DateTime.local();
    const votingEndDate = DateTime.fromISO(contest.votingEnd);
    return todayDate > votingEndDate;
  }, [contest]);
  const contestInVoting = contest && !contestVotingEndInPast && !isScheduled;

  //a post status can be OK but the photo can be rejected
  const visiblePhotoRejected = rejectionReason && rejectionReason !== '';

  //we test the visible photo rejected status here in case of photo posts
  const statusOK = status === PostStatusEnum.Ok && !visiblePhotoRejected;

  //is either primary, error color or undefined
  const COLOR_ERROR = 'red.500';
  const COLOR_PRIMARY = 'primary.500';

  const highlightColor: ContainerProps['borderColor'] | undefined =
    React.useMemo(() => {
      if (statusOK && (isFeatured || isScheduled || contestInVoting)) {
        return COLOR_PRIMARY;
      }

      //especially for photo posts
      if (visiblePhotoRejected) {
        return COLOR_ERROR;
      }

      //especially for clip posts or uploading states
      let statusColor = undefined;

      switch (status) {
        case PostStatusEnum.Broken:
        case PostStatusEnum.Rejected:
          statusColor = COLOR_ERROR;
          break;
        default:
          break;
      }
      switch (currentTusUploadStatus) {
        case FILE_STATES.ERROR:
        case FILE_STATES.ABORTED:
        case FILE_STATES.CANCELLED:
          statusColor = COLOR_ERROR;
          break;
        default:
          break;
      }
      return statusColor;
    }, [
      statusOK,
      isFeatured,
      isScheduled,
      contestInVoting,
      visiblePhotoRejected,
      status,
      currentTusUploadStatus,
    ]);

  const isHighlighted = !!highlightColor;

  const labelElement = React.useMemo(() => {
    if (visiblePhotoRejected) {
      return (
        <RejectedPostLabel
          isPhotoPost={isPhotoPost}
          multiPhotoPost={multiPhotoPost}
          onDeletePost={onDeletePost}
          onDeleteImage={onDeleteImage}
          onReplaceImage={onReplaceImage}
          postId={postId}
          visiblePhotoId={visiblePhotoId}
          rejectionReason={rejectionReason ?? undefined}
          isContest={!!contest}
        />
      );
    }

    if (statusOK && previusStatus === PostStatusEnum.Transcoding) {
      return <JustPublishedPostLabel />;
    }

    if (isScheduled) {
      return <ScheduledPostLabel />;
    }

    if (statusOK && isFeatured) {
      return <FeaturedPostLabel />;
    }

    if (statusOK && contestInVoting) {
      return <InVotingContestPostLabel {...contest} />;
    }

    let statusElement = undefined;
    switch (status) {
      case PostStatusEnum.Broken:
        statusElement = <BrokenPostLabel onDeletePost={onDeletePost} />;
        break;
      case PostStatusEnum.Rejected:
        statusElement = (
          <RejectedPostLabel
            isPhotoPost={isPhotoPost}
            multiPhotoPost={multiPhotoPost}
            onDeletePost={onDeletePost}
            onDeleteImage={onDeleteImage}
            onReplaceImage={onReplaceImage}
            postId={postId}
            visiblePhotoId={visiblePhotoId}
            rejectionReason={rejectionReason ?? undefined}
            isContest={!!contest}
          />
        );
        break;
      default:
        break;
    }
    switch (currentTusUploadStatus) {
      case FILE_STATES.ERROR:
      case FILE_STATES.ABORTED:
      case FILE_STATES.CANCELLED:
        statusElement = (
          <RejectedPostLabel
            isPhotoPost={isPhotoPost}
            multiPhotoPost={multiPhotoPost}
            onDeletePost={onDeletePost}
            onDeleteImage={onDeleteImage}
            onReplaceImage={onReplaceImage}
            postId={postId}
            visiblePhotoId={visiblePhotoId}
            rejectionReason={currentTusUpload?.rejectionReason ?? undefined}
            isContest={!!contest}
          />
        );
        break;
      default:
        break;
    }
    return statusElement;
  }, [
    visiblePhotoRejected,
    statusOK,
    previusStatus,
    isFeatured,
    isScheduled,
    contestInVoting,
    status,
    currentTusUploadStatus,
    isPhotoPost,
    multiPhotoPost,
    onDeletePost,
    onDeleteImage,
    onReplaceImage,
    postId,
    visiblePhotoId,
    rejectionReason,
    contest,
    currentTusUpload?.rejectionReason,
  ]);

  return {
    highlightColor,
    isHighlighted,
    labelElement,
  };
};
