import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * state that also is reflected in the url as query parameter
 * which is also considered as initial state
 *
 * @example
 * // https://some.domain/path?tipId=tip-42
 * const [tipId, setTipId] = useQueryParamState('tipId');
 * // tipId === 'tip-42'
 * setTipId('tip-12')
 * // https://some.domain/path?tipId=tip-12
 */
export const useQueryParamState = (queryParamName: string) => {
  const location = useLocation();
  const history = useHistory();

  const queryParam = React.useMemo(
    () => new URLSearchParams(location.search).get(queryParamName),
    [location, queryParamName]
  );

  const [paramState, setParamState] = useState<string | null>(queryParam);

  useEffect(() => {
    setParamState((currentState) => {
      if (!!queryParam && queryParam !== currentState) {
        return queryParam;
      }
      return currentState;
    });
  }, [queryParam, setParamState]);

  const updateParamState = React.useCallback(
    (newState: string | null) => {
      setParamState(newState);
      const queryParams = new URLSearchParams(location.search);
      !!newState
        ? queryParams.set(queryParamName, newState)
        : queryParams.delete(queryParamName);
      history.replace({
        search: queryParams.toString(),
      });
    },
    [location.search, queryParamName, history]
  );

  return [paramState, updateParamState] as const;
};

export const useFeedPostQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const modalParam = React.useMemo(
    () => new URLSearchParams(location.search).get('modal'),
    [location.search]
  );
  const postIdParam = React.useMemo(
    () => new URLSearchParams(location.search).get('postId'),
    [location.search]
  );

  const [modal, setModal] = useState<string | null>();
  const [postId, setPostId] = useState<string | null>();

  useEffect(() => {
    setModal((curModal) => {
      if (!!modalParam && modalParam !== curModal) {
        return modalParam;
      }
      return curModal;
    });
    setPostId((curPostId) => {
      if (!!postIdParam && postIdParam !== curPostId) {
        return postIdParam;
      }
      return curPostId;
    });
  }, [modalParam, postIdParam]);

  const updateFeedPostParamState = React.useCallback(
    (newState: { modal: string | null; postId: string | null }) => {
      setModal(newState.modal);
      setPostId(newState.postId);
      const queryParams = new URLSearchParams(location.search);
      !!newState.modal
        ? queryParams.set('modal', newState.modal)
        : queryParams.delete('modal');
      !!newState.postId
        ? queryParams.set('postId', newState.postId)
        : queryParams.delete('postId');
      history.replace({
        search: queryParams.toString(),
      });
    },
    [location.search, history]
  );

  return [modal, postId, updateFeedPostParamState] as const;
};

export const useMediaModalQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const modalParam = React.useMemo(
    () => new URLSearchParams(location.search).get('modal'),
    [location.search]
  );
  const albumIdParams = React.useMemo(
    () => new URLSearchParams(location.search).get('albumId'),
    [location.search]
  );

  const [modal, setModal] = useState<string | null>();
  const [albumId, setAlbumId] = useState<string | null>();

  useEffect(() => {
    setModal((curModal) => {
      if (!!modalParam && modalParam !== curModal) {
        return modalParam;
      }
      return curModal;
    });
    setAlbumId((curAlbumId) => {
      if (!!albumIdParams && albumIdParams !== curAlbumId) {
        return albumIdParams;
      }
      return curAlbumId;
    });
  }, [modalParam, albumIdParams]);
  const updateFeedPostParamState = React.useCallback(
    (newState: { modal: string | null; albumId: string | null }) => {
      setModal(newState.modal);
      setAlbumId(newState.albumId);
      const queryParams = new URLSearchParams(location.search);
      !!newState.modal
        ? queryParams.set('modal', newState.modal)
        : queryParams.delete('modal');
      !!newState.albumId
        ? queryParams.set('albumId', newState.albumId)
        : queryParams.delete('albumId');
      history.replace({
        search: queryParams.toString(),
      });
    },
    [location.search, history]
  );
  return [modal, albumId, updateFeedPostParamState] as const;
};
