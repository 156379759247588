import { Add, Close, Error } from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
  VStack,
  Wrap,
  forwardRef,
  useDisclosure,
  useMergeRefs,
  useOutsideClick,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Maybe,
  MediaTag,
  useTagAutoCompleteLazyQuery,
} from '../../../../generated/graphql';
import { useDebounce } from '../../../../hooks/useDebounce';
import { FormControlHeaderStack } from '../FormControlHeaderStack/FormControlHeaderStack';

export type TagCloudControlProps = {
  name: string;
  label?: React.ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  inputProps?: InputProps;
};
const SelectedTag: React.FC<TagProps> = (props) => (
  <Tag variant="outline" size={'lg'} fontSize={'md'} as={'li'} {...props} />
);
const alphabetically = (a: string, b: string) => a.localeCompare(b);

export const TagCloudControl = forwardRef<TagCloudControlProps, 'input'>(
  (props, ref) => {
    const { name, label, isDisabled, isReadOnly, size, inputProps, ...rest } =
      props;

    const { t } = useTranslation(['videoEditModal']);
    const [tagInputValue, setTagInputValue] = React.useState<string>('');
    const inputRef = React.useRef<HTMLInputElement>(null);
    const mergedRef = useMergeRefs(ref, inputRef);
    const popoverContentRef = React.useRef<HTMLElement>(null);
    const popoverRef = React.useRef(null);
    const debouncedTagInputValue = useDebounce(tagInputValue ?? '', 100);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { setValue } = useFormContext();

    useOutsideClick({
      ref: popoverRef,
      handler: onClose,
    });

    const value: string[] = useWatch({ name });

    /**
     * Tag Autocomplete Functionality
     */

    const [tagsForAutoComplete, setTagsForAutoComplete] = React.useState<
      Maybe<MediaTag>[]
    >([]);

    const [fetchTagsForAutoComplete] = useTagAutoCompleteLazyQuery({
      onCompleted: (data) => {
        setTagsForAutoComplete(data?.media.tags ?? []);
      },
      onError: () => {
        setTagsForAutoComplete([]);
      },
    });

    const handleKeyDown:
      | React.KeyboardEventHandler<HTMLInputElement>
      | undefined = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleAddTag();
        return;
      }
    };

    const autoCompleteTagsVisible = React.useMemo(() => {
      return tagsForAutoComplete.length > 0;
    }, [tagsForAutoComplete.length]);

    React.useEffect(() => {
      if (debouncedTagInputValue.length > 2) {
        fetchTagsForAutoComplete({
          variables: {
            fragment: debouncedTagInputValue,
          },
        });
      } else {
        setTagsForAutoComplete([]);
      }
    }, [debouncedTagInputValue, fetchTagsForAutoComplete]);

    const validateTag = React.useCallback(
      (tag: string) => {
        if (tag.length < 1) {
          return null;
        }

        if (tag.length < 2) {
          return t('videoEditModal:inputs.error.Mindestens2Zeichen');
        }

        if (tag.length > 32) {
          return t('videoEditModal:inputs.error.Maximal32Zeichen');
        }

        const spaceCharCountInTag = tag.split(' ').length - 1;
        if (spaceCharCountInTag > 1) {
          return t('videoEditModal:inputs.error.Maximal2Worter');
        }

        //special characters of DE/SP allowed
        const specialCharsDE = 'ßÄÖÜäöü';
        const specialCharsSP = 'ÁÉÍÓÚÑáéíóúñ';
        const allowedCharsRegEx = `^[a-zA-Z0-9- ${specialCharsDE}${specialCharsSP}]+$`;
        const allowedChars = new RegExp(allowedCharsRegEx);
        if (!allowedChars.test(tag)) {
          return t('videoEditModal:inputs.error.KeineSonderzeichenErlaubt');
        }

        return null;
      },
      [t]
    );

    const error = React.useMemo(() => {
      return validateTag(tagInputValue);
    }, [tagInputValue, validateTag]);

    const onClearTagInput = React.useCallback(() => {
      setTagInputValue('');
      inputRef?.current?.focus();
    }, []);

    const handleAddTag = React.useCallback(
      (tag?: string) => {
        let inputValue: string = tagInputValue?.trim() ?? '';
        if (tag) inputValue = tag;

        if (!inputValue) {
          return;
        }

        validateTag(inputValue);

        if (error) return;

        if (
          value
            .map((val) => val.toLowerCase())
            .includes(inputValue.toLowerCase())
        ) {
          onClearTagInput();
          return;
        }

        setValue(name, [...value, inputValue].sort(alphabetically));
        onClearTagInput();
      },
      [
        error,
        name,
        onClearTagInput,
        setValue,
        tagInputValue,
        validateTag,
        value,
      ]
    );

    const emitRemoveOnChange = React.useCallback(
      (valueToRemove: string) => {
        setValue(
          name,
          value.filter((v) => v !== valueToRemove),
          { shouldTouch: true }
        );
      },
      [name, setValue, value]
    );

    const selectedElements = React.useMemo(() => {
      const currentValue = value ?? [];

      if (currentValue.length <= 0) {
        return null;
      }

      const selectedOptions = value ?? [];

      return (
        <>
          {selectedOptions.map((selectedOption) => (
            <SelectedTag key={selectedOption}>
              <TagLabel color={'gray.900'}>{selectedOption}</TagLabel>
              {!isReadOnly && (
                <TagCloseButton
                  isDisabled={isReadOnly}
                  color={'gray.900'}
                  onClick={() => emitRemoveOnChange(selectedOption)}
                />
              )}
            </SelectedTag>
          ))}
        </>
      );
    }, [value, isReadOnly, emitRemoveOnChange]);

    return (
      <FormControl
        name={name}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isInvalid={!!error}
        {...rest}
      >
        <FormControlHeaderStack>
          <FormLabel
            fontSize={'md'}
            fontWeight={'medium'}
            lineHeight={'5'}
            letterSpacing={'normal'}
            color={'coldGray.900'}
          >
            {label}
          </FormLabel>
        </FormControlHeaderStack>
        <Box ref={popoverRef} w={'full'}>
          <Popover
            isOpen={isOpen && autoCompleteTagsVisible}
            onOpen={onOpen}
            onClose={onClose}
            placement={'top-start'}
            initialFocusRef={inputRef}
            variant={'responsive'}
          >
            <PopoverTrigger>
              <InputGroup>
                <Input
                  ref={mergedRef}
                  value={tagInputValue}
                  placeholder={t('videoEditModal:inputs.text.TagEingeben')}
                  pointerEvents={isReadOnly ? 'none' : 'all'}
                  onChange={(ev) => {
                    const val = ev.target.value;
                    setTagInputValue(val);
                  }}
                  autoComplete={'none'}
                  onFocus={() => {
                    inputRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest',
                    });
                  }}
                  //keyPressed only tracks characters typed
                  onKeyDown={handleKeyDown}
                  {...inputProps}
                />
                {!isDisabled && !isReadOnly && !error && (
                  <InputRightElement>
                    <AddElement
                      onClick={(ev) => handleAddTag()}
                      hasValue={tagInputValue !== ''}
                    />
                  </InputRightElement>
                )}
                {!isDisabled && !isReadOnly && error && (
                  <InputRightElement>
                    <ClearableElement onClick={onClearTagInput} />
                  </InputRightElement>
                )}
              </InputGroup>
            </PopoverTrigger>
            <PopoverContent ref={popoverContentRef} w={'full'}>
              <VStack
                as={PopoverBody}
                cursor={'pointer'}
                p={0}
                divider={<Divider />}
                spacing={0}
                alignItems={'stretch'}
              >
                {tagsForAutoComplete.map((suggestion, index) => {
                  if (suggestion?.value) {
                    return (
                      <Box
                        key={index}
                        onClick={() => {
                          if (!suggestion.value) return;
                          handleAddTag(suggestion.value);
                        }}
                        px={4}
                        py={2.5}
                        textStyle={'bodyMd'}
                        children={suggestion.value}
                        _hover={{
                          backgroundColor: 'coldGray.50',
                        }}
                      />
                    );
                  }
                  return <></>;
                })}
              </VStack>
            </PopoverContent>
          </Popover>
        </Box>
        {error && (
          <HStack minH={5} mt={3}>
            <Icon as={Error} color={'error.500'} />
            <FormErrorMessage>{error}</FormErrorMessage>
          </HStack>
        )}
        {selectedElements && <Wrap mt={'4'}>{selectedElements}</Wrap>}
      </FormControl>
    );
  }
);

const AddElement = forwardRef<
  Partial<IconButtonProps & { hasValue: boolean }>,
  'button'
>((props, ref) => {
  const { hasValue, ...rest } = props;
  return (
    <IconButton
      ref={ref}
      me={2}
      size="sm"
      aria-label="add"
      variant="ghost"
      color={hasValue ? 'primary.500' : 'darkSteel'}
      icon={<Icon as={Add} boxSize="icon.md" />}
      _hover={{ backgroundColor: 'unset' }}
      {...rest}
    />
  );
});

const ClearableElement = forwardRef<Partial<IconButtonProps>, 'button'>(
  (props, ref) => (
    <IconButton
      ref={ref}
      me={2}
      size="sm"
      aria-label="clear"
      variant="ghost"
      color="onSurface.mediumEmphasis"
      colorScheme="gray"
      icon={<Icon as={Close} boxSize="icon.md" />}
      data-testid="clearable-icon"
      {...props}
    />
  )
);
