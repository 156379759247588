import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import {
  Button,
  ButtonGroup,
  Container,
  Heading,
  List,
  VStack,
} from '@chakra-ui/react';
import { Reorder } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import {
  StatisticEditEntryFragment,
  useGetAllStatisticsQuery,
  useUpdateStatisticsListMutation,
} from '../../generated/graphql';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import Logger from '../../utils/Logger';
import StatisticAddableListItem from './StatisticAddableListItem/StatisticAddableListItem';
import StatisticRemovableListItem from './StatisticRemovableListItem/StatisticRemovableListItem';

const StatisticEditPage: React.FC = () => {
  const history = useHistoryWithStorage();
  const { t } = useTranslation(['statistic', 'general']);
  const [selectedItems, setSelectedItems] = useState<
    StatisticEditEntryFragment[]
  >([]);
  const [availableItems, setAvailableItems] = useState<
    StatisticEditEntryFragment[]
  >([]);
  const [updateStatisticSelection] = useUpdateStatisticsListMutation({
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      });
    },
  });

  const { loading: isLoading } = useGetAllStatisticsQuery({
    onCompleted: (data) => {
      setSelectedItems(data.statistics.entries);
      setAvailableItems(data.statistics.availableEntries);
    },
  });

  const onBack = useCallback(async () => {
    const statisticList = selectedItems.map((item) => item.id);

    await updateStatisticSelection({
      variables: { statisticEntries: statisticList },
    });

    history.goBack();
  }, [selectedItems, updateStatisticSelection, history]);

  const { isAddingDisabled, isRemovingDisabled } = React.useMemo(() => {
    const count = selectedItems?.length ?? 0;
    return {
      isAddingDisabled: count >= 6,
      isRemovingDisabled: count <= 2,
    };
  }, [selectedItems]);
  const addItem = (item: StatisticEditEntryFragment) => {
    setAvailableItems(
      availableItems?.filter((availableItem) => availableItem !== item)
    );
    setSelectedItems([...selectedItems, item]);
  };

  const removeItem = (item: StatisticEditEntryFragment) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== item)
    );
    setAvailableItems([...availableItems, item]);
  };

  return (
    <FluentPageLayout isContentLoading={isLoading}>
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <SubHeaderTitle>
            {t('statistic:heading.StatistikenBearbeiten')}
          </SubHeaderTitle>
        </Container>
      </SubHeader>
      <VStack spacing={'7'} p={'4'} w={'full'} bg={'white'} my={'9'} pb={'9'}>
        <VStack spacing={'4'} w={'full'} alignItems={'flex-start'} maxW={'7xl'}>
          <Heading
            children={t('statistic:heading.AngezeigteStatistiken')}
            fontSize={'md'}
          />
          <Reorder.Group
            axis="y"
            layoutScroll
            values={selectedItems}
            onReorder={setSelectedItems}
            style={{
              width: '100%',
              listStyleType: 'none',
            }}
          >
            {selectedItems.map((item, index) => (
              <StatisticRemovableListItem
                key={item.id}
                value={item}
                displayName={item.label}
                firstItem={index === 0}
                lastItem={index === selectedItems.length - 1}
                onClick={() => removeItem(item)}
                disabled={isRemovingDisabled}
              />
            ))}
          </Reorder.Group>
        </VStack>
        <VStack spacing={'4'} w={'full'} alignItems={'flex-start'} maxW={'7xl'}>
          {availableItems.length > 0 && (
            <Heading
              children={t('statistic:heading.WeitereStatistiken')}
              fontSize={'md'}
            />
          )}
          <List p={0} w={'full'} data-testid="statisticEditPageMoreStatistics">
            {availableItems.map((item, index) => (
              <StatisticAddableListItem
                key={item.id}
                value={item}
                displayName={item.label}
                firstItem={index === 0}
                lastItem={index === availableItems.length - 1}
                onClick={() => addItem(item)}
                disabled={isAddingDisabled}
              />
            ))}
          </List>
        </VStack>
        <ButtonGroup>
          <>
            <Button onClick={history.goBack}>
              {t('general:button.Zuruck')}
            </Button>
            <Button onClick={onBack}>{t('general:done')}</Button>
          </>
        </ButtonGroup>
      </VStack>
    </FluentPageLayout>
  );
};

export default StatisticEditPage;
