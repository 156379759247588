import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { useFormattedAmount } from '../../../../utils/formattedAmount';

type PriceSliderProps = {
  name: string;
  min: number;
  max: number;
  step: number;
  isDisabled?: boolean;
};

export const PriceSlider = ({
  name,
  min,
  max,
  step,
  isDisabled,
}: PriceSliderProps) => {
  const [field, , { setValue }] = useField(name);
  const formattedAmount = useFormattedAmount(field.value);
  const minSliderMark = useFormattedAmount(min);
  const maxSliderMark = useFormattedAmount(max);
  const thumbSize = 30;

  function handleChange(value: number) {
    setValue(value);
  }

  const thumbMargin = (): string => {
    const range = (field.value - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        {...field}
        isDisabled={isDisabled}
        defaultValue={1}
        min={min}
        max={max}
        step={step}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={field.value}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'sm'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedAmount}
          </SliderMark>
        </Box>
        <SliderMark value={1} mt={'4'} fontSize={'sm'}>
          {minSliderMark}
        </SliderMark>
        <SliderMark value={20} mt={'4'} ml={'-12'} fontSize={'sm'}>
          {maxSliderMark}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};
