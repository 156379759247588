import { merge } from 'lodash';
import { DateTime } from 'luxon';
import { graphql, rest } from 'msw';

import {
  GetFeedPostStatisticsProviderQuery,
  GetFeedPostsQuery,
  GetFeedTotalCountQuery,
} from './generated/feed';
import {
  AccountStatusProviderQuery,
  DocumentTypeEnum,
  FinancesBillingCompanyEnum,
  FinancesBillingFragment,
  FinancesBillingStatusEnum,
  FinancesBillingTypeEnum,
  GetBillingsListQuery,
  GetFinanceServiceQuery,
  GetInvoicesListQuery,
  GetPayoutBillingsSectionQuery,
  GetPayoutWithdrawalsSectionQuery,
  GetUploadInfoForFieldQuery,
  MediaPictureStatusEnum,
  ModelProfileProviderQuery,
} from './generated/graphql';
import { mediaUploadErrorCodes } from './utils/media';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const profilePictureStatusHandler = [
  graphql.query('ModelProfileProvider', async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const data = (await originalResponse.json()).data;
    const mappedData = merge<
      ModelProfileProviderQuery,
      DeepPartial<ModelProfileProviderQuery>
    >(data, {
      profile: {
        mediaCollection: {
          fields: {
            modelPictureFSK16Avatar: {
              __typename: 'ProfileFieldTypePicture',
              value: {
                status: MediaPictureStatusEnum.Rejected,
                rejectReason: 'Kein Gesicht zu sehen',
              },
            },
          },
        },
      },
    });
    return res(ctx.data(mappedData));
  }),
];

export const postSalesHandler = [
  graphql.query('GetFeedPostStatisticsProvider', async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const data = (await originalResponse.json()).data;

    const mappedData = merge<
      GetFeedPostStatisticsProviderQuery,
      DeepPartial<GetFeedPostStatisticsProviderQuery>
    >(data, {
      postSales: {
        totalCount: 2,
        sales: [
          {
            salesAt: new Date().toISOString(),
            user: {
              id: 'qwertz',
              name: 'MockManfred',
              avatar: null,
              __typename: 'User',
            },
          },
          {
            salesAt: new Date().toISOString(),
            user: {
              id: 'asdf',
              name: 'TestThomas',
              avatar: null,
              __typename: 'User',
            },
          },
        ],
      },
    });
    return res(ctx.data(mappedData));
  }),

  graphql.query('GetFeedPosts', async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const data: GetFeedPostsQuery = (await originalResponse.json()).data;
    const mappedData = merge<GetFeedPostsQuery, DeepPartial<GetFeedPostsQuery>>(
      data,
      {
        feed: {
          posts: {
            edges: data.feed.posts.edges.map((edge) => {
              if (!edge.node?.price) {
                return edge;
              }
              return merge(edge, {
                node: {
                  purchases: {
                    count: 2,
                  },
                },
              });
            }),
          },
        },
      }
    );
    return res(ctx.data(mappedData));
  }),
];

export const tippingGoalHandler = [
  graphql.query('GetFeedPostStatisticsProvider', async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const data = (await originalResponse.json()).data;

    const mappedData = merge<
      GetFeedPostStatisticsProviderQuery,
      DeepPartial<GetFeedPostStatisticsProviderQuery>
    >(data, {
      post: {
        tips: {
          amount: 50,
        },
        tippingGoal: {
          achieved: false,
          goal: 150,
          tipsSum: 50,
          progressPercent: 33,
          __typename: 'TippingGoal',
        },
      },
      postTips: {
        totalCount: 1,
        tips: [
          {
            tippedAt: new Date().toISOString(),
            amount: 8,
            user: {
              id: 'foo',
              name: 'bingo',
              avatar: null,
              __typename: 'User',
            },
          },
        ],
      },
    });
    return res(ctx.data(mappedData));
  }),
  graphql.query('GetFeedPosts', async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const data: GetFeedPostsQuery = (await originalResponse.json()).data;
    const mappedData = merge<GetFeedPostsQuery, DeepPartial<GetFeedPostsQuery>>(
      data,
      {
        feed: {
          posts: {
            edges: data.feed.posts.edges.map((edge) => {
              if (!!edge.node?.price) {
                return edge;
              }
              return merge(edge, {
                node: {
                  tippingGoal: {
                    achieved: false,
                    goal: 150,
                    tipsSum: 50,
                    progressPercent: 30,
                    __typename: 'TippingGoal',
                  },
                },
              });
            }),
          },
        },
      }
    );
    return res(ctx.data(mappedData));
  }),
];

let mockDocumentId = 100_000;
export const createActorUploadHandlers = [
  graphql.query('getDocumentUploadUrl', (req, res, ctx) => {
    const url = new URL('http://localhost:3000/upload-document');
    url.searchParams.set('userId', req.variables.userId);
    url.searchParams.set('documentType', req.variables.documentType);
    url.searchParams.set('side', req.variables.side);

    return res(
      ctx.data({
        documents: {
          uploadUrl: url.href,
          __typename: 'Documents',
        },
      })
    );
  }),
  rest.post('/upload-document', (req, res, ctx) => {
    if (req.url.searchParams.get('documentType') === DocumentTypeEnum.IdShot) {
      return res(
        ctx.delay(1_000),
        ctx.json({
          success: false,
          message: 'Passt mir nicht',
        })
      );
    }

    return res(
      ctx.delay(1_000),
      ctx.json({
        success: true,
        documentId: mockDocumentId++,
      })
    );
  }),
];

const invoiceArray = [
  {
    amount: 5200.82,
    payoutDate: '2017-04-15',
    status: 'ok',
    message: 'TestOk',
    billingStartDate: '2022-04-01',
    billingEndDate: '2022-04-18',
  },
  {
    amount: 5200.82,
    payoutDate: '2022-04-15',
    status: 'ok',
    message: 'TestOk',
    billingStartDate: '2022-04-01',
    billingEndDate: '2022-04-18',
  },
  {
    amount: 1529502.33,
    payoutDate: '2023-05-15',
    status: 'waiting',
    message: 'TestWaiting',
    billingStartDate: '2023-05-01',
    billingEndDate: '2023-05-18',
  },
  {
    amount: 5.82,
    payoutDate: '2022-06-15',
    status: 'error',
    message: 'TESTERRORCOMEON',
    billingStartDate: '2022-06-01',
    billingEndDate: '2022-06-18',
  },
  {
    amount: 1337.82,
    payoutDate: '2022-07-15',
    status: 'waiting',
    message: 'TestWaiting',
    billingStartDate: '2022-07-01',
    billingEndDate: '2022-07-18',
  },
  {
    amount: 829.82,
    payoutDate: '2024-08-15',
    status: 'error',
    message: 'TestError',
    billingStartDate: '2022-08-01',
    billingEndDate: '2022-08-18',
  },
  {
    amount: 15.82,
    payoutDate: '2024-09-15',
    status: 'ok',
    message: 'TestOk',
    billingStartDate: '2022-09-01',
    billingEndDate: '2022-09-18',
  },
  {
    amount: 55.82,
    payoutDate: '2024-10-15',
    status: 'waiting',
    message: 'TestWaiting',
    billingStartDate: '2022-10-01',
    billingEndDate: '2022-10-18',
  },
];

const billingsArray: FinancesBillingFragment[] = [
  {
    amount: 5200.82,
    billNumber: '1',
    company: FinancesBillingCompanyEnum.Campoint,
    companyName: 'Campoint',
    id: 1,
    issueDate: '2017-04-15',
    type: FinancesBillingTypeEnum.Billing,
    billingStartDate: '2022-04-01',
    billingEndDate: '2022-04-18',
    status: FinancesBillingStatusEnum.Paid,
  },
  {
    amount: 5200.82,
    billNumber: '2',
    company: FinancesBillingCompanyEnum.Campoint,
    companyName: 'Campoint',
    id: 2,
    issueDate: '2017-04-20',
    type: FinancesBillingTypeEnum.Cancellation,
    billingStartDate: '2022-05-01',
    billingEndDate: '2022-05-18',
    status: FinancesBillingStatusEnum.Paid,
  },
];

export const getFinancePageInvoicesHandlers = [
  graphql.query('GetPayoutWithdrawalsSection', (req, res, ctx) => {
    const newestEntry = invoiceArray.at(-1);
    const oldestEntry = invoiceArray.at(0);

    return res(
      ctx.data({
        account: {
          signupDate: '2021-04-01',
        },
        finances: {
          invoices: {
            maxDate: newestEntry?.payoutDate ?? null,
            minDate: oldestEntry?.payoutDate ?? null,
          },
        },
      } as GetPayoutWithdrawalsSectionQuery)
    );
  }),
  graphql.query('GetInvoicesList', (req, res, ctx) => {
    const requestVariableYear = req.variables.year;

    const filteredInvoices = invoiceArray.filter((invoice) => {
      const parsedPayoutDate = DateTime.fromISO(invoice.payoutDate);

      return parsedPayoutDate.year === requestVariableYear;
    });

    return res(
      ctx.data({
        finances: {
          invoices: {
            count: 8,
            maxDate: '2024-12-01',
            minDate: '2020-04-01',
            invoices: filteredInvoices,
            __typename: 'FinancesInvoices',
          },
        },
      } as GetInvoicesListQuery)
    );
  }),
];

export const getFinancePageBillingsHandlers = [
  graphql.query('GetPayoutBillingsSection', (req, res, ctx) => {
    return res(
      ctx.data({
        account: {
          signupDate: '2021-04-01',
        },
      } as GetPayoutBillingsSectionQuery)
    );
  }),
  graphql.query('GetBillingsList', (req, res, ctx) => {
    return res(
      ctx.data({
        finances: {
          billings: billingsArray,
        },
      } as GetBillingsListQuery)
    );
  }),
];

export const handlers = [
  graphql.query('GetFinanceService', (req, res, ctx) => {
    return res(
      ctx.data({
        account: {
          isAccountFromGermany: true,
          isAccountFromEU: true,
          expirationDateOfLastAcceptedTaxDocument: '2023-09-24T06:37:12+00:00',
          originator: {
            userId: 11528422,
            financialDocuments: {
              isProofOfAddressRequired: true,
              proofOfAddress: {
                id: 450492,
                filename: '450492.jpg',
                url: 'https://vx.vxcdn.org/doc/H3bhgrdk3dEDy4wEMyS4XPShEN4gIek3NMaqNs4LiJg/64fb237c/22/84/11528422/docs/450492.jpg?h=01DD08C5B9936BFA36DF2F491D88FAF1',
                status: 'ok',
                rejectionReason: null,
                side: 'entireDocument',
                isImage: true,
                type: DocumentTypeEnum.AddressProof,
                typeName: 'ProofOfAddress',
                uploadDate: '2023-06-01T14:00:52+00:00',
                userId: 1,
                __typename: 'Document',
              },
              isTaxDocumentRequired: true,
              taxDocument: {
                id: 450492,
                filename: '450492.jpg',
                url: 'https://vx.vxcdn.org/doc/H3bhgrdk3dEDy4wEMyS4XPShEN4gIek3NMaqNs4LiJg/64fb237c/22/84/11528422/docs/450492.jpg?h=01DD08C5B9936BFA36DF2F491D88FAF1',
                status: 'ok',
                rejectionReason: null,
                side: 'entireDocument',
                isImage: true,
                type: DocumentTypeEnum.Vat,
                typeName: 'ProofOfAddress',
                uploadDate: '2023-06-01T14:00:52+00:00',
                userId: 1,
                __typename: 'Document',
              },
              __typename: 'DocumentsFinancialRealPerson',
            },
            __typename: 'Originator',
          },
          __typename: 'Account',
        },
        payoutWizardV1: {
          active: true,
          status: 'accepted',
          lastVerified: '2023-06-01T14:00:52+00:00',
          __typename: 'TourPayoutV1',
        },
        payoutWizardV2: {
          active: true,
          status: 'accepted',
          __typename: 'TourPayoutV2',
        },
        finances: {
          payoutStatus: {
            isFirstPayout: true,
            payoutDate: '2023-06-08',
            status: 'payoutAlreadyRequested',
            minimalPayableAmount: 50,
            payableAmount: 20,
            __typename: 'FinancesPayoutStatus',
          },
          __typename: 'Finances',
        },
        profile: {
          collection: {
            fields: {
              payoutIsTaxable: {
                __typename: 'ProfileFieldTypeBoolean',
                name: 'payoutIsTaxable',
                status: 'accepted',
                rejectReason: null,
                value: true,
              },
              payoutTaxId: {
                __typename: 'ProfileFieldTypeString',
                name: 'payoutTaxId',
                readOnly: false,
                status: 'accepted',
                rejectReason: null,
                disabled: false,
                value: 'DE123456789',
                minLength: 6,
                maxLength: 15,
              },
              payoutTaxDocument: {
                __typename: 'ProfileFieldTypeUploadDocument',
                name: 'payoutTaxDocument',
                status: 'accepted',
                rejectReason: null,
                value: {
                  docId: 450492,
                  __typename: 'UploadDocument',
                },
              },
              payoutTransactionType: {
                __typename: 'ProfileFieldTypeEnum',
                name: 'payoutTransactionType',
                readOnly: false,
                status: 'accepted',
                rejectReason: null,
                disabled: false,
                value: 'bank',
                quickpickValues: [],
                validValues: [
                  {
                    value: 'bank',
                    label: 'Payout via bank',
                    __typename: 'OptionEntry',
                  },
                  {
                    value: 'paxum',
                    label: 'Payout via Paxum',
                    __typename: 'OptionEntry',
                  },
                ],
              },
              payoutBankaccountOwner: {
                __typename: 'ProfileFieldTypeString',
                name: 'payoutBankaccountOwner',
                readOnly: false,
                status: 'rejected',
                rejectReason: 'bad',
                disabled: false,
                value: 'Martin',
                minLength: 1,
                maxLength: 70,
              },
              payoutIban: {
                __typename: 'ProfileFieldTypeString',
                name: 'payoutIban',
                readOnly: false,
                status: 'accepted',
                rejectReason: null,
                disabled: false,
                value: 'DE12500105170648489890',
                minLength: 5,
                maxLength: 34,
              },
              payoutBic: {
                __typename: 'ProfileFieldTypeString',
                name: 'payoutBic',
                readOnly: false,
                status: 'accepted',
                rejectReason: null,
                disabled: false,
                value: 'INGDDEFFXXX',
                minLength: 8,
                maxLength: 11,
              },
              payoutPaxumEmailAccount: {
                __typename: 'ProfileFieldTypeString',
                name: 'payoutPaxumEmailAccount',
                readOnly: false,
                status: 'accepted',
                rejectReason: null,
                disabled: false,
                value: '',
                minLength: 1,
                maxLength: 60,
              },
              payoutAddressDocument: {
                __typename: 'ProfileFieldTypeUploadDocument',
                name: 'payoutAddressDocument',
                status: 'accepted',
                rejectReason: null,
                value: {
                  docId: 451255,
                  __typename: 'UploadDocument',
                },
              },
              __typename: 'ProfileCollectionPayoutV1Fields',
            },
            values: {
              payoutIsTaxable: true,
              payoutTaxId: 'DE123456789',
              payoutTaxDocument: {
                docId: 450492,
                __typename: 'UploadDocument',
              },
              payoutTransactionType: 'bank',
              payoutBankaccountOwner: 'Martin',
              payoutIban: 'DE12500105170648489890',
              payoutBic: 'INGDDEFFXXX',
              payoutPaxumEmailAccount: '',
              payoutAddressCity: 'Aschaffenburg',
              payoutAddressCountry: 'DE',
              payoutAddressDocument: {
                docId: 451255,
                __typename: 'UploadDocument',
              },
              payoutAddressPostalCode: '63739',
              payoutAddressStreet: 'Frohsinnstraße',
              payoutAddressStreetNumber: '19',
              payoutAddressFirstname: '',
              payoutAddressLastname: '',
              payoutAddressCompanyname: 'Bevelop GmbH',
              payoutAddressIsCompany: true,
              __typename: 'ProfileCollectionPayoutV1Values',
            },
            __typename: 'ProfileCollectionPayoutV1',
          },
          __typename: 'Profile',
        },
      } as GetFinanceServiceQuery)
    );
  }),
  rest.post('http://localhost:3000/upload-document', (req, res, ctx) => {
    return res(ctx.delay(2_000), ctx.text('123'));
  }),
  graphql.query('GetUploadInfoForField', (req, res, ctx) => {
    return res(
      ctx.data({
        profile: {
          field: {
            upload: {
              url: 'http://localhost:3000/upload-document',
              errorCodes: mediaUploadErrorCodes.de,
              requiredFormData: {},
              __typename: 'UploadInfo',
            },
            __typename: 'ProfileFieldTypeUploadDocument',
          },
          __typename: 'Profile',
        },
        __typename: 'Query',
      } as GetUploadInfoForFieldQuery)
    );
  }),
];

export const accountStatusHandler = [
  graphql.query('AccountStatusProvider', (req, res, ctx) => {
    return res(
      ctx.data({
        account: {
          isModelVerified: true,
          wasOnlineOnVXLive: true,
          __typename: 'Account',
        },
        profileTour: {
          status: 'accepted',
          lastVerified: '2023-09-07T09:45:38+00:00',
          __typename: 'TourOnboardingV1',
        },
        verificationTour: {
          modelVerificationStatus: 'accepted',
          __typename: 'Account',
        },
        mrfTour: {
          status: 'accepted',
          lastVerified: null,
          __typename: 'TourModelReleaseFormV1',
        },
      } as AccountStatusProviderQuery)
    );
  }),
  graphql.query('GetFeedTotalCount', (req, res, ctx) => {
    return res(
      ctx.data({
        feedTotalCounts: {
          totalCount: 96,
          __typename: 'FeedTotalCount',
        },
      } as GetFeedTotalCountQuery)
    );
  }),
];

export const billingAddressEdit = [
  graphql.query('GetPayoutDocumentsAddressSection', (req, res, ctx) => {
    return res(
      ctx.data({
        payment: {
          billingAddress: {
            status: 'pending',
            rejectReason: null,
            isCompany: {
              __typename: 'ProfileFieldTypeBoolean',
              name: 'paymentBillingAddressIsCompany',
              status: 'pending',
              rejectReason: null,
              value: true,
            },
            companyname: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressCompanyname',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: 'bevelop GmbH',
              minLength: 2,
              maxLength: 50,
            },
            firstname: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressFirstname',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: '',
              minLength: 2,
              maxLength: 50,
            },
            lastname: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressLastname',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: '',
              minLength: 2,
              maxLength: 50,
            },
            street: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressStreet',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: 'Frohsinnstraße',
              minLength: 2,
              maxLength: 100,
            },
            streetNumber: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressStreetNumber',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: '19',
              minLength: 1,
              maxLength: 5,
            },
            city: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressCity',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: 'Aschaffenburg',
              minLength: 1,
              maxLength: 60,
            },
            postalCode: {
              __typename: 'ProfileFieldTypeString',
              name: 'paymentBillingAddressPostalCode',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: '63739',
              minLength: 2,
              maxLength: 32,
            },
            country: {
              __typename: 'ProfileFieldTypeEnum',
              name: 'paymentBillingAddressCountry',
              readOnly: true,
              status: 'pending',
              rejectReason: null,
              disabled: false,
              value: 'DE',
              quickpickValues: [
                {
                  value: 'DE',
                  label: 'Deutschland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UK',
                  label: 'Grossbritannien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CO',
                  label: 'Kolumbien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PH',
                  label: 'Philippinen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PL',
                  label: 'Polen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'RU',
                  label: 'Russische Föderation',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CH',
                  label: 'Schweiz',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ES',
                  label: 'Spanien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CZ',
                  label: 'Tschechische Republik',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AT',
                  label: 'Österreich',
                  __typename: 'OptionEntry',
                },
              ],
              validValues: [
                {
                  value: 'AF',
                  label: 'Afghanistan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AX',
                  label: 'Aland Islands',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AL',
                  label: 'Albanien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'DZ',
                  label: 'Algerien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AS',
                  label: 'Amerikanisch Samoa',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AD',
                  label: 'Andorra',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AO',
                  label: 'Angola',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AI',
                  label: 'Anguilla',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AQ',
                  label: 'Antarktis',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AG',
                  label: 'Antigua und Barbuda',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AR',
                  label: 'Argentinien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AM',
                  label: 'Armenien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AW',
                  label: 'Aruba',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AZ',
                  label: 'Aserbaidschan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AU',
                  label: 'Australien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BS',
                  label: 'Bahamas',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BH',
                  label: 'Bahrain',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BD',
                  label: 'Bangladesch',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BB',
                  label: 'Barbados',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BE',
                  label: 'Belgien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BZ',
                  label: 'Belize',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BJ',
                  label: 'Benin',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BM',
                  label: 'Bermudas',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BT',
                  label: 'Bhutan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BO',
                  label: 'Bolivien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BA',
                  label: 'Bosnien-Herzegowina',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BW',
                  label: 'Botswana',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BV',
                  label: 'Bouvet Insel',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BR',
                  label: 'Brasilien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BN',
                  label: 'Brunei',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BG',
                  label: 'Bulgarien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BF',
                  label: 'Burkina Faso',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BI',
                  label: 'Burundi',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KY',
                  label: 'Cayman Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CL',
                  label: 'Chile',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CN',
                  label: 'China',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CC',
                  label: 'Cocos (Keeling) Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CG',
                  label: 'Congo',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CK',
                  label: 'Cook Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CR',
                  label: 'Costa Rica',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CI',
                  label: 'Cote d`Ivoire',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'DE',
                  label: 'Deutschland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'DM',
                  label: 'Dominica',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'DO',
                  label: 'Dominikanische Republik',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'DJ',
                  label: 'Dschibuti',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'DK',
                  label: 'Dänemark',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'EC',
                  label: 'Ecuador',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SV',
                  label: 'El Salvador',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ER',
                  label: 'Eritrea',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'EE',
                  label: 'Estland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'FK',
                  label: 'Falkland Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'FJ',
                  label: 'Fidschi',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'FI',
                  label: 'Finnland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'FR',
                  label: 'Frankreich',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PF',
                  label: 'Französisch Polynesien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GF',
                  label: 'Französisch-Guyana',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TF',
                  label: 'French Southern Territories',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'FO',
                  label: 'Färöer Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GA',
                  label: 'Gabun',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GM',
                  label: 'Gambia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GE',
                  label: 'Georgien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GH',
                  label: 'Ghana',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GI',
                  label: 'Gibraltar',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GD',
                  label: 'Grenada',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GR',
                  label: 'Griechenland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UK',
                  label: 'Grossbritannien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GL',
                  label: 'Grönland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GP',
                  label: 'Guadeloupe',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GU',
                  label: 'Guam',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GT',
                  label: 'Guatemala',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GG',
                  label: 'Guernsey',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GN',
                  label: 'Guinea',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GW',
                  label: 'Guinea-Bissau',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GY',
                  label: 'Guyana',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'HT',
                  label: 'Haiti',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'HM',
                  label: 'Heard und McDonald Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'HN',
                  label: 'Honduras',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'HK',
                  label: 'Hong Kong',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IN',
                  label: 'Indien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ID',
                  label: 'Indonesien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IQ',
                  label: 'Irak',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IR',
                  label: 'Iran',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IE',
                  label: 'Irland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IS',
                  label: 'Island',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IM',
                  label: 'Isle of Man',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IL',
                  label: 'Israel',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'IT',
                  label: 'Italien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'JM',
                  label: 'Jamaika',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'JP',
                  label: 'Japan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'YE',
                  label: 'Jemen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'JE',
                  label: 'Jersey',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'JO',
                  label: 'Jordanien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'YU',
                  label: 'Jugoslawien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KH',
                  label: 'Kambodscha',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CM',
                  label: 'Kamerun',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CA',
                  label: 'Kanada',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CV',
                  label: 'Kap Verde',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KZ',
                  label: 'Kasachstan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'QA',
                  label: 'Katar',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KE',
                  label: 'Kenia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KG',
                  label: 'Kirgistan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KI',
                  label: 'Kiribati',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CO',
                  label: 'Kolumbien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KM',
                  label: 'Komoren',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CD',
                  label: 'Kongo',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KR',
                  label: 'Korea, Sued',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'XK',
                  label: 'Kosovo',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'HR',
                  label: 'Kroatien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CU',
                  label: 'Kuba',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KW',
                  label: 'Kuwait',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LA',
                  label: 'Laos',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LS',
                  label: 'Lesotho',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LV',
                  label: 'Lettland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LB',
                  label: 'Libanon',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LR',
                  label: 'Liberia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LY',
                  label: 'Libyen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LI',
                  label: 'Liechtenstein',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LT',
                  label: 'Litauen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LU',
                  label: 'Luxemburg',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MO',
                  label: 'Macau',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MG',
                  label: 'Madagaskar',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MW',
                  label: 'Malawi',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MY',
                  label: 'Malaysia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MV',
                  label: 'Malediven',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ML',
                  label: 'Mali',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MT',
                  label: 'Malta',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MA',
                  label: 'Marokko',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MH',
                  label: 'Marshall Islands',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MQ',
                  label: 'Martinique',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MR',
                  label: 'Mauritanien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MU',
                  label: 'Mauritius',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'YT',
                  label: 'Mayotte',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MK',
                  label: 'Mazedonien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MX',
                  label: 'Mexiko',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MI',
                  label: 'Midway Islands',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MD',
                  label: 'Moldawien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MC',
                  label: 'Monaco',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MN',
                  label: 'Mongolei',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ME',
                  label: 'Montenegro',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MS',
                  label: 'Montserrat',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MZ',
                  label: 'Mosambik',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MM',
                  label: 'Myanmar',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NA',
                  label: 'Namibia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NR',
                  label: 'Nauru',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NP',
                  label: 'Nepal',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NC',
                  label: 'Neukaledonien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NZ',
                  label: 'Neuseeland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NI',
                  label: 'Nicaragua',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AN',
                  label: 'Niederlaendische Antillen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NL',
                  label: 'Niederlande',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NE',
                  label: 'Niger',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NG',
                  label: 'Nigeria',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NU',
                  label: 'Niue',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NF',
                  label: 'Norfolk Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'NO',
                  label: 'Norwegen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'MP',
                  label: 'Nördliche Mariana Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'OM',
                  label: 'Oman',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TP',
                  label: 'Ost Timor',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PK',
                  label: 'Pakistan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PW',
                  label: 'Palau',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PS',
                  label: 'Palestinian Territory',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PA',
                  label: 'Panama',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PG',
                  label: 'Papua-Neuguinea',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PY',
                  label: 'Paraguay',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PE',
                  label: 'Peru',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PH',
                  label: 'Philippinen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PN',
                  label: 'Pitcairn',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PL',
                  label: 'Polen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PT',
                  label: 'Portugal',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PR',
                  label: 'Puerto Rico',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'RE',
                  label: 'Reunion',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'RW',
                  label: 'Ruanda',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'RO',
                  label: 'Rumänien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'RU',
                  label: 'Russische Föderation',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SH',
                  label: 'Saint Helena',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'KN',
                  label: 'Saint Kitts und Nevis',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LC',
                  label: 'Saint Lucia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'PM',
                  label: 'Saint Pierre und Miquelon',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ZM',
                  label: 'Sambia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'WS',
                  label: 'Samoa',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SM',
                  label: 'San Marino',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ST',
                  label: 'Sao Tome und Principe',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SA',
                  label: 'Saudi Arabien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SE',
                  label: 'Schweden',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CH',
                  label: 'Schweiz',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SN',
                  label: 'Senegal',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'RS',
                  label: 'Serbia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CS',
                  label: 'Serbien Montenegro',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SC',
                  label: 'Seychellen',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SL',
                  label: 'Sierra Leone',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SG',
                  label: 'Singapur',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SK',
                  label: 'Slowakei',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SI',
                  label: 'Slowenien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SB',
                  label: 'Solomon Islands',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SO',
                  label: 'Somalia',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ES',
                  label: 'Spanien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'E5',
                  label: 'Spanien, Kanarische Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'LK',
                  label: 'Sri Lanka',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SD',
                  label: 'Sudan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SR',
                  label: 'Surinam',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SZ',
                  label: 'Swaziland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'SY',
                  label: 'Syrien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ZA',
                  label: 'Süd Afrika',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TJ',
                  label: 'Tadschikistan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TW',
                  label: 'Taiwan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TZ',
                  label: 'Tansania',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TH',
                  label: 'Thailand',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TG',
                  label: 'Togo',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TK',
                  label: 'Tokelau',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TO',
                  label: 'Tonga',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TT',
                  label: 'Trinidad und Tobago',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TD',
                  label: 'Tschad',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CZ',
                  label: 'Tschechische Republik',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TN',
                  label: 'Tunesien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TM',
                  label: 'Turkmenistan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TC',
                  label: 'Turks und Caicos Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TV',
                  label: 'Tuvalu',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'TR',
                  label: 'Türkei',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'US',
                  label: 'USA',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UG',
                  label: 'Uganda',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UA',
                  label: 'Ukraine',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'HU',
                  label: 'Ungarn',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UM',
                  label: 'United States Minor Outlying Islands',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UY',
                  label: 'Uruguay',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'UZ',
                  label: 'Usbekistan',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'VU',
                  label: 'Vanuatu',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'VA',
                  label: 'Vatikanstadt',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'VE',
                  label: 'Venezuela',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AE',
                  label: 'Vereinigte Arabische Emirate',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'VN',
                  label: 'Vietnam',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'VG',
                  label: 'Virgin Islands (Britisch)',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'VI',
                  label: 'Virgin Islands (USA)',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'WF',
                  label: 'Wallis und Futuna Inseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CX',
                  label: 'Weihnachtsinseln',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'BY',
                  label: 'Weissrussland',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'EH',
                  label: 'Western Sahara',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CF',
                  label: 'Zentralafrikanische Republik',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ZW',
                  label: 'Zimbabwe',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'CY',
                  label: 'Zypern',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'EG',
                  label: 'Ägypten',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'GQ',
                  label: 'Äquatorial-Guinea',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'ET',
                  label: 'Äthiopien',
                  __typename: 'OptionEntry',
                },
                {
                  value: 'AT',
                  label: 'Österreich',
                  __typename: 'OptionEntry',
                },
              ],
            },
            document: {
              __typename: 'ProfileFieldTypeUploadDocument',
              name: 'paymentBillingAddressDocument',
              status: 'accepted',
              rejectReason: null,
              value: null,
              // value: {
              //   docId: 465960,
              //   status: DocumentStatusEnum.Rejected,
              //   expireDate: null, //'2023-10-24T00:00:00+02:00',
              //   filename: '465960.jpg',
              //   url: 'https://vx.vxcdn.org/doc/4WT6jxlwwOi3s5OdHbKHKivzj0XVfFKKWx1Og_9E6gg/653672c4/08/26/10852608/docs/465960.jpg?h=CB1B8F79ABCDD4CD361E0F4997571CAC',
              //   __typename: 'UploadDocument',
              // },
            },
            __typename: 'PaymentBillingAddress',
          },
          __typename: 'Payment',
        },
      })
    );
  }),
];

export const notificationHandler = [
  graphql.query('GetNotificationsCount', (req, res, ctx) => {
    return res(
      ctx.data({
        notifications: {
          pagination: {
            totalCount: 0,
            __typename: 'NotificationConnection',
          },
          __typename: 'Notifications',
        },
      })
    );
  }),
  graphql.query('GetImportantNotifications', (req, res, ctx) => {
    return res(
      ctx.data({
        notifications: {
          filter: {
            preset: 'IMPORTANT_ALL',
            __typename: 'NotificationsFilter',
          },
          pagination: {
            pageInfo: {
              hasNextPage: false,
              __typename: 'PageInfo',
            },
            edges: [
              {
                cursor: 'YXJyYXljb25uZWN0aW9uOjA=',
                node: {
                  id: '0defb3e94647979f4f3ab8a653f0ea05e8fd775a',
                  category: 'PAYMENT',
                  title:
                    'Handlungsbedarf: Ohne Steuer-ID ab Januar 2024 Auszahlung nicht mehr möglich !',
                  subtitle:
                    'Reiche deine Steuer-Identifikationsnummer (TIN) bis zum 31.12.2023 ein, um weiterhin Auszahlungen zu erhalten. Trage dafür unter “Mein Account → Auszahlungsdokumente” deine TIN ein und klicke auf “Änderungen einreichen”. Deine TIN findest du z. B. auf deiner Lohnsteuerbescheinigung. Läuft dein Account über ein Unternehmen? Auch in diesem Fall wird eine TIN benötigt!\nOhne diese Angabe kann ab Januar 2024 keine Auszahlung mehr erfolgen.\n',
                  createdAt: '2023-12-12T12:18:41+00:00',
                  updatedAt: '2023-12-12T12:18:41+00:00',
                  readAt: '2023-12-12T14:02:43+00:00',
                  type: 'PAYMENT_TAX_DATA_TIN_MISSING',
                  shortInfo: 'Steuer-ID aktualisieren',
                  uri: 'app://notification/id/0defb3e94647979f4f3ab8a653f0ea05e8fd775a',
                  link: null,
                  picture: null,
                  icon: {
                    src: 'app://static/icon/Error?color=error.500',
                    alt: null,
                    height: null,
                    width: null,
                    __typename: 'Image',
                  },
                  primaryButton: {
                    label: 'TIN aktualisieren',
                    action: 'classic://camtool/account/documents',
                    __typename: 'Button',
                  },
                  secondaryButton: null,
                  __typename: 'Notification',
                },
                __typename: 'NotificationEdge',
              },
            ],
            __typename: 'NotificationConnection',
          },
          __typename: 'Notifications',
        },
      })
    );
  }),
  graphql.query('GetAllNotifications', (req, res, ctx) => {
    return res(
      ctx.data({
        notifications: {
          filter: {
            preset: 'ALL',
            __typename: 'NotificationsFilter',
          },
          pagination: {
            pageInfo: {
              hasNextPage: false,
              __typename: 'PageInfo',
            },
            edges: [
              {
                cursor: 'YXJyYXljb25uZWN0aW9uOjA=',
                node: {
                  id: '0defb3e94647979f4f3ab8a653f0ea05e8fd775a',
                  category: 'PAYMENT',
                  title:
                    'Handlungsbedarf: Ohne Steuer-ID ab Januar 2024 Auszahlung nicht mehr möglich !',
                  subtitle:
                    'Reiche deine Steuer-Identifikationsnummer (TIN) bis zum 31.12.2023 ein, um weiterhin Auszahlungen zu erhalten. Trage dafür unter “Mein Account → Auszahlungsdokumente” deine TIN ein und klicke auf “Änderungen einreichen”. Deine TIN findest du z. B. auf deiner Lohnsteuerbescheinigung. Läuft dein Account über ein Unternehmen? Auch in diesem Fall wird eine TIN benötigt!\nOhne diese Angabe kann ab Januar 2024 keine Auszahlung mehr erfolgen.\n',
                  createdAt: '2023-12-12T12:18:41+00:00',
                  updatedAt: '2023-12-12T12:18:41+00:00',
                  readAt: '2023-12-12T14:02:43+00:00',
                  type: 'PAYMENT_TAX_DATA_TIN_MISSING',
                  shortInfo: 'Steuer-ID aktualisieren',
                  uri: 'app://notification/id/0defb3e94647979f4f3ab8a653f0ea05e8fd775a',
                  link: null,
                  picture: null,
                  icon: {
                    src: 'app://static/icon/Error?color=error.500',
                    alt: null,
                    height: null,
                    width: null,
                    __typename: 'Image',
                  },
                  primaryButton: {
                    label: 'TIN aktualisieren',
                    action: 'classic://camtool/account/documents',
                    __typename: 'Button',
                  },
                  secondaryButton: null,
                  __typename: 'Notification',
                },
                __typename: 'NotificationEdge',
              },
            ],
            __typename: 'NotificationConnection',
          },
          __typename: 'Notifications',
        },
      })
    );
  }),
];
