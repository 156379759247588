import { deleteAllCookies } from './cookie';

export function createTestIds<
  Prefix extends Readonly<string>,
  List extends Readonly<Readonly<string>[]>
>(prefix: Prefix, list: List) {
  return {
    ...list.reduce<TestIdNameSet<Prefix, List>>(
      (acc, name) => ({ ...acc, [name]: `${prefix}|${name}` }),
      {} as TestIdNameSet<Prefix, List>
    ),
  };
}

export function createTestId(prefix: string, entry: string) {
  return `${prefix}-${entry}`;
}

type TestIdNameSet<
  Prefix extends Readonly<string>,
  List extends Readonly<Readonly<string>[]>
> = {
  [Name in List[number]]: `${Prefix}|${Name}`;
};
type TestIdNameFnSet<
  Prefix extends Readonly<string>,
  List extends Readonly<Readonly<string>[]>
> = {
  [Name in List[number]]: (
    ...args: [...{ toString(): string }[]]
  ) => `${Prefix}|${Name}|${string}`;
};

function createTestIdSuffixFn<
  Prefix extends Readonly<string>,
  Name extends Readonly<string>
>(prefix: Prefix, name: Name) {
  return (...args: [...(string | number)[]]): `${Prefix}|${Name}|${string}` =>
    `${prefix}|${name}|${args.map((e) => e.toString()).join('|')}`;
}

export function createTestIdSet<
  Prefix extends Readonly<string>,
  List extends Readonly<Readonly<string>[]>
>(
  prefix: Prefix,
  list: List
): TestIdNameSet<Prefix, List> & { suffixed: TestIdNameFnSet<Prefix, List> } {
  return {
    ...list.reduce<TestIdNameSet<Prefix, List>>(
      (acc, name) => ({ ...acc, [name]: `${prefix}|${name}` }),
      {} as TestIdNameSet<Prefix, List>
    ),
    suffixed: list.reduce<TestIdNameFnSet<Prefix, List>>(
      (acc, name) => ({ ...acc, [name]: createTestIdSuffixFn(prefix, name) }),
      {} as TestIdNameFnSet<Prefix, List>
    ),
  };
}

export const cleanupDocumentScripts = () => {
  document.body.innerHTML = '';
  document.head.innerHTML = '';
  deleteAllCookies();
};

export function tkey<T extends Readonly<string> = Readonly<string>>(_: T) {
  return _;
}
