import { AspectRatio, Box, ContainerProps } from '@chakra-ui/react';
import { FILE_STATES } from '@rpldy/uploady';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VideoJsPlayerOptions } from 'video.js';

import {
  FeedPost,
  FeedPostBody,
  FeedPostButtonWrapper,
  FeedPostDataText,
  FeedPostDate,
  FeedPostHeader,
  FeedPostHeaderDataRow,
  FeedPostHeaderStack,
  FeedPostHeaderTitle,
  FeedPostMenu,
  FeedPostModelImage,
  FeedPostPin,
  FeedPostTags,
  FeedPostViews,
} from '../../../../../components/shared/FeedPost/FeedPost';
import { ScheduledForDateTimeBatch } from '../../../../../components/shared/ScheduledForDateTimeBatch/ScheduledForDateTimeBatch';
import VideoPlayer from '../../../../../components/shared/VideoPlayer/VideoPlayer';
import { FeatureName, useFeature } from '../../../../../flags';
import {
  FeedClipPostFragment,
  PostStatusEnum,
  PostTypeEnum,
} from '../../../../../generated/feed';
import { useActiveApiLanguage } from '../../../../../hooks/useActiveApiLanguage';
import { useFeedService } from '../../../../../provider/FeedService/FeedService';
import { useUploadyService } from '../../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { getLengthInMinutes } from '../../../../../utils/videoLength';
import { useFeedPostUploadProgress } from '../../../hooks/useFeedPostUploadProgress';
import { PostFunctionProps } from '../../../posts';
import { avatarURL, typeDependantModelName } from '../../../utils';
import { PostFooter } from '../component/PostFooter';
import { FeedPostBrokenInlineAlert } from '../component/PostInlineAlert';
import { TempFeedPostScheduleProp } from '../component/types';

export const ClipPost: React.FC<
  {
    containerProps?: ContainerProps;
    tags?: string[];
    formattedPrice?: string;
    isPostOwnedByUser?: boolean;
  } & TempFeedPostScheduleProp &
    FeedClipPostFragment &
    PostFunctionProps
> = ({
  id,
  umaId,
  status,
  model,
  created,
  pinned,
  formattedPrice = '',
  purchases,
  title,
  text,
  likes,
  comments,
  tips,
  type,
  clip,
  views,
  tags = [],
  price,
  isPublished,
  publicationScheduledFor,
  tippingGoal,
  rejected,
  deRejection,
  enRejection,
  onClickOnPin,
  onClickOnLikes,
  onClickOnTips,
  onClickOnEdit,
  onClickOnDelete,
  containerProps,
  isPostOwnedByUser = true,
}) => {
  const { t } = useTranslation(['feed']);
  const { action } = useFeedService();
  const { isVideoPaused, onPlayVideo } = action;
  const paused = isVideoPaused(id);

  const { feedUploads } = useUploadyService();
  const currentTusUpload = feedUploads.find(
    (upload) => Number(upload.umaId) === Number(umaId)
  );
  const uploadProgressElement = useFeedPostUploadProgress({
    //this is the status of the post instance
    status,
    postId: id,
    umaId,
    isTus: true,
  });

  const brokenOrErrorStatus =
    status === PostStatusEnum.Broken ||
    status === PostStatusEnum.Rejected ||
    currentTusUpload?.status === FILE_STATES.ERROR ||
    currentTusUpload?.status === FILE_STATES.CANCELLED;
  const isPinnable = !brokenOrErrorStatus;
  const isEditable = !brokenOrErrorStatus;
  const isScheduled = status === PostStatusEnum.Scheduled;

  const apiLang = useActiveApiLanguage();
  const rejectionReason = React.useMemo(() => {
    switch (apiLang) {
      case 'de':
        return deRejection;
      default:
        return enRejection;
    }
  }, [deRejection, enRejection, apiLang]);

  const isFeaturedPost = type === PostTypeEnum.FpHotClip;
  const clipLength = getLengthInMinutes(clip?.length);
  const videoJsOptions = {
    sources: [
      {
        src: `${clip?.sources?.[0]?.src}`,
        type: clip?.sources?.[0]?.type,
      },
    ],
    poster: clip?.poster?.url,
    preload: 'metadata',
  } satisfies VideoJsPlayerOptions;

  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);
  const isMediaDimmedDown =
    feedTippingGoalFlag && !!tippingGoal && tippingGoal?.achieved === false;

  return (
    <FeedPost
      postId={id}
      status={status}
      currentTusUpload={currentTusUpload}
      rejectionReason={rejectionReason}
      isFeatured={isFeaturedPost}
      isScheduled={isScheduled}
      onDeletePost={() => onClickOnDelete(id)}
      isPhotoPost={false}
      multiPhotoPost={false}
      {...containerProps}
    >
      <FeedPostHeader borderRadius={isFeaturedPost ? '8px 8px 0 0' : '0'}>
        <FeedPostModelImage
          src={avatarURL(isFeaturedPost, model.avatar?.url)}
          srcSet={isFeaturedPost ? undefined : model.avatar?.srcSet}
        />
        <FeedPostHeaderStack>
          <FeedPostHeaderTitle
            children={typeDependantModelName(
              isFeaturedPost,
              model.name,
              t('feed:label.FeaturedPostAvatar')
            )}
          />
          <FeedPostHeaderDataRow>
            {isScheduled && publicationScheduledFor ? (
              <ScheduledForDateTimeBatch dateTime={publicationScheduledFor} />
            ) : (
              <>
                <FeedPostDate children={created} />
                <FeedPostDataText children={clipLength} />
                <FeedPostViews children={views} />
              </>
            )}
          </FeedPostHeaderDataRow>
        </FeedPostHeaderStack>
        {isPostOwnedByUser && (
          <FeedPostButtonWrapper>
            {!isPinnable ? (
              <></>
            ) : (
              <FeedPostPin
                isPinned={pinned}
                onClickOnPin={() => onClickOnPin(id, pinned)}
              />
            )}
            <FeedPostMenu
              onClickOnEdit={!isEditable ? null : onClickOnEdit}
              onClickOnDelete={() => onClickOnDelete(id)}
            />
          </FeedPostButtonWrapper>
        )}
      </FeedPostHeader>
      <FeedPostBody bgColor={!!uploadProgressElement ? 'steel' : 'steel'}>
        {brokenOrErrorStatus ? (
          <FeedPostBrokenInlineAlert
            rejectionReason={rejectionReason}
            onDeletePost={() => onClickOnDelete(id)}
          />
        ) : (
          <AspectRatio ratio={16 / 9}>
            {uploadProgressElement ? (
              <Box>{uploadProgressElement}</Box>
            ) : (
              <Box>
                {clip?.sources &&
                  clip?.sources.length > 0 &&
                  clip?.poster?.url && (
                    <VideoPlayer
                      isDimmedDown={isMediaDimmedDown}
                      options={videoJsOptions}
                      formattedPrice={formattedPrice}
                      onPlay={() => onPlayVideo(id)}
                      paused={paused}
                    />
                  )}
                {tags && <FeedPostTags tags={tags} />}
              </Box>
            )}
          </AspectRatio>
        )}
      </FeedPostBody>
      <PostFooter
        isPhotoPost={false}
        multiPhotoPost={false}
        postId={id}
        status={status}
        brokenOrErrorStatus={brokenOrErrorStatus}
        likes={likes}
        comments={comments.totalCount}
        tips={tips.count}
        price={price}
        purchaseCount={purchases?.count ?? 0}
        tippingGoal={tippingGoal}
        onClickOnLikes={onClickOnLikes}
        onClickOnTips={onClickOnTips}
        onDeletePost={() => onClickOnDelete(id)}
        headline={title}
        text={text}
        borderRadius={isFeaturedPost ? '0 0 8px 8px' : '0'}
      />
    </FeedPost>
  );
};
