import { Visibility, VisibilityOff } from '@campoint/odi-ui-icons';
import { useBoolean } from '@chakra-ui/hooks';
import { Icon, forwardRef } from '@chakra-ui/react';
import React from 'react';

import { useContainerWidth } from '../../../../hooks/useContainerWidth';
import {
  ClearableInput,
  ClearableInputClearElement,
  ClearableInputControl,
  ClearableInputControlProps,
} from '../ClearableInputGroup';
import { ClearableInputRightElement } from '../ClearableInputGroup/ClearableInputRightElement';

export type PasswordControlProps = ClearableInputControlProps;

export const PasswordControl = forwardRef<ClearableInputControlProps, 'input'>(
  (
    {
      inputGroupProps,
      inputProps: passedInputProps,
      isDisabled,
      ...rest
    }: ClearableInputControlProps,
    ref
  ) => {
    const { ...restPassedInputProps } = passedInputProps ?? {};
    const { ref: addonRef, width: addonWidth } = useContainerWidth();
    const { ref: clearableRef, width: clearableWidth } = useContainerWidth();
    const [showPassword, { toggle }] = useBoolean(false);
    return (
      <ClearableInputControl isDisabled={isDisabled} {...rest}>
        <ClearableInput
          {...restPassedInputProps}
          type={showPassword ? 'text' : 'password'}
          ref={ref}
          pr={`${addonWidth + clearableWidth}px`}
        />
        <ClearableInputClearElement
          ref={clearableRef}
          right={`${addonWidth}px`}
        />
        <ClearableInputRightElement ref={addonRef} isDisabled={isDisabled}>
          {showPassword ? (
            <Icon
              data-testid={'passwordControlIcon'}
              as={VisibilityOff}
              onClick={toggle}
              cursor={'pointer'}
              boxSize={'icon.md'}
            />
          ) : (
            <Icon
              data-testid={'passwordControlIcon'}
              as={Visibility}
              onClick={toggle}
              cursor={'pointer'}
              boxSize={'icon.md'}
            />
          )}
        </ClearableInputRightElement>
      </ClearableInputControl>
    );
  }
);
