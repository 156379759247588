import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFinanceService } from '../../../provider/FinanceService/FinanceService';
import { LayoutCenteringVStack } from '../../Layout/LayoutCenteringVStack';
import { RejectedTaxInformationCard } from '../Payout/RejectedInformationCard/RejectedInformationCard';

export const UpdateTaxInformationRejectedCardSlot = () => {
  const { t } = useTranslation(['payout']);
  const {
    isPayoutTaxUpdateVisible,
    isPayoutWizardV1Finished,
    isPayoutTaxUpdateRejected,
    payoutTaxUpdateRejectReason,
  } = useFinanceService();

  const isShown =
    isPayoutTaxUpdateVisible &&
    isPayoutWizardV1Finished &&
    isPayoutTaxUpdateRejected;

  return !isShown ? null : (
    <LayoutCenteringVStack>
      <RejectedTaxInformationCard
        rejectedElement={t('payout:label.Umsatzsteuernachweis')}
        reason={payoutTaxUpdateRejectReason ?? ''}
      />
    </LayoutCenteringVStack>
  );
};
