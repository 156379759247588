import { PlayCircleOutline } from '@campoint/odi-ui-icons';
import { Box, Icon } from '@chakra-ui/react';
import React from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';

import useIsMounted from '../../../hooks/useIsMounted';
import {
  FeedPostBuyButton,
  FeedPostCenteredButtons,
} from '../FeedPost/FeedPost';

interface VideoPlayerProps {
  options: any;
  formattedPrice?: string;
  isDimmedDown?: boolean;
  onReady?: (player: any) => void;
  //set video id so all others get paused
  onPlay?: () => void;
  paused?: boolean;
}
const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: false,
  fill: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
  bigPlayButton: false,
};

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const isMounted = useIsMounted();
  const videoRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<any>(null);
  const {
    options,
    formattedPrice,
    isDimmedDown = false,
    onPlay,
    paused,
  } = props;

  const [isPlaying, setIsPlaying] = React.useState(false);
  const oldOptions = React.useRef<{
    hash: string | null;
    options: VideoJsPlayerOptions;
  }>({ hash: null, options: {} });

  const videoJsOptions = React.useMemo(() => {
    const newOptions: VideoJsPlayerOptions = options;
    // Only return new options if the hashes differ
    const hash = JSON.stringify(newOptions);
    if (hash === oldOptions.current.hash) {
      return oldOptions.current.options;
    }
    oldOptions.current.hash = hash;
    oldOptions.current.options = newOptions;
    return newOptions;
  }, [options]);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');

      if (videoRef.current) {
        videoRef.current.appendChild(videoElement);
      }

      playerRef.current = videojs(videoElement, {
        ...initialOptions,
        ...videoJsOptions,
      });
      playerRef.current.on(['waiting', 'pause'], () => {
        if (isMounted()) {
          setIsPlaying(false);
        }
      });
      playerRef.current.on(['playing'], () => {
        onPlay?.();
        if (isMounted()) {
          setIsPlaying(true);
        }
      });
    } else if (videoJsOptions === oldOptions.current.options) {
      // options are identical, so avoid setting them again to avoid flickering and cancellation of playback
      return;
    } else {
      const player = playerRef.current;
      player.autoplay(videoJsOptions.autoplay);
      player.src(videoJsOptions.sources);
    }
  }, [videoJsOptions, setIsPlaying, isMounted, onPlay]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    if (paused && playerRef.current) {
      playerRef.current?.pause();
    }
  }, [paused]);

  return (
    <>
      <Box
        w={'full'}
        h={'full'}
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        cursor={'pointer'}
        opacity={isDimmedDown ? 0.4 : 'unset'}
      />
      {!isPlaying && (
        <FeedPostCenteredButtons>
          <Icon
            as={PlayCircleOutline}
            color={'surface'}
            h={'42px'}
            w={'42px'}
            cursor={'pointer'}
            pointerEvents={'none'}
          />
          {formattedPrice !== '' && (
            <FeedPostBuyButton
              formattedPrice={formattedPrice ?? ''}
              isVideo={true}
            />
          )}
        </FeedPostCenteredButtons>
      )}
    </>
  );
};

export default VideoPlayer;
