import { Box, HStack, Heading, ModalFooter } from '@chakra-ui/react';
import * as React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { createContext } from '../../../../hooks/useContext';
import { noop } from '../../../../utils';
import LoadingPage from '../../../LoadingPage/LoadingPage';

export const wizardParentLayoutTestingIds = {
  closeButton: 'wizard-parent-layout-close-button',
  steps: 'wizard-parent-layout-steps',
};

const WizardProgressbar: React.FC<{
  totalSteps: number;
  currentStep: number;
}> = ({ totalSteps, currentStep }) => {
  const steps = React.useMemo(() => {
    return Array.from({ length: totalSteps }, (_, index) => ({
      index,
      isFilled: index < currentStep,
    }));
  }, [totalSteps, currentStep]);
  return (
    <HStack
      role={'progressbar'}
      aria-valuenow={currentStep}
      aria-valuemin={0}
      aria-valuemax={totalSteps}
      alignItems={'stretch'}
      minH={1}
      w={'full'}
      spacing={'0.5'}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          bg={step.isFilled ? 'primary.500' : 'gray.200'}
          flexGrow={1}
        />
      ))}
    </HStack>
  );
};

export const [WizardModalProvider, useWizardModal, wizardModalContext] =
  createContext<{
    footerRef: React.MutableRefObject<HTMLElement | null>;
  }>({
    name: 'Wizard Modal Provider',
    errorMessage:
      'useWizardModal: `wizardModalContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export type WizardParentModalLayoutProps = {
  currentStep?: number;
  headerText?: string;
  totalSteps?: number;
  loading?: boolean;
  onCloseClick: () => void;
  isProcessing?: boolean;
  children?: React.ReactNode;
  isFooterSticky?: boolean;
};
export const WizardParentModalLayout: React.FC<
  WizardParentModalLayoutProps
> = ({
  children,
  currentStep = 0,
  headerText = '',
  totalSteps = 1,
  loading = false,
  isProcessing = false,
  onCloseClick = noop,
  isFooterSticky = true,
}) => {
  const ref = React.useRef(null);
  const footerRef = React.useRef<HTMLDivElement | null>(null);
  const context = React.useMemo(
    () => ({
      footerRef,
    }),
    [footerRef]
  );

  return (
    <WizardModalProvider value={context}>
      {/* Currently we portal into this local box, to have the portal within the Storybook canvas bounds */}
      <Box ref={ref} />
      <ResponsiveModal
        isOpen={true}
        onClose={onCloseClick}
        portalProps={{ containerRef: ref }}
        closeOnOverlayClick={false}
        preferredSize="xl"
      >
        <ResponsiveModalOverlay />
        <ResponsiveModalContent>
          <ResponsiveModalStickyHeaderBox p={0}>
            <Heading as="h1" children={headerText} size={'sm'} p={4} />
            <ResponsiveModalCloseButton />
            <Box data-testid={wizardParentLayoutTestingIds.steps}>
              <WizardProgressbar
                totalSteps={totalSteps}
                currentStep={currentStep}
              />
            </Box>
          </ResponsiveModalStickyHeaderBox>
          <ResponsiveModalBodyBox
            aria-busy={loading || isProcessing}
            children={
              !loading ? (
                children
              ) : (
                <Box height={'200px'}>
                  <LoadingPage />
                </Box>
              )
            }
          />
          {!loading && (
            <ResponsiveModalStickyFooterBox
              {...(isFooterSticky ? undefined : { position: 'relative' })}
            >
              <ModalFooter
                ref={footerRef}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'stretch'}
              />
            </ResponsiveModalStickyFooterBox>
          )}
        </ResponsiveModalContent>
      </ResponsiveModal>
    </WizardModalProvider>
  );
};
