import {
  Section,
  SectionBody,
  SectionButton,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { MonetizationOn } from '@campoint/odi-ui-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  HSnapScrollContainer,
  SnapScrollContainerEnd,
} from '../../../../components/Layout/HSnapScrollContainer';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import ExperienceIntentionImageCard from '../../../../components/shared/ExperienceIntentionImageCard/ExperienceIntentionImageCard';
import { ExperienceIntentionStatusIndicatorIncomeSources } from '../../../../components/shared/ExperienceIntentionStatusIndicator/ExperienceIntentionStatusIndicator';
import {
  IncomeSourceEntryFragment,
  useGetIncomeSourceSectionDataQuery,
} from '../../../../generated/graphql';
import {
  IncomeSourceDetailModalProvider,
  useIncomeSourceDetailModal,
} from '../../../../provider/IncomeSourceDetailModalProvider';
import { routes } from '../../../../routes/routesConfig';
import { extractDefinedEntries } from '../../../../utils/utils';

const IncomeSourceCard: React.FC<{ entry: IncomeSourceEntryFragment }> = ({
  entry,
}) => {
  const incomeSourceModalCtx = useIncomeSourceDetailModal();

  return (
    <ExperienceIntentionImageCard
      key={entry.id}
      entry={entry}
      id={`income-source-image-card-${entry.id}`}
      onClick={() => {
        incomeSourceModalCtx.action.showIncomeSource(entry.id);
      }}
      sx={{
        scrollSnapAlign: 'center',
        verticalAlign: 'top',
        justifyContent: 'space-between',
      }}
      statusIndicator={
        entry.activatedAt && <ExperienceIntentionStatusIndicatorIncomeSources />
      }
    />
  );
};

export const IncomeSourceSection: React.FC = () => {
  const { t } = useTranslation(['home', 'general']);
  const history = useHistory();

  const { data } = useGetIncomeSourceSectionDataQuery({
    fetchPolicy: 'cache-first',
  });

  const incomeSourceEntries: IncomeSourceEntryFragment[] =
    extractDefinedEntries(data?.helpcenter?.incomeSources?.entries);

  return (
    <IncomeSourceDetailModalProvider>
      <Section>
        <SectionHeader>
          <SectionCenterContainer>
            <SectionTitleRow>
              <SectionIcon as={MonetizationOn} />
              <SectionTitle sx={{ flexShrink: 1 }}>
                {t('home:heading.Verdienstmoglichkeiten')}
              </SectionTitle>
              <SectionButton
                children={t('general:button.all')}
                onClick={() => {
                  history.push(routes.incomeSources.path);
                }}
              />
            </SectionTitleRow>
            <SectionDescription>
              {t('home:text.ErweiteredeinenServicemitweiterenVXFeatures')}
            </SectionDescription>
          </SectionCenterContainer>
        </SectionHeader>
        <SectionBody>
          <SectionCenterContainer>
            <HSnapScrollContainer>
              {incomeSourceEntries.map((entry) => (
                <IncomeSourceCard key={entry.id} entry={entry} />
              ))}
              <SnapScrollContainerEnd />
            </HSnapScrollContainer>
          </SectionCenterContainer>
        </SectionBody>
      </Section>
    </IncomeSourceDetailModalProvider>
  );
};
