import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionIconButton,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import {
  ArrowDropDown,
  ArrowDropUp,
  ChevronLeft,
  ChevronRight,
  CustomSettings,
  PieChart,
} from '@campoint/odi-ui-icons';
import {
  Center,
  Grid,
  HStack,
  Heading,
  Icon,
  IconButton,
  IconProps,
  VStack,
} from '@chakra-ui/react';
import { Interval } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import {
  BarChart,
  BarChartBarRow,
  BarChartCaption,
  BarChartGrid,
  BarChartLabelRow,
  BarChartLegend,
} from '../../../../components/shared/BarChart';
import { CurrencyAmount } from '../../../../components/shared/CurrencyAmount/CurrencyAmount';
import {
  AuthUserPermissionEnum,
  useGetStatisticSectionQuery,
} from '../../../../generated/graphql';
import { useTimeframe } from '../../../../hooks/useTimeframe';
import { useAuth } from '../../../../provider/AuthProvider';
import { useStatistic } from '../../../../provider/StatisticProvider';
import { routes } from '../../../../routes/routesConfig';
import { StatsTileWithIcon } from './StatsTile/StatsTile';

const TrendIndicatorArrow: React.FC<IconProps & { trend?: number }> = ({
  trend = 0,
  ...rest
}) => {
  if (trend > 0) {
    return (
      <Icon
        color={'success.highEmphasis'}
        as={ArrowDropUp}
        boxSize={8}
        {...rest}
      />
    );
  }
  if (trend < 0) {
    return (
      <Icon
        color={'error.highEmphasis'}
        as={ArrowDropDown}
        boxSize={8}
        {...rest}
      />
    );
  }
  return null;
};

const StatisticSection: React.FC = () => {
  const { authUser } = useAuth();
  const financeStatsPermission = authUser?.permissions.includes(
    AuthUserPermissionEnum.FinanceStatsView
  );

  const Intl = useIntl();
  const history = useHistory();
  const { t } = useTranslation(['statistic']);
  const timeFrame = useTimeframe();

  const { entries: statisticCards, lastUpdatedAt } = useStatistic(true);

  const { data: weekData } = useGetStatisticSectionQuery({
    fetchPolicy: 'no-cache',
    variables: {
      startDate: timeFrame.firstDayOfWeek.toISODate(),
      endDate: timeFrame.today.toISODate(),
      prevStartDate: timeFrame.firstDayOfPreviousWeek.toISODate(),
      prevEndDate: timeFrame.firstDayOfPreviousWeek.endOf('week').toISODate(),
    },
  });
  const { data: monthData } = useGetStatisticSectionQuery({
    fetchPolicy: 'no-cache',
    variables: {
      startDate: timeFrame.firstDayOfMonth.toISODate(),
      endDate: timeFrame.today.toISODate(),
      prevStartDate: timeFrame.firstDayOfPreviousMonth.toISODate(),
      prevEndDate: timeFrame.firstDayOfPreviousMonth.endOf('month').toISODate(),
    },
  });

  const availableTimeFrames = [
    t('statistic:heading.DieseWoche'),
    t('statistic:heading.DieserMonat'),
    t('statistic:heading.DiesesJahr'),
  ].slice(0, 2); // todo: remove slice to enable year selection
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>(0);

  const { indexOfCurrent, values, total, previousTotal } = React.useMemo(() => {
    const daysInWeek = Interval.fromDateTimes(
      timeFrame.firstDayOfWeek,
      timeFrame.firstDayOfNextWeek
    ).splitBy({
      days: 1,
    });
    const i = daysInWeek.findIndex((dayInterval) =>
      dayInterval.contains(timeFrame.today)
    );
    const currentWeek = {
      previousTotal: weekData?.previous.turnoverForPeriodTotal ?? 0,
      total: weekData?.current.turnoverForPeriodTotal ?? 0,
      indexOfCurrent: i < 0 ? daysInWeek.length : i,
      values: daysInWeek.map((dayInterval, index) => {
        return {
          label: Intl.formatDate(dayInterval.start.toJSDate(), {
            weekday: 'short',
          }),
          value:
            weekData?.current.turnoverForPeriodGroupedByDay?.[index]?.value ??
            0,
        };
      }),
    };
    const daysInMonth = Interval.fromDateTimes(
      timeFrame.firstDayOfMonth,
      timeFrame.firstDayOfNextMonth
    ).splitBy({
      days: 1,
    });

    const j = daysInMonth.findIndex((dayInterval) =>
      dayInterval.contains(timeFrame.today)
    );
    const currentMonth = {
      previousTotal: monthData?.previous.turnoverForPeriodTotal ?? 0,
      total: monthData?.current.turnoverForPeriodTotal ?? 0,
      indexOfCurrent: j < 0 ? daysInMonth.length : j,
      values: daysInMonth.map((dayInterval, index) => {
        return {
          label: Intl.formatDate(dayInterval.start.toJSDate(), {
            day: 'numeric',
          }),
          value:
            monthData?.current.turnoverForPeriodGroupedByDay?.[index]?.value ??
            0,
        };
      }),
    };

    return (
      [currentWeek, currentMonth][selectedTimeFrame] ?? {
        total: 0,
        indexOfCurrent: 0,
        values: [],
      }
    );
  }, [weekData, monthData, Intl, timeFrame, selectedTimeFrame]);

  const timeFrameHeading = React.useMemo(() => {
    switch (selectedTimeFrame) {
      case 0:
        return t('statistic:heading.DeinVerdienstDieseWoche');
      case 1:
        return t('statistic:heading.DeinVerdienstDiesenMonat');
      case 2:
        return t('statistic:heading.DeinVerdienstDiesesJahr');
    }
  }, [t, selectedTimeFrame]);

  const caption = React.useMemo(() => {
    switch (selectedTimeFrame) {
      case 0:
        return t('statistic:label.DateHeute', {
          date: timeFrame.firstDayOfWeek.toJSDate(),
        });
      case 1:
        return t('statistic:label.DateHeute', {
          date: timeFrame.firstDayOfMonth.toJSDate(),
        });
      case 2:
        return t('statistic:label.DateHeute', {
          date: timeFrame.firstDayOfYear.toJSDate(),
        });
    }
  }, [
    t,
    selectedTimeFrame,
    timeFrame.firstDayOfWeek,
    timeFrame.firstDayOfMonth,
    timeFrame.firstDayOfYear,
  ]);

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow>
            <SectionIcon as={PieChart} />
            <SectionTitle>{t('statistic:heading.Statistiken')}</SectionTitle>
            <SectionIconButton
              aria-label={t('statistic:button.StatistikEinstellungen')}
              icon={
                <Icon
                  as={CustomSettings}
                  boxSize={'icon.md'}
                  color={'gray.900'}
                />
              }
              onClick={() => {
                history.push(routes.statisticEdit.path);
              }}
            />
          </SectionTitleRow>
          <SectionDescription>
            {t('statistic:text.HeuteUmTimeAktualisiert', {
              time: lastUpdatedAt,
            })}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          <Grid templateColumns={'repeat(2, 1fr)'} w={'full'} gap={'4'} mb={20}>
            {statisticCards.map((statisticCard) => (
              <StatsTileWithIcon
                key={statisticCard.id}
                category={statisticCard.category}
                value={statisticCard.values[0]?.value}
                unit={statisticCard?.unit ?? undefined}
                headline={statisticCard.label}
                trend={statisticCard.trend?.percent}
                type={statisticCard.__typename}
              />
            ))}
          </Grid>

          {financeStatsPermission && (
            <VStack spacing={8} w={'full'}>
              <HStack w={'full'}>
                <IconButton
                  isDisabled={selectedTimeFrame <= 0}
                  aria-label={t('statistic:button.ZuKleineremZeitraum')}
                  icon={<Icon as={ChevronLeft} boxSize={'icon.md'} />}
                  onClick={() => setSelectedTimeFrame((prev) => prev - 1)}
                />
                <Center flexGrow={1}>
                  <Heading size={'xl'}>
                    {availableTimeFrames[selectedTimeFrame]}
                  </Heading>
                </Center>
                <IconButton
                  isDisabled={
                    selectedTimeFrame >= availableTimeFrames.length - 1
                  }
                  aria-label={t('statistic:button.ZuGrosseremZeitraum')}
                  icon={<Icon as={ChevronRight} boxSize={'icon.md'} />}
                  onClick={() => setSelectedTimeFrame((prev) => prev + 1)}
                />
              </HStack>
              <VStack spacing={4} py={4}>
                <Heading size={'md'} color={'onSurface.mediumEmphasis'}>
                  {timeFrameHeading}
                </Heading>
                <Heading
                  size={'3xl'}
                  as={'span'}
                  role="status"
                  aria-live="polite"
                >
                  <CurrencyAmount amount={total} />
                  <TrendIndicatorArrow trend={total - previousTotal} />
                </Heading>
              </VStack>
              <BarChart
                w={'full'}
                indexOfCurrent={indexOfCurrent}
                values={values}
              >
                <BarChartGrid minH={32}>
                  <BarChartBarRow />
                  <BarChartLabelRow />
                </BarChartGrid>
                <BarChartCaption>{caption}</BarChartCaption>
                <Center pt={10}>
                  <HStack spacing={2}>
                    <BarChartLegend
                      baseLabel={t('statistic:label.Einnahmen')}
                      bestLabel={t('statistic:label.BesterTag')}
                      debtLabel={t('statistic:label.Kosten')}
                      futureLabel={t('statistic:label.Zukunft')}
                    />
                  </HStack>
                </Center>
              </BarChart>
            </VStack>
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

export default StatisticSection;
