import { StackProps } from '@chakra-ui/react';
import React from 'react';

import { useFinanceService } from '../../../provider/FinanceService/FinanceService';
import { LayoutCenteringVStack } from '../../Layout/LayoutCenteringVStack';
import { UpdateTaxInformationHint } from '../Payout/UpdateTaxInformation/UpdateTaxInformationHint';

export const UpdateTaxInformationExpiredHintSlot: React.FC<{
  outerStackProps?: StackProps;
}> = ({ outerStackProps }) => {
  const {
    isPayoutTaxUpdateVisible,
    isPayoutTaxUpdatePending,
    isPayoutTaxUpdateRejected,
    isPayoutTaxDocumentExpired,
  } = useFinanceService();

  const isShown =
    isPayoutTaxUpdateVisible &&
    !(isPayoutTaxUpdatePending || isPayoutTaxUpdateRejected) &&
    isPayoutTaxDocumentExpired;

  return !isShown ? null : (
    <LayoutCenteringVStack outerStackProps={outerStackProps}>
      <UpdateTaxInformationHint />
    </LayoutCenteringVStack>
  );
};
