import ENV from '../environments/environment';
import Logger from '../utils/Logger';

export enum FeatureName {
  wizardPayoutV2 = 'wizardPayoutV2',
  feed = 'feed',
  feedFilter = 'feedFilter',
  feedPostScheduling = 'feedPostScheduling',
  newscenter = 'newscenter',
  feedPaidContent = 'feedPaidContent',
  feedStatistics = 'feedStatistics',
  feedTippingGoal = 'feedTippingGoal',
  feedVxLiveChatButton = 'feedVxLiveChatButton',
  document = 'document',
  documentFinanceEditAllowed = 'documentFinanceEditAllowed',
  documentPreferredForFinanceDocument = 'documentPreferredForFinanceDocument',
  videoLibrary = 'videoLibrary',
  financePageNew = 'financePageNew',
  deeplImplementation = 'deeplImplementation',
  contest = 'contest',
}

const typedReadonly = <
  T extends { readonly [key in FeatureName]: object | boolean }
>(
  _: T
): Readonly<T> => _;

export const features = typedReadonly({
  [FeatureName.document]: ENV.FEATURE_DOCUMENT,
  [FeatureName.documentFinanceEditAllowed]:
    ENV.FEATURE_DOCUMENT_FINANCE_EDIT_ALLOWED,
  [FeatureName.feed]: ENV.FEATURE_FEED,
  [FeatureName.feedFilter]: ENV.FEATURE_FEED_FILTER,
  [FeatureName.feedPaidContent]: ENV.FEATURE_FEED_PAID_CONTENT,
  [FeatureName.feedPostScheduling]: ENV.FEATURE_FEED_POST_SCHEDULING,
  [FeatureName.feedStatistics]: ENV.FEATURE_FEED_STATISTICS,
  [FeatureName.feedTippingGoal]: ENV.FEATURE_FEED_TIPPING_GOAL,
  [FeatureName.feedVxLiveChatButton]: ENV.FEATURE_FEED_VXLIVE_CHAT_BUTTON,
  [FeatureName.newscenter]: ENV.FEATURE_NEWSCENTER,
  [FeatureName.wizardPayoutV2]: ENV.FEATURE_WIZARD_PAYOUT_V2,
  [FeatureName.documentPreferredForFinanceDocument]:
    ENV.FEATURE_DOCUMENT_PREFERRED_FOR_FINANCE_DOCUMENT_EDITS,
  [FeatureName.videoLibrary]: ENV.FEATURE_VIDEO_LIBRARY,
  [FeatureName.financePageNew]: ENV.FEATURE_FINANCE_PAGE_NEW,
  [FeatureName.deeplImplementation]: ENV.FEATURE_DEEPL_IMPLEMENTATION,
  [FeatureName.contest]: ENV.FEATURE_CONTEST,
});

export type Features = typeof features;

// Log an overview in non prod environments
if (!ENV.PRODUCTION && ENV.ENVIRONMENT !== 'test') {
  Logger.groupCollapsed('Feature Flags');
  Logger.log(features);
  Logger.groupEnd();
}
