import { Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import verifyEmailImage from '../../../../../assets/images/email/verify-email.svg';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../../components/Layout/ResponsiveModal';
import {
  EmailVerificationModalResendEmailMutation,
  useEmailVerificationModalResendEmailMutation,
  useGetVerifyEmailModalDataQuery,
} from '../../../../../generated/graphql';
import { useOnboardingTaskStatusContext } from '../../../../../provider/OnboardingTaskStatusProvider';
import { routes } from '../../../../../routes/routesConfig';
import ChangeMailSection from './components/ChangeMailSection/ChangeMailSection';
import EmailVerifiedSection from './components/EmailVerifiedSection/EmailVerifiedSection';
import ResendMailSection from './components/ResendMailSection/ResendMailSection';

export type VerifyEmailModalProps = {};
export type EmailVerificationRouterState = {
  source: 'home' | 'modelReleaseWizard' | '';
};

export const VerifyEmailModal: React.FC<VerifyEmailModalProps> = () => {
  const { t } = useTranslation(['home']);
  const history = useHistory();
  const { actions: onboardingTaskStatusContextActions } =
    useOnboardingTaskStatusContext();

  const { data: queryData, loading: dataLoading } =
    useGetVerifyEmailModalDataQuery();

  const emailAddress = React.useMemo(() => {
    return queryData?.account?.contact?.currentEmailAddress;
  }, [queryData?.account?.contact?.currentEmailAddress]);

  const isEmailAddressVerified = React.useMemo(
    () => queryData?.account.contact.isCurrentEmailAddressVerified,
    [queryData?.account.contact.isCurrentEmailAddressVerified]
  );

  const closeAction = () => {
    onboardingTaskStatusContextActions.refetchEmailVerificationStatus();
    routeToHome();
  };
  // routing
  const routeToHome = React.useCallback(() => {
    history.push(routes.home.path);
  }, [history]);
  const routeToAccount = React.useCallback(() => {
    history.push(routes.account.path);
  }, [history]);

  // handle resend email button click
  const resendEmailDone = (data: EmailVerificationModalResendEmailMutation) => {
    if (data.account?.resendRegistrationMail?.success) {
      issueChakraToast({
        status: 'success',
        description: t('home:text.EinNeuerAktivierungslinkWurdeVerschickt'),
      });
    }
  };

  const [resendEmailData, { loading: resendEmailDataLoading }] =
    useEmailVerificationModalResendEmailMutation({
      onCompleted: resendEmailDone,
    });

  //route to home if email already verified
  React.useEffect(() => {
    if (isEmailAddressVerified) {
      onboardingTaskStatusContextActions.refetchEmailVerificationStatus();
      issueChakraToast({
        description: t('home:toast.EmailErfolgreichVerifiziert'),
        status: 'success',
      });
      routeToHome();
    }
  }, [
    isEmailAddressVerified,
    routeToHome,
    onboardingTaskStatusContextActions,
    t,
  ]);

  return (
    <ResponsiveModal
      isOpen={!dataLoading && !isEmailAddressVerified}
      onClose={closeAction}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalCloseButton />
        <ResponsiveModalBodyBox mb="10">
          <VStack align="start" spacing="0">
            <Img
              alignSelf="center"
              sx={{ aspectRatio: '2/1' }}
              minWidth="32"
              minHeight="32"
              src={verifyEmailImage}
              alt={t('home:imgAlt.EmailVerifizieren')}
            />
            <EmailVerifiedSection email={emailAddress} />
            <ResendMailSection
              actionButtonDisabled={resendEmailDataLoading || dataLoading}
              buttonAction={resendEmailData}
            />
            <VStack spacing="2" align="start" pt="6">
              <Text
                as="div"
                textStyle={'bodyMd'}
                color="gray.300"
                fontWeight="medium"
                children={t('home:text.EMailAdresseBereitsBestatigt')}
              />
              <Text
                textStyle={'bodyMd'}
                color="gray.300"
                textAlign="left"
                children={t(
                  'home:text.DannKannstDuDenHinweisEinfachSchliessen'
                )}
              />
            </VStack>
            <ChangeMailSection
              actionButtonDisabled={dataLoading}
              buttonAction={routeToAccount}
            />
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
