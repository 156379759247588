import { HStack, Tag, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const VideoLibraryDiscountTag: React.FC<{
  discountPercent: number | null | undefined;
  discountStart?: any;
  discountEnd?: any;
}> = ({ discountPercent, discountStart, discountEnd }) => {
  const formatDEDiscountDateRange = (_: { oldest: Date; newest: Date }) => {
    return new Intl.DateTimeFormat('de', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    }).formatRange(_.oldest, _.newest);
  };

  const { t } = useTranslation(['videoLibrary']);

  const discountTagDateRange = React.useMemo(() => {
    if (discountStart && discountEnd) {
      return `(${formatDEDiscountDateRange({
        oldest: DateTime.fromISO(discountStart).toJSDate(),
        newest: DateTime.fromISO(discountEnd).toJSDate(),
      }).replace(/–/g, ` ${t('videoLibrary:text.Bis')} `)})`;
    }
    return '';
  }, [t, discountEnd, discountStart]);

  return (
    <Tag
      minW={'unset'}
      minH={'unset'}
      h={'5'}
      paddingInlineStart={'1'}
      paddingInlineEnd={'1'}
      color={'coldGray.900'}
      bg={'caribbeanGreen.300'}
      textStyle={'labelXs'}
    >
      <HStack spacing={1} lineHeight={'20px'}>
        <Text fontWeight={700}>{`-${discountPercent}%`}</Text>
        {discountStart && discountEnd ? (
          <Text fontWeight={300}>{`${discountTagDateRange}`}</Text>
        ) : null}
      </HStack>
    </Tag>
  );
};
