import React from 'react';

import { usePaidContentConfigQuery } from '../generated/feed';
import { useAuth } from '../provider/AuthProvider';
import Logger from '../utils/Logger';

export interface FeedConfig {
  price: {
    min: number;
    max: number;
    step: number;
  };
  tippingGoal: {
    min: number;
    max: number;
    steps: number[];
  };
}
export const useFeedConfig = () => {
  const authCtx = useAuth();

  const { data, ...rest } = usePaidContentConfigQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => Logger.error(error),
    skip: !authCtx.isAuthenticated,
  });

  const mappedData = React.useMemo<FeedConfig>(() => {
    return {
      price: {
        min: data?.config?.paidContent?.min ?? 1,
        max: data?.config?.paidContent?.max ?? 20,
        step: data?.config?.paidContent?.step ?? 0.5,
      },
      tippingGoal: {
        min: data?.config?.tippingGoal?.min ?? 5,
        max: data?.config?.tippingGoal?.max ?? 150,
        steps: (data?.config?.tippingGoal?.steps?.filter(
          Number
        ) as number[]) ?? [
          5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35,
          40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130,
          140, 150,
        ],
      },
    };
  }, [data]);

  return { data: mappedData, ...rest };
};
