import {
  OdiCard,
  OdiCardDescription,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardIcon,
  OdiCardTitle,
  OdiCardTop,
} from '@campoint/odi-ui';
import { LightBulbOutlined } from '@campoint/odi-ui-icons';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TipCardProps = {
  text: string;
  buttonFooter?: React.ReactNode;
};

export const TipCard: React.FC<TipCardProps> = ({ text, buttonFooter }) => {
  const { t } = useTranslation(['general']);
  return (
    <OdiCard data-testid={'TipCard'}>
      <OdiCardHeader color={'caribbeanGreen.500'}>
        <OdiCardTop children={<OdiCardIcon as={LightBulbOutlined} />} />
        <OdiCardTitle children={t('general:heading.UnserTipp')} />
        <OdiCardDescription children={<Text children={text} />} />
      </OdiCardHeader>
      {buttonFooter && <OdiCardFooter>{buttonFooter}</OdiCardFooter>}
    </OdiCard>
  );
};

export const HTipCard: React.FC<TipCardProps> = ({ text, buttonFooter }) => {
  return (
    <OdiCard
      data-testid={'TipCard'}
      flexDirection={'row'}
      color={'caribbeanGreen.500'}
      gap={0}
    >
      <OdiCardTop
        children={<OdiCardIcon as={LightBulbOutlined} />}
        flex={'0 0'}
        p={3}
      />
      <OdiCardDescription
        color={'inherit'}
        pl={0}
        flex={'1 0'}
        textAlign={'left'}
        lineHeight={1.3}
        children={<Text children={text} />}
      />
      {buttonFooter && <OdiCardFooter>{buttonFooter}</OdiCardFooter>}
    </OdiCard>
  );
};
