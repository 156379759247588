import { ApolloError } from '@apollo/client';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import avatarPlaceholder from '../../../../assets/images/feed/AvatarPlaceholder.svg';
import { getFormattedAmount } from '../../../../utils/formattedAmount';
import { AgeIndicator } from '../../AgeIndicator/AgeIndicator';
import { ChatWithUserButton } from '../../ChatWithUserButton/ChatWithUserButton';
import { FetchMoreIndicator } from '../../FetchMoreIndicator/FetchMoreIndicator';
import { ImageGalleryTag } from '../../ImageGallery/components/ImageGalleryTag';
import { NoSalesList } from './EmptyState';

export type Sale = {
  userId: Maybe<string>;
  avatarUrl: Maybe<string>;
  userName: Maybe<string>;
  soldAt: Maybe<string>;
  discount?: Maybe<number>;
  price?: Maybe<number>;
};

export const SalesTab: React.FC<{
  sales: Sale[];
  salesLoading?: boolean;
  salesError?: ApolloError | undefined;
  loadMoreSales?: () => void;
  showChat?: boolean;
}> = ({
  sales,
  salesLoading = false,
  salesError,
  loadMoreSales,
  showChat = false,
}) => {
  const isSalesEmpty = (sales?.length ?? 0) === 0;
  if (isSalesEmpty) return <NoSalesList />;

  return (
    <VStack spacing={'4'}>
      <>
        {sales?.map((sale) => {
          return (
            <SaleLineItem sale={sale} key={sale.userId} showChat={showChat} />
          );
        })}
        {loadMoreSales && (
          <FetchMoreIndicator
            mt={{ base: '8', lg: '12' }}
            loading={salesLoading}
            error={salesError}
            onIsVisible={loadMoreSales}
          />
        )}
      </>
    </VStack>
  );
};

const SaleLineItem: React.FC<{ sale: Sale; showChat: boolean }> = ({
  sale,
  showChat,
}) => {
  const { price, discount, userName, avatarUrl, userId, soldAt } = sale;
  const maybeDiscountPrice = discount && price ? price * discount : price;
  const locale = useIntl().locale;
  const { t } = useTranslation(['feed']);
  return (
    <Box width={'full'} key={userId}>
      <HStack spacing={'2.5'} alignItems={'start'}>
        <Image
          alignSelf={'start'}
          borderRadius={'full'}
          borderColor={'steel'}
          w={'30px'}
          h={'30px'}
          src={avatarUrl || avatarPlaceholder}
        />
        <VStack alignItems={'start'} spacing={0} lineHeight={'6'} flexGrow={1}>
          <Text color={'coldGray.900'} fontSize={'md'} fontWeight={'medium'}>
            {userName}
          </Text>
          <Text
            color={'coldGray.900'}
            fontSize={'sm'}
            fontWeight={'normal'}
            data-testid={'sold-amount'}
          >
            {t('feed:postStatistic.HatDeinenBeitragGekauft')}
          </Text>
          <AgeIndicator created={soldAt ?? ''} />
        </VStack>
        {price && (
          <VStack>
            {maybeDiscountPrice && (
              <Text
                color={discount ? 'caribbeanGreen.300' : 'coldGray.900'}
                fontWeight={'medium'}
              >
                {`${getFormattedAmount(maybeDiscountPrice, locale)}`}
              </Text>
            )}
            {discount && (
              <ImageGalleryTag
                children={`-${discount * 100}%`}
                bgColor={'caribbeanGreen.300'}
                color={'coldGray.900'}
                fontWeight={'bold'}
                fontSize={'12px'}
              />
            )}
          </VStack>
        )}
        {userId && showChat && <ChatWithUserButton guestUserId={userId} />}
      </HStack>
    </Box>
  );
};
