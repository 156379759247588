import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Tag,
  Text,
  VStack,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import retryEnhancer, { useRetry } from '@rpldy/retry-hooks';
import { WithRequestPreSendUpdateProps } from '@rpldy/shared-ui';
import {
  useItemCancelListener,
  withRequestPreSendUpdate,
} from '@rpldy/tus-uploady';
import UploadButton from '@rpldy/upload-button';
import UploadDropZone from '@rpldy/upload-drop-zone';
import UploadPreview, {
  PreviewComponentProps,
  PreviewItem,
  PreviewMethods,
} from '@rpldy/upload-preview';
import { UploaderEnhancer } from '@rpldy/uploader';
import TusUploady, {
  BatchItem,
  UPLOADER_EVENTS,
  composeEnhancers,
  useAbortItem,
  useItemAbortListener,
  useItemErrorListener,
  useItemProgressListener,
  useItemStartListener,
} from '@rpldy/uploady';
import { Meta, Story } from '@storybook/react';
import { rest } from 'msw';
import React from 'react';

import { RelativeBox } from './components/Layout/RelativeBox/RelativeBox';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from './components/Layout/ResponsiveModal';
import { ImageCropper } from './components/shared/ImageCropper/ImageCropper';
import VideoLengthFromSeconds from './components/shared/VideoLengthFromSeconds/VideoLengthFromSeconds';
import VideoPlayer from './components/shared/VideoPlayer/VideoPlayer';
import { createContext } from './hooks/useContext';
import { ImageCropperProvider } from './provider/ImageCropperProvider';
import { MediaPropertiesProvider } from './provider/MediaPropertiesProvider';
import { CroppedImage } from './types/CroppedImage';
import { actionLogEnhancer } from './upload.stories.util';
import { noop } from './utils';
import {
  ImageMetaData,
  VideoMetaData,
  findMatchingStandardAspectRatio,
  formatBytes,
  loadMediaMetaData,
} from './utils/media/mediaMetaData';

export default {
  title: 'Upload',
  parameters: {
    msw: {
      handlers: [
        rest.post('https://my-tus-server/upload', (req, res, ctx) => {
          return res(ctx.delay(1_000), ctx.status(200), ctx.text(`OK:${123}`));
        }),
        rest.post('https://my-other-server/upload', (req, res, ctx) => {
          return res(ctx.delay(1_000), ctx.status(200), ctx.text(`OK:${123}`));
        }),
      ],
    },
    layout: 'fullscreen',
  },
} as Meta;

const errorMap = new Map();
const mediaMetaDataMap = new Map();
const originalFileMap = new Map();
const videoLengthFailEnhancer: UploaderEnhancer = (uploader) => {
  uploader.on(UPLOADER_EVENTS.ITEM_START, async (item: BatchItem) => {
    mediaMetaDataMap.set(item.id, loadMediaMetaData(item.file as File));

    if (!originalFileMap.has(item.id)) {
      originalFileMap.set(item.id, URL.createObjectURL(item.file as File));
    }

    return await checkBatchItemForErrors(item.id);
  });

  return uploader;
};
const enhancer = composeEnhancers(
  // mockSenderEnhancer,
  retryEnhancer,
  actionLogEnhancer,
  videoLengthFailEnhancer
);

interface MediaError {
  code:
    | `duration-${string}`
    | `dimension-${string}`
    | `filesize-${string}`
    | `other-${string}`;
  message: {
    de: string;
    en: string;
  };
}
async function checkBatchItemForErrors(id: string) {
  const metaDataPromise = mediaMetaDataMap.get(id);
  if (!metaDataPromise) {
    return false;
  }

  let errors: MediaError[] = [];

  const metaData = await metaDataPromise;

  if (metaData.dimension.width < 520) {
    errors.push({
      message: { en: 'not wide enough', de: 'nicht breit genug' },
      code: 'dimension-width-too-short',
    });
  }

  if (metaData.dimension.height < 520) {
    errors.push({
      message: { en: 'not high enough', de: 'nicht hoch genug' },
      code: 'dimension-height-too-short',
    });
  }

  if (metaData.sizeInBytes > 1e8) {
    errors.push({
      message: { en: 'too large', de: 'zu groß' },
      code: 'filesize-too-large',
    });
  }

  if (metaData.type === 'video') {
    if (metaData.duration < 18) {
      errors.push({
        message: { en: 'too short', de: 'zu kurz' },
        code: 'duration-too-short',
      });
    }

    if (metaData.duration > 15 * 60) {
      errors.push({
        message: { en: 'too long', de: 'zu lang' },
        code: 'duration-too-long',
      });
    }
  }

  if (errors.length > 0) {
    errorMap.set(id, errors);
    return false;
  }

  return true;
}
const STATES = {
  PROGRESS: 'PROGRESS',
  DONE: 'DONE',
  ABORTED: 'ABORTED',
  ERROR: 'ERROR',
};

const AbortButton = forwardRef(({ id, state }, ref) => {
  const abortItem = useAbortItem();
  const onAbort = React.useCallback(() => abortItem(id), [id, abortItem]);

  return (
    <IconButton
      aria-label={'abort'}
      disabled={state === STATES.ABORTED || state === STATES.DONE}
      onClick={onAbort}
      variant={'link'}
      ref={ref}
      icon={<Icon as={icons.Close} boxSize={'icon.md'} />}
    />
  );
});

const RetryButton = forwardRef(({ id, state }, ref) => {
  const retry = useRetry();
  const onRetry = React.useCallback(() => retry(id), [id, retry]);

  return (
    <IconButton
      aria-label={'retry'}
      disabled={state !== STATES.ABORTED}
      variant={'link'}
      onClick={onRetry}
      ref={ref}
      icon={<Icon as={icons.Refresh} boxSize={'icon.md'} />}
    />
  );
});

function MetaData(props: {
  metaData: VideoMetaData | ImageMetaData;
  durationErrors?: any[];
  dimensionErrors?: any[];
  fileSizeErrors?: any[];
}) {
  const dimensionErrorDisc = useDisclosure();
  const fileSizeErrorDisc = useDisclosure();
  const durationErrorDisc = useDisclosure();
  const {
    metaData,
    durationErrors = [],
    dimensionErrors = [],
    fileSizeErrors = [],
  } = props;
  if (!metaData) {
    return <></>;
  }

  const foundAspectRatio = findMatchingStandardAspectRatio(
    metaData.dimension.aspectRatio
  );

  const sizeDescription = `${Math.round(
    metaData.dimension.width
  )} x ${Math.round(metaData.dimension.height)}`;

  const dimensionHasError = dimensionErrors.length > 0;
  const durationHasError = durationErrors.length > 0;
  const fileSizeHasError = fileSizeErrors.length > 0;

  return (
    <VStack alignItems={'start'}>
      <HStack>
        <Tag
          colorScheme={dimensionHasError ? 'red' : 'lime'}
          onClick={!dimensionHasError ? noop : dimensionErrorDisc.onOpen}
        >
          <Icon
            as={dimensionHasError ? icons.Error : icons.Check}
            color={dimensionHasError ? 'error.500' : 'lime.500'}
            boxSize={'icon.sm'}
            marginEnd={'2'}
          />
          {sizeDescription} (
          {foundAspectRatio?.landscapeMatch?.label ??
            metaData.dimension.aspectRatio?.toFixed(2) ??
            '-'}
          )
          <ResponsiveModal
            isOpen={dimensionErrorDisc.isOpen}
            onClose={dimensionErrorDisc.onClose}
          >
            <ResponsiveModalOverlay />
            <ResponsiveModalContent>
              <ResponsiveModalCloseButton />
              <ResponsiveModalStickyHeaderBox>
                <Heading>Fehlerbeschreibung</Heading>
              </ResponsiveModalStickyHeaderBox>
              <ResponsiveModalBodyBox>
                <Tag colorScheme={'red'}>
                  <Icon
                    as={icons.Error}
                    color={'error.500'}
                    boxSize={'icon.sm'}
                    marginEnd={'2'}
                  />
                  {sizeDescription}
                </Tag>
                {dimensionErrors.map((error, index) => (
                  <p key={index}>{error.message.de}</p>
                ))}
              </ResponsiveModalBodyBox>
            </ResponsiveModalContent>
          </ResponsiveModal>
        </Tag>
        <Tag
          colorScheme={fileSizeHasError ? 'red' : 'lime'}
          onClick={!fileSizeHasError ? noop : fileSizeErrorDisc.onOpen}
        >
          <Icon
            as={fileSizeHasError ? icons.Error : icons.Check}
            color={fileSizeHasError ? 'error.500' : 'lime.500'}
            boxSize={'icon.sm'}
            marginEnd={'2'}
          />
          {formatBytes(metaData.sizeInBytes)}
          <ResponsiveModal
            isOpen={fileSizeErrorDisc.isOpen}
            onClose={fileSizeErrorDisc.onClose}
          >
            <ResponsiveModalOverlay />
            <ResponsiveModalContent>
              <ResponsiveModalCloseButton />
              <ResponsiveModalStickyHeaderBox>
                <Heading>Fehlerbeschreibung</Heading>
              </ResponsiveModalStickyHeaderBox>
              <ResponsiveModalBodyBox>
                <Tag colorScheme={'red'}>
                  <Icon
                    as={icons.Error}
                    color={'error.500'}
                    boxSize={'icon.sm'}
                    marginEnd={'2'}
                  />
                  {formatBytes(metaData.sizeInBytes)}
                </Tag>
                {fileSizeErrors.map((error, index) => (
                  <p key={index}>{error.message.de}</p>
                ))}
              </ResponsiveModalBodyBox>
            </ResponsiveModalContent>
          </ResponsiveModal>
        </Tag>
        {metaData.type === 'video' && metaData?.duration > 0 ? (
          <Tag
            colorScheme={durationHasError ? 'red' : 'lime'}
            onClick={!durationHasError ? noop : durationErrorDisc.onOpen}
          >
            <Icon
              as={durationHasError ? icons.Error : icons.Check}
              color={durationHasError ? 'error.500' : 'lime.500'}
              boxSize={'icon.sm'}
              marginEnd={'2'}
            />
            <VideoLengthFromSeconds
              durationInSeconds={metaData.duration}
              textShadow={'unset'}
              textStyle={'unset'}
              color={'unset'}
            />

            <ResponsiveModal
              isOpen={durationErrorDisc.isOpen}
              onClose={durationErrorDisc.onClose}
            >
              <ResponsiveModalOverlay />
              <ResponsiveModalContent>
                <ResponsiveModalCloseButton />
                <ResponsiveModalStickyHeaderBox>
                  <Heading>Fehlerbeschreibung</Heading>
                </ResponsiveModalStickyHeaderBox>
                <ResponsiveModalBodyBox>
                  <Tag colorScheme={'red'}>
                    <Icon
                      as={icons.Error}
                      color={'error.500'}
                      boxSize={'icon.sm'}
                      marginEnd={'2'}
                    />
                    <VideoLengthFromSeconds
                      durationInSeconds={metaData.duration}
                      textShadow={'unset'}
                      textStyle={'unset'}
                      color={'unset'}
                    />
                  </Tag>
                  {durationErrors.map((error, index) => (
                    <p key={index}>{error.message.de}</p>
                  ))}
                </ResponsiveModalBodyBox>
              </ResponsiveModalContent>
            </ResponsiveModal>
          </Tag>
        ) : null}
      </HStack>
      {metaData.type !== 'video' ? (
        <></>
      ) : (
        <HStack>
          <RelativeBox
            attachment={
              <Tag size={'sm'}>
                <VideoLengthFromSeconds
                  durationInSeconds={0}
                  textShadow={'unset'}
                  textStyle={'unset'}
                  color={'unset'}
                />
              </Tag>
            }
          >
            <Image
              w={'20'}
              h={'20'}
              src={''}
              alt={''}
              objectFit={'contain'}
              objectPosition={'right center'}
              position={'absolute'}
            />
          </RelativeBox>
        </HStack>
      )}
    </VStack>
  );
}

// Create a new context
interface FileContextType {
  filename: string;
  filetype: string;
  fileErrors: string;
  readonly action: {
    setfileErrors: React.Dispatch<React.SetStateAction<string>>;
    readonly setFile: (name: string, type: string) => void;
  };
}

export const [, useFileContext, FileContext] = createContext<FileContextType>({
  name: 'FileContext',
});

// Context Provider component
export const FileContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // State to hold the filename and filetype
  const [filename, setFilename] = React.useState<string>('');
  const [filetype, setFiletype] = React.useState<string>('');
  const [fileErrors, setfileErrors] = React.useState<string>('');

  // Function to set the filename and filetype
  const setFile = React.useMemo(
    () => (name: string, type: string) => {
      setFilename(name);
      setFiletype(type);
    },
    [] // Empty dependency array as we don't have any dependencies for this memoized function
  );

  const action = React.useMemo<FileContextType['action']>(
    () => ({
      setFile: (name: string, type: string) => {
        setFile(name, type);
      },
      setfileErrors,
    }),
    [setFile]
  );

  const context = React.useMemo<FileContextType>(
    () => ({
      filename,
      filetype,
      fileErrors,
      action,
    }),
    [action, fileErrors, filename, filetype]
  );

  return (
    <FileContext.Provider value={context}>{children}</FileContext.Provider>
  );
};

const QueueItem = withRequestPreSendUpdate<
  PreviewComponentProps & WithRequestPreSendUpdateProps
>((props) => {
  const [progress, setProgress] = React.useState(0);
  const [itemState, setItemState] = React.useState<string | null>(null);
  const [itemErrors, setItemErrors] = React.useState<MediaError[]>([]);
  const [metaData, setMetaData] = React.useState<
    VideoMetaData | ImageMetaData | null
  >(null);
  const { isFallback, updateRequest, requestData } = props;

  useItemProgressListener((item) => {
    if (item.completed > progress) {
      setProgress(() => item.completed);
      setItemState(() =>
        item.completed === 100 ? STATES.DONE : STATES.PROGRESS
      );
    }
  }, props.id);

  useItemStartListener(() => {
    mediaMetaDataMap.get(props.id)?.then((metaData: any) => {
      setMetaData(metaData);
    });
  }, props.id);

  useItemCancelListener((item: BatchItem) => {
    setItemState(STATES.ERROR);
    const errors = errorMap.get(item.id);
    mediaMetaDataMap
      .get(props.id)
      ?.then((metaData: any) => setMetaData(metaData));
    setItemErrors(errors ?? []);
  }, props.id);

  useItemErrorListener((item: BatchItem) => {
    setItemErrors(item.uploadResponse?.errors ?? []);
  }, props.id);

  useItemAbortListener((item: BatchItem) => {
    setItemState(STATES.ABORTED);
    setItemErrors(item.uploadResponse?.errors ?? []);
  }, props.id);

  useItemErrorListener((item: BatchItem) => {
    setItemState(STATES.ERROR);
  }, props.id);

  const dimensionErrors = React.useMemo(() => {
    return itemErrors.filter((itemError) => /^dimension/.test(itemError.code));
  }, [itemErrors]);

  const durationErrors = React.useMemo(() => {
    return itemErrors.filter((itemError) => /^duration/.test(itemError.code));
  }, [itemErrors]);

  const fileSizeErrors = React.useMemo(() => {
    return itemErrors.filter((itemError) => /^filesize/.test(itemError.code));
  }, [itemErrors]);

  const [showCropper, setShowCropper] = React.useState(true);
  const [croppedSrc, setCroppedSrc] = React.useState<string | null>(null);

  const onUploadCrop = React.useCallback(
    async (c?: CroppedImage | null) => {
      if (c) {
        setCroppedSrc(URL.createObjectURL(c.blob));
        requestData.items[0].file = c.blob;
        const newMetaData = await loadMediaMetaData(requestData.items[0].file);
        mediaMetaDataMap.set(props.id, newMetaData);
        setMetaData(newMetaData);
      }

      if (!(await checkBatchItemForErrors(props.id))) {
        setShowCropper(false);
        const errors = errorMap.get(props.id);
        setItemErrors(errors);
        return;
      }

      //setFile('test', 'test');

      updateRequest({
        items: requestData.items,
        options: {
          destination: {
            url:
              Math.random() > 0.5
                ? 'https://my-tus-server/upload'
                : 'https://my-other-server/upload',
            // 'https://transcodeit-stage.inethoster.org/api/v1/tus/stageuseralbumvideo',
          },
        },
      });
      setShowCropper(false);
    },
    [requestData, updateRequest, props.id]
  );

  // action.setFile(props.name, props.type);

  const videoOptions = React.useMemo(() => {
    if (metaData?.type === 'video') {
      const posterBlob = metaData.thumbnail?.blob;
      return {
        sources: [
          {
            src: props.url,
            type: metaData.mimeType,
          },
        ],
        poster: !posterBlob ? undefined : URL.createObjectURL(posterBlob),
      };
    }
    return null;
  }, [props.url, metaData]);

  return (
    <Box
      data-status={itemState}
      bg={itemState === STATES.ERROR ? 'red.200' : 'transparent'}
      borderColor={itemState === STATES.ERROR ? 'red.500' : 'transparent'}
    >
      <HStack>
        <Box boxSize={'200px'} position={'relative'}>
          {props.type === 'image' ? (
            <Image
              w={'full'}
              h={'full'}
              src={croppedSrc || props.url}
              alt={''}
              objectFit={'contain'}
              objectPosition={'right center'}
              position={'absolute'}
            />
          ) : (
            <VideoPlayer key={videoOptions?.poster} options={videoOptions} />
          )}
        </Box>
        <Box flexGrow={1}>
          <Text>{props.name}</Text>
          <Text>{itemState}</Text>
          <Box>
            <CircularProgress
              value={progress}
              thickness={'16px'}
              size={'10'}
              color={progress === 100 ? 'lime.500' : 'primary.500'}
            >
              <CircularProgressLabel>
                {(progress ?? 0).toFixed(0)}%
              </CircularProgressLabel>
            </CircularProgress>
            {itemState === STATES.ABORTED ? (
              <RetryButton id={props.id} state={itemState} />
            ) : null}
            {itemState === STATES.PROGRESS ? (
              <AbortButton id={props.id} state={itemState} />
            ) : null}
          </Box>
          {metaData && (
            <MetaData
              metaData={metaData}
              durationErrors={durationErrors}
              dimensionErrors={dimensionErrors}
              fileSizeErrors={fileSizeErrors}
            />
          )}
        </Box>
      </HStack>

      {isFallback || props.type !== 'image' ? (
        <Button onClick={() => onUploadCrop(null)}>Upload</Button>
      ) : (
        <>
          {showCropper && requestData ? (
            <MediaPropertiesProvider
              targetDimensions={{ width: 40, height: 30 }}
              isOrientationFlipAllowed
            >
              <ImageCropperProvider
                src={originalFileMap.get(props.id) ?? ''}
                onImage={onUploadCrop}
                initialCroppedAreaPercentages={{
                  height: 100 / 2,
                  width: 88.31168831168831 / 2,
                  x: 5.844155844155847,
                  y: 0,
                }}
              >
                <ImageCropper />
              </ImageCropperProvider>
            </MediaPropertiesProvider>
          ) : null}
        </>
      )}
    </Box>
  );
});
function Comp({ metadata }: any) {
  const previewMethodsRef = React.useRef<PreviewMethods>(null);
  const [previews, setPreviews] = React.useState<PreviewItem[]>([]);
  const onPreviewsChanged = React.useCallback((previews: PreviewItem[]) => {
    setPreviews(previews);
  }, []);

  const onClear = React.useCallback(() => {
    if (previewMethodsRef.current?.clear) {
      mediaMetaDataMap.clear();
      errorMap.clear();
      previewMethodsRef.current.clear();
    }
  }, [previewMethodsRef]);

  return (
    <Box p={4}>
      <TusUploady
        autoUpload={true}
        destination={{
          url: 'https://transcodeit-stage.inethoster.org/api/v1/tus/stageuseralbumvideo',
        }}
        enhancer={enhancer}
        multiple={true}
        concurrent={true}
        maxConcurrent={3}
        params={Object.assign(metadata ?? {}, {
          filename: 'testfile.mp4',
          filetype: 'video/mp4',
        })}
      >
        <UploadDropZone onDragOverClassName="drag-over">
          <Box
            borderWidth={'1px'}
            borderColor={'primary.500'}
            borderRadius={'md'}
            w={'full'}
            height={'273px'}
            bg={'surface'}
            sx={{
              '.drag-over &': {
                bg: 'primary.300',
              },
            }}
          >
            <Center h={'full'}>
              <VStack textAlign={'center'}>
                <Circle bg={'steel'} size={'9'}>
                  <Icon
                    as={icons.Upload}
                    boxSize={'icon.md'}
                    color={'primary.500'}
                  />
                </Circle>
                <Heading size={'sm'} color={'primary.500'}>
                  Video(s), Clips oder Fotos hochladen
                </Heading>
                <Text color={'primary.500'}>
                  Dateien für den Upload per Drag-and-drop hinzufügen
                </Text>
                <Button as={UploadButton} children={'Inhalte auswählen'} />
              </VStack>
            </Center>
          </Box>
        </UploadDropZone>
        <button onClick={onClear}>Clear {previews.length} previews</button>
        <UploadPreview
          rememberPreviousBatches
          PreviewComponent={QueueItem}
          previewMethodsRef={previewMethodsRef}
          onPreviewsChanged={onPreviewsChanged}
          maxPreviewVideoSize={1e12}
          fallbackUrl={'http://inteliblue.com/i/videos/video-fallback.jpg'}
        />
      </TusUploady>
    </Box>
  );
}

export const Upload: Story = ({ metadata }) => {
  return <Comp metadata={metadata} />;
};

export const Default = Upload;
