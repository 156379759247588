import { useIntl } from 'react-intl';

export function useFormattedAmount(amount: number, currency: string = 'EUR') {
  const intl = useIntl();
  const formattedAmount = intl
    .formatNumberToParts(amount, {
      currency,
      style: 'currency',
    })
    .map(({ type, value }) => {
      if (type === 'fraction' && amount === 0) {
        return '–';
      }
      return value;
    })
    .join('');
  return formattedAmount;
}

export function getFormattedAmount(
  amount: number,
  locale: string = 'de-DE',
  currency: string = 'EUR'
) {
  const options = { style: 'currency', currency };
  const numberFormat = new Intl.NumberFormat(locale, options);
  const parts = numberFormat
    .formatToParts(amount)
    .map(({ type, value }) => {
      if (type === 'fraction' && amount === 0) {
        return '–';
      }
      return value;
    })
    .join('');
  return parts;
}
