import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { GraphQLError } from 'graphql';
import { print } from 'graphql/language/printer';
import { i18n } from 'i18next';
import jwtDecode from 'jwt-decode';

import ENV from '../environments/environment';
import { LoginWithTokenDocument } from '../generated/graphql';
import { LocalStorageKeys } from '../types';
import { LocalStorage } from '../utils';
import feedConfig from './../generated/feed.possibleTypes.json';
import vxModelsConfig from './../generated/graphql.possibleTypes.json';
import notificationsConfig from './../generated/notifications.possibleTypes.json';

export interface ApolloLinkContext {
  logout: () => void;
  i18n: i18n;
  /** Pass the token to the request when we have a token but no auth user */
  token?: string;
  [key: string]: any;
}

/**
 * Checks whether there are any unauthorized errors in a request.
 * Sometimes the API returns a 401, sometimes a 200 with an error message,
 * so we try to catch both errors in here.
 */
const checkForAuthorizationErrors = (
  graphQLErrors: readonly GraphQLError[] | undefined,
  networkError: Error | ServerParseError | ServerError | null | undefined
) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError?.statusCode === 401
  ) {
    return true;
  }

  for (const err of graphQLErrors || []) {
    if (err.message.startsWith('Unauthorized')) {
      return true;
    }
  }

  return false;
};

const errorHandlerLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (checkForAuthorizationErrors(graphQLErrors, networkError)) {
      const context = operation.getContext() as ApolloLinkContext;
      context.logout();
    }
  }
);

const vxmodelV2BaseUrl = new URL(ENV.BEVELOP_VXMODELS_API_URL);

/**
 * Link tries to determine an access token.
 *
 * First it will look up the access token entry in the local storage.
 * If there is none or the token in there is expired,
 * it will look up the refresh token entry in the local storage.
 * If there is none, an empty string will be returned which should result
 * in a failing request and therefore in a logout of the app.
 * In case there is a refresh token, a request will be made to the server
 * in order to fetch a new and valid access token, refresh token pair.
 *
 * In case the refresh token is used to get a new access token,
 * it will also be checked, if the user should be redirected to the
 * desktop version of the app.
 * In this case, a redirect URL will be set an picked up by a later
 * Apollo link to actually execute the redirect.
 *
 * @returns an access token
 */
const authTokenLink = setContext(async () => {
  const lsAccessToken = LocalStorage.getString(LocalStorageKeys.ACCESS_TOKEN);
  if (lsAccessToken) {
    const { exp }: { exp: number } = jwtDecode(lsAccessToken);
    // convert exp to milliseconds and subtract 1 minute
    // the early refresh is supposed to prevent token
    // expiration in the middle of a request
    if (exp * 1000 - 60000 > Date.now()) {
      return { token: lsAccessToken, redirect: undefined };
    }
  }

  const lsRefreshToken = LocalStorage.getString(LocalStorageKeys.REFRESH_TOKEN);
  if (!lsRefreshToken) {
    return { token: '', redirect: undefined };
  }

  const res = await fetch(vxmodelV2BaseUrl.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: print(LoginWithTokenDocument),
      variables: {
        token: lsRefreshToken,
      },
    }),
  });

  if (!res.ok) {
    return { token: '', redirect: undefined };
  }

  const { data } = await res.json();
  const { accessToken, refreshToken, redirectUrl } = data?.auth?.loginWithToken;

  LocalStorage.add(LocalStorageKeys.ACCESS_TOKEN, accessToken);
  LocalStorage.add(LocalStorageKeys.REFRESH_TOKEN, refreshToken);

  return { token: accessToken, redirect: redirectUrl };
});

/**
 * Redirects to the URL provided in the context.
 * If a redirect is triggered, access and refresh token will be deleted.
 */
// @ts-ignore
const redirectLink = new ApolloLink((operation, forward) => {
  const { redirect } = operation.getContext();

  if (!redirect) {
    return forward(operation);
  }

  LocalStorage.remove(LocalStorageKeys.ACCESS_TOKEN);
  LocalStorage.remove(LocalStorageKeys.REFRESH_TOKEN);
  window.open(redirect, '_self');
  return undefined;
});

const authLink = setContext(async (_, { headers, token }) => ({
  headers: {
    ...headers,
    Authorization: token ? `Bearer ${token}` : '',
  },
}));

const httpLink = createHttpLink({
  uri: (operation) => {
    const currentLanguage =
      operation.getContext().i18n?.language?.toLowerCase?.() ?? 'de';
    const url = new URL(vxmodelV2BaseUrl);
    url.searchParams.set('lang', currentLanguage);

    if (ENV.ENVIRONMENT !== 'production') {
      url.searchParams.set('APP_DEBUG', '1');
    }
    return url.toString();
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorHandlerLink,
    authTokenLink,
    redirectLink,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    canonizeResults: true,
    possibleTypes: vxModelsConfig.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          media: {
            //dont overwrite existing entries for this namespace type
            merge: true,
          },
        },
      },
      Media: {
        fields: {
          videos: {
            //dont overwrite existing entries for this namespace type
            merge: true,
          },
          photoalbums: {
            merge: true,
          },
          likes: relayStylePagination(['albumId']),
          sales: relayStylePagination(['albumId']),
          comments: relayStylePagination(['albumId']),
        },
      },
      Videos: {
        fields: {
          //keep separate cache entries for same type
          videosList: relayStylePagination(['status', 'mediaTypes', 'group']),
        },
      },
      Photoalbums: {
        fields: {
          photoalbumList: relayStylePagination(),
          pictureList: relayStylePagination(['albumId']),
        },
      },
      Video: {
        keyFields: ['albumId'],
      },
      TipsEntry: {
        keyFields: ['id'],
      },
      Helpcenter: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  }),
  // Disable caching
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

const feedUrl = new URL(ENV.BEVELOP_VXFEED_API_URL);

const feedHttpLink = createHttpLink({
  uri: () => feedUrl.toString(),
});

export const feedClient = new ApolloClient({
  link: ApolloLink.from([
    errorHandlerLink,
    authTokenLink,
    redirectLink,
    authLink,
    feedHttpLink,
  ]),
  cache: new InMemoryCache({
    canonizeResults: true,
    possibleTypes: feedConfig.possibleTypes,
    typePolicies: {
      Feed: {
        keyFields: ['id'],
        fields: {
          posts: relayStylePagination(),
        },
      },
    },
  }),
  // Disable caching
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

const notificationsUrl = new URL(ENV.BEVELOP_NOTIFICATIONS_API_URL);

const notificationsHttpLink = createHttpLink({
  uri: () => notificationsUrl.toString(),
});

export const notificationsClient = new ApolloClient({
  link: ApolloLink.from([
    errorHandlerLink,
    authTokenLink,
    redirectLink,
    authLink,
    notificationsHttpLink,
  ]),
  cache: new InMemoryCache({
    canonizeResults: true,
    possibleTypes: notificationsConfig.possibleTypes,
    typePolicies: {
      Notifications: {
        keyFields: ['filter', ['preset']],
        fields: {
          pagination: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});
