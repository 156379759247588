import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { useFeedConfig } from '../../../../hooks/useFeedConfig';
import { useFormattedAmount } from '../../../../utils/formattedAmount';

type TippingGoalSliderProps = {
  name: string;
  isDisabled?: boolean;
};

export const TippingGoalSlider = ({
  name,
  isDisabled,
}: TippingGoalSliderProps) => {
  const { data: feedConfig } = useFeedConfig();
  const possiblePrices = feedConfig.tippingGoal.steps;
  const [field, , { setValue }] = useField(name);
  const formattedAmount = useFormattedAmount(field.value);
  const minFormatted = useFormattedAmount(possiblePrices[0]);
  const maxFormatted = useFormattedAmount(
    possiblePrices[possiblePrices.length - 1]
  );
  const thumbSize = 30;
  const min = 0;
  const max = possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(field.value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    setValue(
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)]
    );
  }

  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        name={field.name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={curIndex}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'sm'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedAmount}
          </SliderMark>
        </Box>
        <SliderMark value={min} mt={'4'} fontSize={'sm'}>
          {minFormatted}
        </SliderMark>
        <SliderMark value={max} mt={'4'} ml={'-14'} fontSize={'sm'}>
          {maxFormatted}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};
