import ENV from '../environments/environment';
import Logger from './Logger';

const DEBUG = ENV.ENVIRONMENT !== 'production';

const enableGtagCallLogging =
  ENV.ENVIRONMENT !== 'production' && ENV.ENVIRONMENT !== 'test';

export const gtag: Gtag = function gtag() {
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).

  if (enableGtagCallLogging && DEBUG) {
    const args = arguments;
    const { length } = args;
    const argArray = Array.from({ length }, (v, k) => args[k]);
    const argString = JSON.stringify(argArray).slice(1, -1);
    Logger.groupCollapsed(`gtag(${argString})`);
    argArray.forEach((d) => Logger.log(d));
    Logger.groupEnd();
  }
  const w = window as any;
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push(arguments);
};

type GtagConfig = ControlParams | EventParams | CustomParams;
export interface GtagInstall {
  (trackingId: string, config?: GtagConfig): void;
}

export interface Gtag {
  (
    command: 'config',
    targetId: string,
    config?: ControlParams | EventParams | CustomParams
  ): void;
  (command: 'set', config: CustomParams): void;
  (command: 'js', config: Date): void;
  (
    command: 'event',
    eventName: EventNames | string,
    eventParams?:
      | ControlParams
      | EventParams
      | NotificationParams
      | CustomParams
  ): void;
  (
    command: 'consent',
    consentArg: 'default' | 'update',
    consentParams: ConsentParams
  ): void;
  <N extends keyof GlobalParams>(
    command: 'set',
    globalParam: N,
    value: GlobalParams[N]
  ): void;
}

interface GlobalParams {
  allow_google_signals: boolean;
  allow_ad_personalization_signals: boolean;
}

type ConsentOption = 'granted' | 'denied';
interface ConsentParams {
  ad_storage?: ConsentOption;
  analytics_storage?: ConsentOption;
  wait_for_update?: number;
}

interface CustomParams {
  [key: string]: any;
}

interface ControlParams {
  groups?: string | string[];
  send_to?: string | string[];
  event_callback?: () => void;
  event_timeout?: number;
}

type EventNames =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'wizard-step-errors'
  | 'wizard-step-completed'
  | 'Notification_MarkAsRead'
  | 'Click_Notification'
  | 'Click_Notification_External'
  | 'Click_Notification_Target'
  | 'Click_Notification_Secondary'
  | 'Click_Notification_Link';

interface EventParams {
  checkout_option?: string;
  checkout_step?: number;
  content_id?: string;
  content_type?: string;
  coupon?: string;
  currency?: string;
  description?: string;
  fatal?: boolean;
  items?: Item[];
  method?: string;
  number?: string;
  promotions?: Promotion[];
  screen_name?: string;
  search_term?: string;
  shipping?: Currency;
  tax?: Currency;
  transaction_id?: string;
  value?: number;
  event_label?: string;
  event_category?: string;
}

type Currency = string | number;

interface Item {
  brand?: string;
  category?: string;
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  location_id?: string;
  name?: string;
  price?: Currency;
  quantity?: number;
}

interface Promotion {
  creative_name?: string;
  creative_slot?: string;
  id?: string;
  name?: string;
}

export interface NotificationParams {
  type: string;
  category: string;
  createdAt: string;
  readAt: string;
  updatedAt: string;
  deltaCreatedRead: number;
  deltaUpdatedRead: number;
  externalUrl?: string;
  targetPath?: string;
}
