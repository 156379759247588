import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  HStack,
  Icon,
  Img,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MarketingVideos from '../../../../assets/images/videoLibrary/marketingVideos.svg';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import {
  ContestVideoFragment,
  Maybe,
  VideoFragment,
  useGetVideoLibraryMarketingVideoDateLazyQuery,
} from '../../../../generated/graphql';
import Logger from '../../../../utils/Logger';
import { UploadHistoryListItem } from '../VideoLibraryPageTabSectionVideo/VideoLibraryPageTabSectionVideo';

export const MarketingVideosSection: React.FC<{
  videos: VideoFragment[];
  playingVideoId: string | null;
  setPlayingVideoId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ videos, playingVideoId, setPlayingVideoId }) => {
  const { t } = useTranslation(['videoLibrary']);

  const columns = useBreakpointValue({
    base: 1,
    sm: 2,
    md: 3,
    xl: 4,
  });

  const videosExceedingColumns = videos.length > (columns ?? 1);

  const videoMinWidth = `calc(${(1 / (columns ?? 1)) * 100}% - 16px)`;

  if (!columns) return <></>;

  return (
    <Section minH={'300px'}>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow alignItems={'center'}>
            <Img src={MarketingVideos} alt={''} />
            <SectionTitle fontSize={{ base: '18px', lg: '24px' }}>
              {t('videoLibrary:heading.MarketingVideos')}
            </SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t(
              'videoLibrary:text.AktionsvideosAufVISITXMitDenenDuNochMehrKundenErreichst'
            )}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <HStack
            display={'flex'}
            overflowX={'auto'}
            h={'full'}
            alignItems={'start'}
            gap={'16px'}
          >
            {videos.map((video) => {
              const vip30id = 'videoVip30' + video.albumId;
              const contestId = 'videoContest' + video.albumId;
              return (
                <Box
                  minW={
                    videosExceedingColumns
                      ? `calc(${videoMinWidth} - 16px)`
                      : videoMinWidth
                  }
                  maxW={
                    videosExceedingColumns
                      ? `calc(${videoMinWidth} - 16px)`
                      : videoMinWidth
                  }
                >
                  {video.isVip30Video && (
                    <Vip30Video
                      key={video.albumId}
                      video={video}
                      onPlay={() => setPlayingVideoId(vip30id)}
                      paused={playingVideoId !== vip30id}
                    />
                  )}
                  {video.contestId !== undefined && (
                    <ContestVideo
                      key={video.albumId}
                      video={video}
                      onPlay={() => setPlayingVideoId(contestId)}
                      paused={playingVideoId !== vip30id}
                    />
                  )}
                </Box>
              );
            })}
          </HStack>
        </div>
      </SectionBody>
    </Section>
  );
};

const ContestVideo: React.FC<{
  video: VideoFragment;
  paused: boolean;
  onPlay: () => void;
}> = ({ video, paused, onPlay }) => {
  const [contest, setcontest] =
    React.useState<Maybe<ContestVideoFragment>>(null);
  const { t } = useTranslation(['videoLibrary']);

  const [fetchContest] = useGetVideoLibraryMarketingVideoDateLazyQuery({
    onError: () => {
      Logger.log('Error fetching contest period in video library');
    },
  });

  const period = React.useMemo(() => {
    const start = contest?.votingStart ?? '';
    const end = contest?.votingEnd ?? '';
    return {
      votingStart: start,
      votingEnd: end,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contest]);

  React.useEffect(() => {
    if (video.contestId) {
      fetchContest({
        variables: {
          contestId: video.contestId,
        },
      }).then((data) => {
        if (data.data?.media?.contest) {
          setcontest(data.data?.media?.contest);
        }
      });
    }
  }, [fetchContest, video.contestId]);

  return (
    <VStack alignItems={'start'}>
      <HStack alignItems={'center'}>
        <Icon
          as={icons.VxModelsLogo}
          boxSize="icon.sm"
          color={'vxModels.500'}
        />
        <Text
          lineHeight={'20px'}
          fontSize={{ base: '14px' }}
          fontWeight={'normal'}
        >
          {t('videoLibrary:text.Wettbewerb', {
            startDate: period.votingStart,
            endDate: period.votingEnd,
          })}
        </Text>
      </HStack>
      <UploadHistoryListItem
        video={video}
        paused={paused}
        boxProps={{ w: 'full' }}
        onPlay={onPlay}
        contestStartForStatus={period.votingStart}
      />
    </VStack>
  );
};

const Vip30Video: React.FC<{
  video: VideoFragment;
  paused: boolean;
  onPlay: () => void;
}> = ({ video, paused, onPlay }) => {
  const { t } = useTranslation(['videoLibrary']);
  const monthFormatter = new Intl.DateTimeFormat('de', { month: 'short' });
  const now = new Date();
  const month = monthFormatter.format(now);

  return (
    <VStack alignItems={'start'}>
      <HStack alignItems={'center'}>
        <Icon as={icons.VxModelsLogo} boxSize="icon.sm" color={'#EEC126'} />
        <Text
          lineHeight={'20px'}
          fontSize={{ base: '14px' }}
          fontWeight={'normal'}
        >
          {t('videoLibrary:text.VIP30VideoMonth', {
            month,
          })}
        </Text>
      </HStack>
      <UploadHistoryListItem
        video={video}
        paused={paused}
        boxProps={{ w: 'full' }}
        onPlay={onPlay}
        isVip30={true}
      />
    </VStack>
  );
};
