import { ApolloError } from '@apollo/client';
import * as icons from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Center,
  Divider,
  Grid,
  GridProps,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  LinkProps,
  Popover,
  PopoverBody,
  PopoverBodyProps,
  PopoverContent,
  PopoverContentProps,
  PopoverHeader,
  PopoverHeaderProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  SlideFade,
  Spinner,
  StackProps,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabPanelsProps,
  Tabs,
  TabsProps,
  Text,
  VStack,
  chakra,
  forwardRef,
  useBreakpointValue,
  usePopoverContext,
} from '@chakra-ui/react';
import { SlideFadeProps } from '@chakra-ui/transition/dist/slide-fade';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RemoveScroll } from 'react-remove-scroll';
import { IRemoveScrollProps } from 'react-remove-scroll/dist/es5/types';

import {
  GetAllNotificationsDocument,
  GetImportantNotificationsDocument,
  GetNewsNotificationsDocument,
  GetNotificationsCountDocument,
  NotificationFragment,
  useMarkReadMutation,
} from '../../../generated/notifications';
import { useActiveApiLanguage } from '../../../hooks/useActiveApiLanguage';
import { useDynamicVisualViewport } from '../../../hooks/useDynamicVisualViewport';
import useHistoryWithStorage from '../../../hooks/useHistoryWithStorage';
import { useNewsCenterActionHandler } from '../../../hooks/useNewsCenterActionHandler';
import { useOnScreen } from '../../../hooks/useOnScreen';
import {
  NewsCenterNotification,
  useNewsCenter,
} from '../../../provider/NewsCenterProvider';
import { externalRoutes } from '../../../routes/routesConfig';
import { noop } from '../../../utils';
import Logger from '../../../utils/Logger';
import { NotificationParams, gtag } from '../../../utils/gtag';
import { RelativeBox } from '../../Layout/RelativeBox/RelativeBox';
import { CornerGuideDot, InlineGuideDot } from '../GuideDot/GuideDot';
import { NotificationAnnouncementItem } from './NewsCenterNotification/NotificationAnnouncementItem';
import { NotificationItem } from './NewsCenterNotification/NotificationItem';
import {
  getExternalURLFromUri,
  getIconAndColor,
  getPathFromUri,
  showNotificationDetails,
} from './utils';

// todo: have this value used responsive switching to 50px on mobile once the layout does
const navbarHeight = 58;
const mobileNavbarHeight = 48;
const breakpointSwitchingFromFullscreen = 'sm' as const;

/**
 * Make sure the popover content is always fully contained in the visual viewport
 * So the complete content can be reached at least by scrolling to it
 */
const NewsCenterPopoverContent = forwardRef<PopoverContentProps, 'div'>(
  (props, ref) => {
    const { dynamicVisualViewport } = useDynamicVisualViewport();

    return (
      <PopoverContent
        data-testid={'news-center-dialog'}
        border={'none'}
        borderRadius={'none'}
        sx={{
          '--top-navbar-height': { base: `${navbarHeight}px` },
          '--bottom-navbar-height': {
            // Change to always 0 in case popover should appear above bottom navbar
            base: `${mobileNavbarHeight}px`,
            md: '0px',
          },
          '--occupied-height':
            'calc(var(--top-navbar-height) + var(--bottom-navbar-height))',
          '--visual-viewport-height': `${dynamicVisualViewport.height}px`,
          '--min-dynamic-height': `calc(var(--visual-viewport-height) - var(--occupied-height))`,
        }}
        w={{
          base: '100vw',
          [breakpointSwitchingFromFullscreen]: '450px',
        }}
        h={{
          // In case the bottom navbar will use the safe area inset we need to add
          // the offset here as well - env(safe-area-inset-bottom, 0px)
          base: 'calc(var(--min-dynamic-height))',
          [breakpointSwitchingFromFullscreen]: `min(538px, var(--min-dynamic-height))`,
        }}
        rootProps={{
          // Add `zIndex: 'popover',` in case popover should appear above bottom navbar
          boxShadow: 'xl',
        }}
        display={'grid'}
        gridTemplateAreas={'"header" "body"'}
        gridTemplateRows={'56px 1fr'}
        {...props}
        ref={ref}
      />
    );
  }
);

const NewsCenterHeader = forwardRef<PopoverHeaderProps, 'div'>((props, ref) => (
  <PopoverHeader
    flexShrink={0}
    gridArea={'header'}
    border={'none'}
    {...props}
    ref={ref}
  />
));

const NewsCenterBody = forwardRef<PopoverBodyProps, 'div'>((props, ref) => (
  <PopoverBody
    data-testid={'news-center-body'}
    gridArea={'body'}
    px={0}
    py={0}
    overflow={'hidden'}
    display={'flex'}
    flexDirection={'column'}
    position={'relative'}
    {...props}
    ref={ref}
  />
));

const NewsCenterTabs = forwardRef<TabsProps, 'div'>((props, ref) => (
  <Tabs
    // https://github.com/chakra-ui/chakra-ui/issues/361#issuecomment-1031450907
    w={'full'}
    display={'grid'}
    gridTemplateAreas={'"tabs" "panels"'}
    gridTemplateRows={'auto 1fr'}
    position={'absolute'}
    // unfortunatly we can't use inset: '0' here yet
    left={0}
    right={0}
    top={0}
    bottom={0}
    isFitted
    p={0}
    {...props}
    ref={ref}
  />
));

const NewsCenterTabPanels = forwardRef<TabPanelsProps, 'div'>((props, ref) => (
  <TabPanels
    gridArea={'panels'}
    overflow={'hidden'}
    display={'flex'}
    flexDirection={'column'}
    {...props}
    ref={ref}
  />
));

const NewsCenterTabPanel = forwardRef<TabPanelProps, 'div'>((props, ref) => (
  <TabPanel
    overflowY={'auto'}
    h={'full'}
    pt={0}
    px={4}
    pb={4}
    {...props}
    ref={ref}
  />
));

const NewsCenterRemoveScroll = forwardRef<IRemoveScrollProps, 'div'>(
  (props, ref) => {
    const popoverCtx = usePopoverContext();
    const isBodyScrollBlocked = useBreakpointValue({
      base: true,
      [breakpointSwitchingFromFullscreen]: false,
    });
    return (
      <RemoveScroll
        enabled={popoverCtx.isOpen && isBodyScrollBlocked}
        {...props}
        ref={ref}
      />
    );
  }
);

const HelpcenterLink: React.FC<
  LinkProps & {
    linkUri?: string;
    linkLabel: string;
  }
> = ({ linkUri, linkLabel }, props) => {
  const { i18n } = useTranslation(['newsCenter']);
  const { selectedNotification } = useNewsCenter();
  return (
    <Link
      href={linkUri ?? externalRoutes.customerService(i18n.language)}
      isExternal
      rel="noreferrer"
      textColor={'secondary.highEmphasis'}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      textAlign={'center'}
      onClick={() => {
        if (selectedNotification) {
          gtag('event', 'Click_Notification_Link', {
            ...getGtagEventParamsFromNotification(selectedNotification),
            linkUri,
          });
        }
      }}
      {...props}
    >
      <chakra.span>{linkLabel}</chakra.span>
      <Icon as={icons.ChevronRight} ps={'2.5'} boxSize={'icon.lg'} />
    </Link>
  );
};

export const NewsCenterPopoverTrigger = forwardRef((props, ref) => {
  const { t } = useTranslation(['newsCenter']);
  const {
    isOpen,
    hasUnreadNotifications,
    hasImportantUnreadNotifications,
    hasUnreadNewsNotifications,
  } = useNewsCenter();
  return (
    <PopoverTrigger>
      <RelativeBox
        attachment={
          <CornerGuideDot
            data-testid={'new-notification-indicator'}
            right={'5px'}
            top={'11px'}
            isShown={
              hasUnreadNotifications ||
              hasImportantUnreadNotifications ||
              hasUnreadNewsNotifications
            }
          />
        }
        w={'min-content'}
        {...props}
        ref={ref}
      >
        <IconButton
          aria-label={t('newsCenter:button.NewsCenter')}
          icon={<Icon as={icons.Notifications} boxSize={'icon.lg'} />}
          size="xs"
          color={'gray.900'}
          bg={isOpen ? 'surface' : 'transparent'}
          px={4}
          // todo: might need to be responsive switching to 50px
          h={'58px'}
          variant={'unstyled'}
          borderRadius={'none'}
        />
      </RelativeBox>
    </PopoverTrigger>
  );
});

const ZStack: React.FC<GridProps & { children: React.ReactNode }> = (props) => (
  <Grid
    templateAreas={'"stack"'}
    sx={{ '&>*': { gridArea: 'stack' } }}
    {...props}
  />
);

const NotificationsEnd: React.FC<
  {
    loading: boolean;
    error: ApolloError | undefined;
    onIsVisible: () => void;
  } & StackProps
> = ({ loading, onIsVisible, error, ...props }) => {
  const { t } = useTranslation(['feed']);
  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);

  React.useEffect(() => {
    if (isVisible) {
      onIsVisible();
    }
  }, [isVisible, onIsVisible]);

  return (
    <VStack {...props} ref={ref}>
      {!error && loading && <Spinner size={'lg'} />}
      {error && !loading && (
        <Alert status={'info'} variant={'outline'}>
          <AlertIcon />
          <AlertTitle>
            {t('feed:text.BeimLadenIstEinFehlerAufgetreten')}:
          </AlertTitle>
          <AlertDescription>
            <Button variant={'link'} onClick={onIsVisible}>
              {t('feed:button.ErneutLaden')}
            </Button>
          </AlertDescription>
        </Alert>
      )}
    </VStack>
  );
};

type NewsCenterListViewType = {
  notificationEdges: NewsCenterNotification[];
  loading: boolean;
  hasNextPage: boolean;
  error: ApolloError | undefined;
  fetchMore: any;
  isAnnouncementTab?: boolean;
};

const NewsCenterListView = ({
  notificationEdges,
  loading,
  fetchMore,
  hasNextPage,
  error,
  isAnnouncementTab = false,
}: NewsCenterListViewType) => {
  const lang = useActiveApiLanguage();
  const loadMoreNotifications = () => {
    const lastNotification = notificationEdges[notificationEdges.length - 1];

    if (hasNextPage && !error) {
      fetchMore({
        variables: { lang: lang, count: 5, cursor: lastNotification.cursor },
      });
    }
  };

  if (loading || !notificationEdges || notificationEdges.length === 0)
    return <NewsCenterEmptyListView />;

  return (
    <VStack divider={<Divider />} as={'ol'} listStyleType={'none'} spacing={0}>
      {notificationEdges.map((notificationEdge) => {
        return (
          <NotificationListItemWrapper
            key={notificationEdge.node.id}
            notification={notificationEdge.node}
            isAnnouncementTab={isAnnouncementTab}
          />
        );
      })}
      {hasNextPage && (
        <NotificationsEnd
          mt={{ base: '8', lg: '12' }}
          loading={loading}
          onIsVisible={loadMoreNotifications}
          error={error}
        />
      )}
    </VStack>
  );
};

export const getGtagEventParamsFromNotification = (
  notification: NotificationFragment
): NotificationParams => {
  const readAt = notification.readAt
    ? DateTime.fromISO(notification.readAt)
    : DateTime.now();
  return {
    type: notification.type.toString(),
    category: notification.category,
    createdAt: String(notification.createdAt),
    readAt: String(notification.readAt),
    updatedAt: String(notification.updatedAt),
    deltaCreatedRead: readAt
      .diff(DateTime.fromISO(notification.createdAt))
      .as('minutes'),
    deltaUpdatedRead: readAt
      .diff(DateTime.fromISO(notification.updatedAt))
      .as('minutes'),
  };
};

const NotificationListItemWrapper: React.FC<
  BoxProps & { notification: NotificationFragment; isAnnouncementTab?: boolean }
> = ({ notification, isAnnouncementTab = false, ...boxProps }) => {
  const { setSelectedNotification, onClose, onOpenAnncouncement } =
    useNewsCenter();
  const history = useHistoryWithStorage();

  const [markReadMutation] = useMarkReadMutation({
    variables: {
      id: notification.id,
    },
    // Cause queries to refetch after marking notifiaction as read
    // todo: might be refactored to be encapsulated in the NewsCenterProvider
    refetchQueries: [
      GetNotificationsCountDocument,
      GetImportantNotificationsDocument,
      GetAllNotificationsDocument,
      GetNewsNotificationsDocument,
    ],
    onError: (error) => Logger.error(error),
    onCompleted: () => {
      gtag(
        'event',
        'Notification_MarkAsRead',
        getGtagEventParamsFromNotification(notification)
      );
    },
  });

  const onClick = React.useCallback(() => {
    const gtagData = getGtagEventParamsFromNotification(notification);
    gtag('event', 'Click_Notification', gtagData);
    if (notification.readAt == null) {
      markReadMutation();
    }

    //--choose where to go next--
    if (!notification.uri) return;

    if (showNotificationDetails(notification.uri)) {
      setSelectedNotification(notification);
    } else {
      // Open new tab when external link is detected
      const externalUrl = getExternalURLFromUri(notification.uri);
      if (externalUrl) {
        window.open(externalUrl.href, '_blank');
        gtag('event', 'Click_Notification_External', {
          ...gtagData,
          externalUrl,
        });
        return;
      }

      const targetPath = getPathFromUri(notification.uri);

      if (targetPath) {
        onClose();
        history.push(targetPath);
        gtag('event', 'Click_Notification_Target', { ...gtagData, targetPath });
      }
    }
  }, [
    notification,
    setSelectedNotification,
    markReadMutation,
    onClose,
    history,
  ]);

  const onClickAnnouncement = React.useCallback(() => {
    const gtagData = getGtagEventParamsFromNotification(notification);
    gtag('event', 'Click_Notification', gtagData);
    if (notification.readAt == null) {
      markReadMutation();
    }

    setSelectedNotification(notification);

    //open the announcement modal
    onOpenAnncouncement();
  }, [
    notification,
    setSelectedNotification,
    onOpenAnncouncement,
    markReadMutation,
  ]);

  const isAnnouncement = notification.type.startsWith('ANNOUNCEMENT');

  return (
    <Box
      as={'li'}
      w={'full'}
      cursor={'pointer'}
      onClick={isAnnouncement ? onClickAnnouncement : onClick}
      {...boxProps}
    >
      {isAnnouncement && isAnnouncementTab ? (
        <NotificationAnnouncementItem notification={notification} />
      ) : (
        <NotificationItem notification={notification} />
      )}
    </Box>
  );
};

const NewsCenterEmptyListView: React.FC = () => {
  const { t } = useTranslation(['newsCenter']);
  return (
    <Center color={'darkSteel'} minH={'300px'} px={'60px'}>
      <VStack textAlign={'center'}>
        <Box fontWeight={'700'}>{t('newsCenter:noNotifications')}</Box>
        <Icon mt={'10px'} as={icons.Notifications} boxSize={'icon.md'} />
      </VStack>
    </Center>
  );
};

const NewsCenterPrimaryActionButton: React.FC<
  ButtonProps & { actionUri: string }
> = ({ actionUri, ...buttonProps }) => {
  const actionOnClick = useNewsCenterActionHandler({
    actionUri,
  });
  const { selectedNotification } = useNewsCenter();

  return (
    <Button
      isLoading={!actionOnClick}
      onClick={() => {
        actionOnClick?.();
        if (selectedNotification) {
          gtag(
            'event',
            'Click_Notification_Primary',
            getGtagEventParamsFromNotification(selectedNotification)
          );
        }
      }}
      {...buttonProps}
    />
  );
};

const NewsCenterSecondaryActionButton: React.FC<
  ButtonProps & { actionUri: string }
> = ({ actionUri, ...buttonProps }) => {
  const actionOnClick = useNewsCenterActionHandler({
    actionUri,
  });
  const { selectedNotification } = useNewsCenter();

  return (
    <Button
      variant={'outline'}
      isLoading={!actionOnClick}
      onClick={() => {
        actionOnClick?.();
        if (selectedNotification) {
          gtag(
            'event',
            'Click_Notification_Secondary',
            getGtagEventParamsFromNotification(selectedNotification)
          );
        }
      }}
      {...buttonProps}
    />
  );
};

const NewsCenterDetailView: React.FC = () => {
  const { selectedNotification } = useNewsCenter();
  if (!selectedNotification) return null;

  const {
    link,
    title,
    subtitle,
    picture,
    icon,
    primaryButton,
    secondaryButton,
  } = selectedNotification;

  const { iconType, color } = getIconAndColor(icon?.src);
  return (
    <Box
      gridArea={'body'}
      w={'full'}
      overflowY={'auto'}
      position={'absolute'}
      left={0}
      right={0}
      top={0}
      bottom={0}
      display={'flex'}
      flexDirection={'column'}
    >
      {picture && (
        <Image
          w={'full'}
          h={'140px'}
          flexShrink={0}
          alignSelf={'start'}
          fit={'cover'}
          src={picture?.src}
          srcSet={picture?.srcset}
          sizes={'500px'}
          alt={picture?.alt ?? ''}
        />
      )}
      <VStack p={5} textAlign={'center'}>
        {iconType && color && <Icon as={iconType} color={color} boxSize={6} />}
        <Heading size={'lg'}>{title}</Heading>
        <Text size={'body.md'} flexGrow={1}>
          <span>{subtitle}</span>
        </Text>
        <VStack alignItems={'stretch'} w={'full'} h={'full'}>
          {primaryButton && (
            <NewsCenterPrimaryActionButton actionUri={primaryButton?.action}>
              {primaryButton?.label}
            </NewsCenterPrimaryActionButton>
          )}
          {secondaryButton && (
            <NewsCenterSecondaryActionButton
              actionUri={secondaryButton?.action}
            >
              {secondaryButton?.label}
            </NewsCenterSecondaryActionButton>
          )}
          {link && (
            <HelpcenterLink linkUri={link?.uri} linkLabel={link?.label} />
          )}
        </VStack>
      </VStack>
    </Box>
  );
};

const NewsCenterListHeader = () => {
  const { t } = useTranslation(['newsCenter']);
  return (
    <HStack alignItems={'center'}>
      <Heading size={'xl'} flexGrow={1}>
        {t('newsCenter:heading.NewsCenter')}
      </Heading>
      {/* <IconButton
        aria-label={t('newsCenter:button.Einstellungen')}
        variant={'daemon'}
        icon={<Icon as={icons.CustomSettings} boxSize={'icon.md'} />}
      /> */}
    </HStack>
  );
};

const NewsCenterDetailHeader = () => {
  const { setSelectedNotification } = useNewsCenter();
  return (
    <HStack alignItems={'center'} h={'full'}>
      <Button
        ps={'0'}
        variant={'link'}
        _hover={{
          textDecoration: undefined,
        }}
        _active={{
          textDecoration: undefined,
        }}
        leftIcon={<Icon as={icons.ChevronLeft} boxSize={'icon.md'} />}
        onClick={() => setSelectedNotification(null)}
        children={'Zurück'}
      />
    </HStack>
  );
};

const NewsCenterSlideFade: React.FC<
  SlideFadeProps & { shiftInPx?: number; visibleFor: 'list' | 'detail' }
> = (props) => {
  const { selectedNotification } = useNewsCenter();

  const { shiftInPx = 20, visibleFor, ...rest } = props;

  return (
    <SlideFade
      in={
        visibleFor === 'list'
          ? !selectedNotification?.id
          : !!selectedNotification?.id
      }
      data-layer={visibleFor}
      offsetX={visibleFor === 'list' ? -shiftInPx : shiftInPx}
      offsetY={0}
      transitionEnd={{
        // attributes are not reset after transition,
        // so enter & exit should set / unset them
        // according to the desired end result
        enter: {
          pointerEvents: 'inherit',
        },
        exit: {
          pointerEvents: 'none',
        },
      }}
      {...rest}
    />
  );
};

const NewsCenterListImportantTab = () => {
  const { t } = useTranslation(['newsCenter']);
  const { hasImportantUnreadNotifications } = useNewsCenter();
  return (
    <Tab>
      <span>
        {t('newsCenter:tab.Wichtiges')}
        <InlineGuideDot ml={1} isShown={hasImportantUnreadNotifications} />
      </span>
    </Tab>
  );
};

const NewsCenterListAllTab = () => {
  const { t } = useTranslation(['newsCenter']);
  const { hasUnreadNotifications } = useNewsCenter();

  return (
    <Tab>
      <span>
        {t('newsCenter:tab.Alle')}
        <InlineGuideDot ml={1} isShown={hasUnreadNotifications} />
      </span>
    </Tab>
  );
};

const NewsCenterListNewsTab = () => {
  const { t } = useTranslation(['newsCenter']);
  const { hasUnreadNewsNotifications } = useNewsCenter();

  return (
    <Tab>
      <span>
        {t('newsCenter:tab.News')}
        <InlineGuideDot ml={1} isShown={hasUnreadNewsNotifications} />
      </span>
    </Tab>
  );
};

const NewsCenterListOverview = () => {
  const {
    setListTabIndex,
    listTabIndex,
    allLoading,
    allNotificationEdges,
    allFetchMore,
    allHasNextPage,
    allError,
    importantLoading,
    importantNotificationEdges,
    importantFetchMore,
    importantHasNextPage,
    importantError,
    newsLoading,
    newsNotificationEdges,
    newsFetchMore,
    newsHasNextPage,
    newsError,
  } = useNewsCenter();

  return (
    <NewsCenterTabs
      defaultIndex={listTabIndex}
      tabIndex={listTabIndex}
      onChange={(index) => setListTabIndex(index)}
    >
      <TabList gridArea={'tabs'}>
        <NewsCenterListImportantTab />
        <NewsCenterListAllTab />
        <NewsCenterListNewsTab />
      </TabList>
      <NewsCenterTabPanels>
        <NewsCenterTabPanel px={0}>
          <NewsCenterListView
            loading={importantLoading}
            notificationEdges={importantNotificationEdges}
            fetchMore={importantFetchMore}
            hasNextPage={importantHasNextPage}
            error={importantError}
          />
        </NewsCenterTabPanel>
        <NewsCenterTabPanel px={0}>
          <NewsCenterListView
            loading={allLoading}
            notificationEdges={allNotificationEdges}
            fetchMore={allFetchMore}
            hasNextPage={allHasNextPage}
            error={allError}
          />
        </NewsCenterTabPanel>
        <NewsCenterTabPanel px={0}>
          <NewsCenterListView
            loading={newsLoading}
            notificationEdges={newsNotificationEdges}
            fetchMore={newsFetchMore}
            hasNextPage={newsHasNextPage}
            error={newsError}
            isAnnouncementTab={true}
          />
        </NewsCenterTabPanel>
      </NewsCenterTabPanels>
    </NewsCenterTabs>
  );
};

const NewsCenterControlledPopover: React.FC<PopoverProps> = (props) => {
  const { isOpen, onOpen, onClose } = useNewsCenter();

  return (
    <Popover
      placement="bottom-end"
      strategy={'fixed'}
      gutter={0}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      {...props}
    />
  );
};

export const NewsCenterPopper: React.FC = () => {
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onClose } = useNewsCenter();
  const poperTriggerRef = React.useRef<HTMLElement>(null);
  const poperContentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const onPointerDown = (
      event: DocumentEventMap['touchstart'] | DocumentEventMap['mousedown']
    ) => {
      const targetNode = event.target as Node;
      if (poperContentRef.current?.contains(targetNode) ?? true) {
        // do nothing and keep it open, as we are still inside the popper
        return;
      }

      if (poperTriggerRef.current?.contains(targetNode) ?? true) {
        return;
      }

      onClose();
    };
    if (!isOpen) {
      // Do not add event listeners when not open.
      return noop;
    }

    document.addEventListener('touchstart', onPointerDown, true);
    document.addEventListener('mousedown', onPointerDown, true);
    return () => {
      document.removeEventListener('touchstart', onPointerDown, true);
      document.removeEventListener('mousedown', onPointerDown, true);
    };
  }, [isOpen, onClose]);

  return (
    <NewsCenterControlledPopover closeOnBlur={false}>
      <NewsCenterPopoverTrigger ref={poperTriggerRef} />
      {!isMobileViewport && <Box w={'16px'} />}
      <Portal>
        <NewsCenterRemoveScroll>
          <NewsCenterPopoverContent ref={poperContentRef}>
            <NewsCenterHeader px={4} display={'flex'} alignItems={'center'}>
              {/* Layering Tabs and detail view here with a ZStack, */}
              {/* so we can have the SlideFade effect transition between them */}
              <ZStack gridArea={'header'}>
                <NewsCenterSlideFade visibleFor={'list'} shiftInPx={20}>
                  <NewsCenterListHeader />
                </NewsCenterSlideFade>
                <NewsCenterSlideFade visibleFor={'detail'} shiftInPx={20}>
                  <NewsCenterDetailHeader />
                </NewsCenterSlideFade>
              </ZStack>
            </NewsCenterHeader>
            <NewsCenterBody>
              {/* Layering Tabs and detail view here with a ZStack, */}
              {/* so we can have the SlideFade effect transition between them */}
              <ZStack
                gridArea={'body'}
                position={'absolute'}
                left={0}
                right={0}
                top={0}
                bottom={0}
              >
                <NewsCenterSlideFade visibleFor={'list'} shiftInPx={10}>
                  <NewsCenterListOverview />
                </NewsCenterSlideFade>
                <NewsCenterSlideFade visibleFor={'detail'} shiftInPx={10}>
                  <NewsCenterDetailView />
                </NewsCenterSlideFade>
              </ZStack>
            </NewsCenterBody>
          </NewsCenterPopoverContent>
        </NewsCenterRemoveScroll>
      </Portal>
    </NewsCenterControlledPopover>
  );
};
