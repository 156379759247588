import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CloseButtonProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
  forwardRef,
  useBreakpointValue,
  useMergeRefs,
  useModalContext,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useViewportBottomCloseness } from '../../hooks/useViewportBottomCloseness';
import { PageSafeAreaInset, PageSafeAreaInsetProps } from './PageSafeAreaInset';

export interface ResponsiveModalProps extends ModalProps {
  /**
   * The preferred size of the modal.
   * Will change to full screen according to preferredSizeBreakpoint.
   * @default 'md'
   */
  preferredSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * At which screen size the modal should become full screen.
   * 'base' means it will never go full screen, preferredSize should be 'xs' then.
   * @default 'md'
   */
  preferredSizeBreakpoint?: 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  /**
   * @deprecated Use preferredSize instead.
   */
  size?: ModalProps['size'];

  /**
   * Alias for isCentered, to make clear it's vertical and to prevent confusion
   * Default true, set false if the modal is expected to be taller than the screen
   * @default true
   */
  isVCentered?: boolean;
  /**
   * @deprecated Use `isVCentered` instead.
   */
  isCentered?: boolean;
}

export const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
  preferredSize = 'md',
  preferredSizeBreakpoint = 'md',
  isVCentered = true,
  ...rest
}) => {
  const mappedIsVCentered = useBreakpointValue({
    base: false, // full screen should not be centered since that will cause a ugly margin
    [preferredSizeBreakpoint]: isVCentered,
  });

  return (
    <Modal
      motionPreset="none"
      autoFocus={false}
      trapFocus={false}
      preserveScrollBarGap
      blockScrollOnMount={true}
      isCentered={mappedIsVCentered}
      size={{
        base: 'full', // default is full screen, anything bigger is determined by prefferedSize
        [preferredSizeBreakpoint]: preferredSize,
      }}
      {...rest}
    />
  );
};
export const ResponsiveModalOverlay = forwardRef<ModalOverlayProps, 'dialog'>(
  (props, ref) => {
    return (
      <ModalOverlay
        ref={ref}
        bg="blackAlpha.300"
        backdropFilter="auto"
        backdropBlur={'base'}
        {...props}
      />
    );
  }
);
export const ResponsiveModalContent = forwardRef<ModalContentProps, 'section'>(
  (props, ref) => {
    return (
      <ModalContent
        ref={ref}
        borderRadius={{ base: 'none', md: 'lg' }}
        position={'relative'}
        sx={{
          contain: 'paint',
          '--max-vh': '100svh',
          '--polyfilled-vh': '$100vh',
        }}
        // minH={{
        //   base: 'var(--max-vh, var(--polyfilled-vh, -webkit-fill-available))',
        //   md: 'min(var(--max-vh, 100vh), 36rem)',
        // }}
        overscrollBehaviorY={'none'}
        role="dialog"
        {...props}
      />
    );
  }
);

type StickyEdge = 'top' | 'bottom';

type UseModalStickyIntersectionObserverOptions = {
  element: React.RefObject<HTMLDivElement>;
  stickyEdge?: StickyEdge;
};
const useModalStickyIntersectionObserver = ({
  element,
  stickyEdge = 'top',
}: UseModalStickyIntersectionObserverOptions) => {
  const [isSticky, setIsSticky] = React.useState<boolean>(false);
  const { dialogRef } = useModalContext();

  //this determines when an element is sticky
  const intersectionOptions = React.useMemo<IntersectionObserverInit>(
    () => ({
      threshold: [1],
      //in case of feedcommentmodal header the top header is sticky
      //right away => -1px
      rootMargin: `${'top' === stickyEdge ? '-1px' : '0px'} 0px ${
        'bottom' === stickyEdge ? '-1px' : '0px'
      } 0px`,
    }),
    [stickyEdge]
  );

  useEffect(() => {
    const divElement = element?.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.isIntersecting);
      },
      {
        root: dialogRef.current,
        ...intersectionOptions,
      }
    );

    if (divElement) {
      observer.observe(divElement);
    }

    return () => {
      if (divElement) {
        observer.unobserve(divElement);
      }
    };
  }, [element, dialogRef, intersectionOptions]);

  return isSticky;
};

type Options = {
  stickyEdge?: StickyEdge;
  stickyBoxShadow?: string;
  scrollDirection?: string | null;
  isSticky?: boolean;
};
const ResponsiveModalStickySafeAreaBox = forwardRef<
  PageSafeAreaInsetProps & Options,
  'div'
>(
  (
    {
      stickyEdge = 'top',
      stickyBoxShadow = 'e8',
      scrollDirection = null,
      ...props
    },
    ref
  ) => {
    const otherInternalRef = React.useRef<HTMLDivElement>(null);
    const element =
      (ref as React.RefObject<HTMLDivElement>) ?? otherInternalRef;
    const isSticky = useModalStickyIntersectionObserver({
      element,
      stickyEdge,
    });
    const refs = useMergeRefs(element, ref);

    const positioning = React.useMemo(
      () => ({ [stickyEdge]: 0 }),
      [stickyEdge]
    );

    return (
      <PageSafeAreaInset
        position={'sticky'}
        {...positioning}
        zIndex={'sticky'}
        boxShadow={
          isSticky && (scrollDirection === null || scrollDirection === 'down')
            ? stickyBoxShadow
            : 'none'
        }
        transitionProperty={'box-shadow'}
        transitionDuration={'slow'}
        bg={'inherit'}
        bgImage={'unset'}
        ref={refs}
        {...props}
      />
    );
  }
);

type ResponsiveModalStickyHeaderBoxProps = BoxProps & {
  scrollDirection?: string | null;
};

export const ResponsiveModalStickyHeaderBox = forwardRef<
  ResponsiveModalStickyHeaderBoxProps,
  'div'
>((props, ref) => (
  <ResponsiveModalStickySafeAreaBox
    as={ModalHeader}
    stickyEdge={'top'}
    ref={ref}
    p={4}
    {...props}
  />
));

type ResponsiveModalStickySubHeaderBoxProps = {
  headerHeight: number;
  subHeaderHeight: number;
  showHeaderShadow: boolean;
} & ResponsiveModalStickyHeaderBoxProps &
  React.HTMLProps<HTMLDivElement>;

export const ResponsiveModalStickySubHeaderBox = React.forwardRef<
  HTMLDivElement,
  ResponsiveModalStickySubHeaderBoxProps
>((props, ref) => {
  const { headerHeight, subHeaderHeight, scrollDirection, children } = props;
  const isHeaderShown = scrollDirection === 'up';
  const showHeaderShadow = !props.showHeaderShadow && isHeaderShown;
  return (
    <Box
      ref={ref}
      position={'sticky'}
      top={!isHeaderShown ? `${subHeaderHeight}px` : `${headerHeight}px`}
      boxShadow={showHeaderShadow ? 'e8' : 'none'}
      transitionProperty={'top boxShadow'}
      transitionDuration={'500ms'}
      zIndex={10}
      bg={'surface'}
    >
      {children}
    </Box>
  );
});

export const ResponsiveModalStickyFooterBox: React.FC<BoxProps> = (props) => {
  const ref = React.useRef(null);
  const isAtBottomOfScreen = useViewportBottomCloseness(ref);
  return (
    <ResponsiveModalStickySafeAreaBox
      as={ModalFooter}
      stickyEdge={'bottom'}
      p={4}
      justifyContent={'center'}
      ref={ref}
      {...props}
      surroundPadding={isAtBottomOfScreen ? 'b' : 'none'}
    />
  );
};

export const ResponsiveModalBodyBlankBox = forwardRef<BoxProps, 'div'>(
  (props, ref) => (
    <Box
      ref={ref}
      bg="surface"
      flexGrow={1}
      flexDirection={'column'}
      {...props}
    />
  )
);

export const ResponsiveModalBodyBox = forwardRef<BoxProps, 'div'>(
  (props, ref) => (
    <Box
      ref={ref}
      p="4"
      bg="surface"
      flexGrow={1}
      flexDirection={'column'}
      {...props}
    />
  )
);

export const PrimaryButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    flexShrink={0}
    data-testid={'modal-primary-action'}
    variant={'solid'}
    minW={'48'}
    ref={ref}
    {...props}
  />
));

export const SecondaryButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => (
    <Button
      flexShrink={0}
      data-testid={'modal-secondary-action'}
      variant={'outline'}
      ref={ref}
      {...props}
    />
  )
);

export const ResponsiveModalCloseButton: React.FC<CloseButtonProps> = (
  props
) => {
  const { t } = useTranslation(['general']);
  return (
    <ModalCloseButton
      data-testid={'modal-close-action'}
      right={3}
      top={3}
      aria-label={t('general:button.Schliessen')}
      color={'gray.900'}
      {...props}
    />
  );
};
