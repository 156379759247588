import VXWorldAvatar from '../../assets/images/feed/Avatar.png';

export const typeDependantModelName = (
  isFeaturedPost: boolean,
  modelName: string,
  featuredPostTitle: string
) => {
  if (isFeaturedPost) {
    return featuredPostTitle + modelName;
  }
  return modelName;
};

export const avatarURL = (
  isFeaturedPost: boolean,
  modelAvatar: string | undefined
) => {
  if (isFeaturedPost) {
    return VXWorldAvatar;
  }
  return modelAvatar;
};
