import * as icon from '@campoint/odi-ui-icons';
import {
  HStack,
  Heading,
  HeadingProps,
  Icon,
  IconProps,
  Image,
  ImageProps,
  StackProps,
  Text,
  TextProps,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import textPostPreviewImage from '../../../assets/images/feed/TextPostPreviewImage.svg';
import { FeedPostFragment } from '../../../generated/feed';
import { createTestIds } from '../../../utils/test';
import { ZStack } from '../../Layout/ZStack';

const testId = createTestIds('FeedPostRef' as const, ['group'] as const);
export const FeedPostRefTestId = testId;

const FeedPostRefHeading: React.FC<HeadingProps> = (props) => (
  <Heading
    as="h3"
    size={'none'}
    textStyle={'labelSm'}
    noOfLines={1}
    {...props}
  />
);
const FeedPostRefContent: React.FC<StackProps> = (props) => (
  <VStack spacing={'0'} alignItems={'start'} {...props} />
);
const FeedPostRefText: React.FC<TextProps> = (props) => (
  <Text as="span" textStyle={'labelXs'} noOfLines={1} {...props} />
);
const FeedPostRefBox: React.FC<StackProps> = (props) => (
  <HStack
    px={4}
    py={2}
    spacing={4}
    alignItems={'start'}
    bg={'steel'}
    role={'group'}
    data-testid={testId.group}
    {...props}
  />
);
const FeedPostRefMediaPreview: React.FC<ImageProps> = (props) => (
  <Image
    fit={'cover'}
    borderRadius={'base'}
    bgColor={'steel'}
    boxSize={10}
    alt={''}
    {...props}
  />
);

const CorneredIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon
    boxSize={'18px'}
    alignSelf={'end'}
    color={'white'}
    ref={ref}
    {...props}
  />
));

const FeedPostRefImage: React.FC<{ post: FeedPostFragment }> = ({ post }) => {
  switch (post.__typename) {
    case 'PhotosPost':
      return (
        <ZStack bg={'coldGray.900'} borderRadius={'base'}>
          <FeedPostRefMediaPreview
            src={post.photos?.[0]?.url ?? textPostPreviewImage}
          />
          <CorneredIcon as={icon.PhotoLibrary} />
        </ZStack>
      );

    case 'ClipPost':
      return (
        <ZStack bg={'coldGray.900'} borderRadius={'base'}>
          <FeedPostRefMediaPreview
            src={post.clip?.poster?.url ?? textPostPreviewImage}
          />
          <CorneredIcon as={icon.Movie} />
        </ZStack>
      );

    default:
      return (
        <ZStack bg={'coldGray.900'} borderRadius={'base'}>
          <FeedPostRefMediaPreview src={textPostPreviewImage} />
        </ZStack>
      );
  }
};

export const FeedPostRef: React.FC<{ post: FeedPostFragment }> = ({ post }) => (
  <FeedPostRefBox>
    <FeedPostRefImage post={post} />
    <FeedPostRefContent>
      <FeedPostRefHeading children={post?.title} />
      <FeedPostRefText children={post?.text} />
    </FeedPostRefContent>
  </FeedPostRefBox>
);
